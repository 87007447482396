import { ResponseResolver, MockedRequest, restContext } from 'msw';

const mockProjectProcesses: ResponseResolver<MockedRequest, typeof restContext> = async (req, res, ctx) => {
    console.log(req);

    const projectProcesses = {
        "count": 13,
        "project_processes": [
            { "project_process_code": "01", "project_process_name": "要件定義" },
            { "project_process_code": "02", "project_process_name": "基本設計" },
            { "project_process_code": "03", "project_process_name": "詳細設計" },
            { "project_process_code": "04", "project_process_name": "製造・単体テスト" },
            { "project_process_code": "05", "project_process_name": "結合テスト" },
            { "project_process_code": "06", "project_process_name": "システムテスト" },
            { "project_process_code": "07", "project_process_name": "運用・保守" },
            { "project_process_code": "08", "project_process_name": "導入・設置" },
            { "project_process_code": "09", "project_process_name": "インフラ構築" },
            { "project_process_code": "10", "project_process_name": "指導・教育" },
            { "project_process_code": "11", "project_process_name": "ヘルプデスク" },
            { "project_process_code": "99", "project_process_name": "その他" },

            { "project_process_code": "999", "project_process_name": "mock" },
        ]
    };

    // 仮想処理時間
    await new Promise(s => setTimeout(s, 2000));

    return res(ctx.json(projectProcesses));
};
  
export default mockProjectProcesses;
