import { ResponseResolver, MockedRequest, restContext } from 'msw';

const mockWorkHistories: ResponseResolver<MockedRequest, typeof restContext> = async (req, res, ctx) => {
    console.log(req);

    // GET
    if (req.method === 'GET') {
        // クエリパラメータ
        const work_history_id = req.url.searchParams.get('work_history_id');    // 職務経歴ID
        const employee_id = req.url.searchParams.get('employee_id');            // 従業員コード
        const employee_name = req.url.searchParams.get('employee_name');        // 従業員名
        const project_code = req.url.searchParams.get('project_code');          // プロジェクトコード
        
        const work_histories = {
            "count": 7,
            "work_histories": [
                {
                    "work_history_id": "3eec5034-feee-4c36-a2f5-1ea9cd4933e6",
                    "employee_id": "210001",
                    "employee_name": "人事　太郎",
                    "project_code": "AAA01",
                    "project_name": "プロジェクトAAA01",
                    "start_date": "2022/3/1",
                    "end_date": "2023/2/28",
                    "work_content": "倉庫管理システム開発",
                    "project_position_code": "PM",
                    "project_position_name": "プロジェクトマネージャー",
                    "project_processes": [
                        { "project_process_code": "01", "project_process_name": "要件定義" },
                        { "project_process_code": "02", "project_process_name": "基本設計" },
                        { "project_process_code": "03", "project_process_name": "詳細設計" },
                        { "project_process_code": "04", "project_process_name": "製造・単体テスト" },
                        { "project_process_code": "05", "project_process_name": "結合テスト" },
                        { "project_process_code": "06", "project_process_name": "システムテスト" },
                        { "project_process_code": "08", "project_process_name": "導入・設置" },
                    ],
                    "use_skills": [
                        { "skill_code": "s01003", "skill_name": "C#" },
                        { "skill_code": "s03008", "skill_name": "SQLServer" },
                    ],
                    "created_at": "2023/3/8 17:28",
                    "created_user_id": "210001",
                    "created_user_name": "人事　太郎",
                    "updated_at": "2023/3/8 17:28",
                    "updated_user_id": "210001",
                    "updated_user_name": "人事　太郎",
                },

                {
                    "work_history_id": "d1465ed6-67bd-4114-8c25-3e88246b9ce9",
                    "employee_id": "210001",
                    "employee_name": "人事　太郎",
                    "project_code": "AAA02",
                    "project_name": "プロジェクトAAA02",
                    "start_date": "2023/3/1",
                    "end_date": "2023/5/31",
                    "work_content": "○○システム開発",
                    "project_position_code": "PL",
                    "project_position_name": "プロジェクトリーダー",
                    "project_processes": [
                        { "project_process_code": "03", "project_process_name": "詳細設計" },
                        { "project_process_code": "04", "project_process_name": "製造・単体テスト" },
                    ],
                    "use_skills": [
                        { "skill_code": "s01020", "skill_name": "VB.net" },
                        { "skill_code": "s01023", "skill_name": "PL/SQL" },
                        { "skill_code": "s03006", "skill_name": "Oracle" },
                    ],
                    "created_at": "2023/3/8 17:28",
                    "created_user_id": "210001",
                    "created_user_name": "人事　太郎",
                    "updated_at": "2023/3/8 17:28",
                    "updated_user_id": "210001",
                    "updated_user_name": "人事　太郎",
                },

                {
                    "work_history_id": "c8bb4bb8-996e-48d8-840a-343142e5d254",
                    "employee_id": "210002",
                    "employee_name": "人事　花子",
                    "project_code": "AAA01",
                    "project_name": "プロジェクトAAA01",
                    "start_date": "2022/3/1",
                    "end_date": "2023/2/28",
                    "work_content": "倉庫管理システム開発",
                    "project_position_code": "M",
                    "project_position_name": "メンバー",
                    "project_processes": [
                        { "project_process_code": "03", "project_process_name": "詳細設計" },
                        { "project_process_code": "04", "project_process_name": "製造・単体テスト" },
                        { "project_process_code": "05", "project_process_name": "結合テスト" },
                    ],
                    "use_skills": [
                        { "skill_code": "s01003", "skill_name": "C#" },
                        { "skill_code": "s03008", "skill_name": "SQLServer" },
                    ],
                    "created_at": "2023/3/8 17:28",
                    "created_user_id": "210001",
                    "created_user_name": "人事　太郎",
                    "updated_at": "2023/3/8 17:28",
                    "updated_user_id": "210001",
                    "updated_user_name": "人事　太郎",
                },

                {
                    "work_history_id": "a0a4227c-20f1-43da-b2ff-24c979a02ccc",
                    "employee_id": "210002",
                    "employee_name": "人事　花子",
                    "project_code": "AAA02",
                    "project_name": "プロジェクトAAA02",
                    "start_date": "2023/3/1",
                    "end_date": "2023/5/31",
                    "work_content": "○○システム開発",
                    "project_position_code": "M",
                    "project_position_name": "メンバー",
                    "project_processes": [
                        { "project_process_code": "03", "project_process_name": "詳細設計" },
                        { "project_process_code": "04", "project_process_name": "製造・単体テスト" },
                    ],
                    "use_skills": [
                        { "skill_code": "s01020", "skill_name": "VB.net" },
                        { "skill_code": "s01023", "skill_name": "PL/SQL" },
                        { "skill_code": "s03006", "skill_name": "Oracle" },
                    ],
                    "created_at": "2023/3/8 17:28",
                    "created_user_id": "210001",
                    "created_user_name": "人事　太郎",
                    "updated_at": "2023/3/8 17:28",
                    "updated_user_id": "210001",
                    "updated_user_name": "人事　太郎",
                },

            ]
        };
        
        let filtered = work_histories;

        // 職務経歴IDでフィルタ(完全一致)
        if (work_history_id) {
            filtered.work_histories = filtered.work_histories.filter(v => v.work_history_id === work_history_id);
        }
        // 従業員コードでフィルタ(完全一致)
        if (employee_id) {
            filtered.work_histories = filtered.work_histories.filter(v => v.employee_id === employee_id);
        }
        // 従業員名でフィルタ(部分一致)
        if (employee_name) {
            filtered.work_histories = filtered.work_histories.filter(v => v.employee_name.indexOf(employee_name) !== -1);
        }
        // プロジェクトコードでフィルタ(完全一致)
        if (project_code) {
            filtered.work_histories = filtered.work_histories.filter(v => v.project_code === project_code);
        }
        
        // count
        filtered.count = filtered.work_histories.length;

        // 仮想処理時間
        await new Promise(s => setTimeout(s, 2000));

        return res(ctx.json(filtered));
    }

    // POST
    if (req.method === 'POST') {
        const workHistories = {
            "count": 1,
            "work_histories": [
                {
                    "work_history_id": "3eec5034-feee-4c36-a2f5-1ea9cd4933e6",
                    "employee_id": "210001",
                    "employee_name": "人事　太郎",
                    "project_code": "AAA01",
                    "project_name": "プロジェクトAAA01",
                    "start_date": "2022/3/1",
                    "end_date": "2023/2/28",
                    "work_content": "倉庫管理システム開発",
                    "project_position_code": "PM",
                    "project_position_name": "プロジェクトマネージャー",
                    "project_processes": [
                        { "project_process_code": "01", "project_process_name": "要件定義" },
                        { "project_process_code": "02", "project_process_name": "基本設計" },
                        { "project_process_code": "03", "project_process_name": "詳細設計" },
                        { "project_process_code": "04", "project_process_name": "製造・単体テスト" },
                        { "project_process_code": "05", "project_process_name": "結合テスト" },
                        { "project_process_code": "06", "project_process_name": "システムテスト" },
                        { "project_process_code": "08", "project_process_name": "導入・設置" },
                    ],
                    "use_skills": [
                        { "skill_code": "s01003", "skill_name": "C#" },
                        { "skill_code": "s03008", "skill_name": "SQLServer" },
                    ],
                    "created_at": "2023/3/8 17:28",
                    "created_user_id": "210001",
                    "created_user_name": "人事　太郎",
                    "updated_at": "2023/3/8 17:28",
                    "updated_user_id": "210001",
                    "updated_user_name": "人事　太郎",
                },
            ]
        };

        // 仮想処理時間
        await new Promise(s => setTimeout(s, 2000));

        return res(ctx.status(201), ctx.json(workHistories));
    }

    // PUT
    if (req.method === 'PUT') {
        const workhistory = {
            "work_history_id": "3eec5034-feee-4c36-a2f5-1ea9cd4933e6",
            "employee_id": "210001",
            "employee_name": "人事　太郎",
            "project_code": "AAA01",
            "project_name": "プロジェクトAAA01",
            "start_date": "2022/3/1",
            "end_date": "2023/2/28",
            "work_content": "倉庫管理システム開発",
            "project_position_code": "PM",
            "project_position_name": "プロジェクトマネージャー",
            "project_processes": [
                { "project_process_code": "01", "project_process_name": "要件定義" },
                { "project_process_code": "02", "project_process_name": "基本設計" },
                { "project_process_code": "03", "project_process_name": "詳細設計" },
                { "project_process_code": "04", "project_process_name": "製造・単体テスト" },
                { "project_process_code": "05", "project_process_name": "結合テスト" },
                { "project_process_code": "06", "project_process_name": "システムテスト" },
                { "project_process_code": "08", "project_process_name": "導入・設置" },
            ],
            "use_skills": [
                { "skill_code": "s01003", "skill_name": "C#" },
                { "skill_code": "s03008", "skill_name": "SQLServer" },
            ],
            "created_at": "2023/3/8 17:28",
            "created_user_id": "210001",
            "created_user_name": "人事　太郎",
            "updated_at": "2023/3/8 17:28",
            "updated_user_id": "210001",
            "updated_user_name": "人事　太郎",
        };

        // 仮想処理時間
        await new Promise(s => setTimeout(s, 2000));

        return res(ctx.status(200), ctx.json(workhistory));
    }

    // DELETE
    if (req.method === 'DELETE') {
        // 仮想処理時間
        await new Promise(s => setTimeout(s, 2000));        

        return res(ctx.status(200));
    }
};
  
export default mockWorkHistories;
