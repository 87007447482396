import { useContext, useEffect, useState } from 'react';
import { API, Auth } from 'aws-amplify';
import { Link, useHistory } from 'react-router-dom';
import { Button, Grid, IconButton, MenuItem, TextField, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { DataGrid, RowsProp, CellParams } from '@material-ui/data-grid';
import { Search, ArrowForward, Add } from '@material-ui/icons';

import { amplifyAuth, loadingContext } from '../App';
import Api from '../api.json';
import { TPossessionSkills } from '../ApiInterface/PossessionSkill';
import { TSkillField } from '../ApiInterface/SkillField';
import { TSkill } from '../ApiInterface/Skill';
import { defaultPermissionAuthority, TPermissionAuthority } from '../ApiInterface/PermissionAuthority';
import { getPermissionAuthority, canReference, canPost } from '../Authority/PermissionAuthority';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            '& > *': {
                margin: theme.spacing(1),
            },
        },
        gridItem: {
            marginBottom: theme.spacing(1),
        },
        select: {
            minWidth: 210,   // TextField.width:210
            width: 210
        },
        dataGrid: {
            marginTop: theme.spacing(2),
            height: 56 + 52 * 10 + 56,     // 632 = header:56 + row:52 * 10rows + footer:56?
            width: '100%'
        }
    }),
);

// 検索条件interface
export interface ISearchConditions {
    employee_id: string;            // 社員コード
    employee_name: string;          // 氏名
    skill_field_code: string;       // 技能分野コード
    skill_code: string;             // 技能コード
    is_searched: boolean;           // 検索済
}

// default function
const SearchPossessionSkill = () => {
    const classes = useStyles();

    // 検索条件hooks
    const [searchConditions, setSearchConditions] = useState<ISearchConditions>({
        employee_id: "",            // 社員コード
        employee_name: "",          // 氏名
        skill_field_code: "",       // 技能分野コード
        skill_code: "",             // 技能コード
        is_searched: false,         // 検索済
    });
    
    const history = useHistory<ISearchConditions>();

    // pathname
    const pathname = window.location.pathname;

    // 画面権限 State
    const [permissionAuthority, setPermissionAuthority] = useState<TPermissionAuthority>(defaultPermissionAuthority);
    const [permissionAuthorityMessage, setPermissionAuthorityMesssage] = useState('Loading...');

    // 技能分野リストState
    const [skillFields, setSkillFields] = useState<TSkillField[]>([]);

    // 技能リストState
    const [skills, setSkills] = useState<TSkill[]>([]);
    const [filteredSkills, setFilteredSkills] = useState<TSkill[]>([]);
    
    // location.stateから検索条件を取得し検索
    useEffect(() => {
        if (history?.location.state !== undefined) {
            console.log(history.location.state);
            setSearchConditions(history.location.state);

            // 技能リストフィルタ
            setFilteredSkills(skills.filter(skill => { return skill.skill_field_code === history.location.state.skill_field_code }));

            // 検索済の場合再検索
            if (history.location.state.is_searched) {
                search(history.location.state);
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [skills]);

    useEffect(() => {
        // リストリソース取得
        getResources();

        // 画面権限取得
        loading.setIsLoading(true);
        getPermissionAuthority().then(r => {
            setPermissionAuthority(r);
            if (!canReference(r, pathname)) {
                setPermissionAuthorityMesssage("参照権限がありません。");
            }
        }).finally(() => {
            loading.setIsLoading(false);
        });

        // 初回のみ実行するため、第2引数の指定は無し。
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // リストリソース取得非同期関数
    const getResources = async () => {
        await Auth.currentSession()
            .then(r => {
                return r.getIdToken().getJwtToken();
            })
            .then(async (jwtToken) => {
                const apiInit = {
                    headers: {
                        Authorization: jwtToken
                    }
                };

                // APIコール 技能分野リスト取得
                API.get(Api.apiName, Api.apis.skillFields.path, apiInit)
                    .then(r => {
                        console.log("API Response", r);
                        // 技能分野リストを取得しStateに設定
                        setSkillFields(r.skill_fields);
                    })
                    .catch(e => {
                        console.log("API.get error:", e);
                        alert(e);
                    });

                // APIコール 技能リスト取得
                API.get(Api.apiName, Api.apis.skills.path, apiInit)
                    .then(r => {
                        console.log("API Response", r);
                        // 技能リストを取得しStateに設定
                        setSkills(r.skills);
                    })
                    .catch(e => {
                        console.log("API.get error:", e);
                        alert(e);
                    });
            })
            .catch(e => {
                console.log("Auth.currentSession error", e);
                // {code: "NotAuthorizedException", name: "NotAuthorizedException", message: "Refresh Token has expired"}が返った場合は再認証。
                if (e.code === "NotAuthorizedException") {
                    alert("タイムアウトにより認証情報が無効となりました。トップページに遷移します。")
                    // 再認証
                    amplifyAuth();
                } else {
                    alert(e.message !== undefined ? e.message : e);
                }
            });
    };


    // 検索条件入力変更ハンドル
    const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, id?: string) => {
        const targetId = id == null ? event.target.id : id;
        setSearchConditions({ ...searchConditions, [targetId]: event.target.value });
    }

    // 分野変更ハンドル
    const handleChangeSkillField = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, id: string) => {
        // 技能分野変更、技能クリア
        setSearchConditions({ ...searchConditions, [id]: event.target.value, skill_code: "" });
        // 技能リストフィルタ
        setFilteredSkills(skills.filter(skill => { return skill.skill_field_code === event.target.value }));
    };

    const [rows, setRows] = useState<RowsProp>([]);

    const loading = useContext(loadingContext);

    // 検索処理定義
    const search = (searchConditions: ISearchConditions) => {
        loading.setIsLoading(true);
        setRows([]);
        // 検索条件.検索済
        setSearchConditions({ ...searchConditions, is_searched: true });
        // 保有技能取得
        getPossessionSkills(searchConditions).then(() => loading.setIsLoading(false));
    };

    // 保有技能取得
    const getPossessionSkills = async (searchConditions: ISearchConditions) => {
        await Auth.currentSession()
            .then(r => {
                return r.getIdToken().getJwtToken();
            })
            .then(async (jwtToken) => {
                const apiInit = {
                    headers: {
                        Authorization: jwtToken
                    }
                };

                // 検索条件指定
                let params = {};
                // 社員コード
                if (searchConditions.employee_id.length !== 0) { params = { ...params, employee_id: searchConditions.employee_id }; }
                // 氏名
                if (searchConditions.employee_name.length !== 0) { params = { ...params, employee_name: searchConditions.employee_name }; }
                // 技能分野コード
                if (searchConditions.skill_field_code.length !== 0) { params = { ...params, skill_field_code: searchConditions.skill_field_code }; }
                // 技能コード
                if (searchConditions.skill_code.length !== 0) { params = { ...params, skill_code: searchConditions.skill_code }; }

                const getParams: string = Object.entries(params).length > 0 ? "?" + Object.entries(params).map((e) => `${e[0]}=${e[1]}`).join("&") : "";
                console.log(getParams);

                // APIコール
                await API.get(Api.apiName, Api.apis.possessionSkills.path + getParams, apiInit)
                    .then(r => {
                        console.log(r);
                        if (r !== null) {
                            setRows(getRowsProp(r));
                        }
                    })
                    .catch(e => {
                        console.log("API.get error:", e);
                        alert(e);
                    });
            })
            .catch(e => {
                console.log("Auth.currentSession error", e);
                // {code: "NotAuthorizedException", name: "NotAuthorizedException", message: "Refresh Token has expired"}が返った場合は再認証。
                if (e.code === "NotAuthorizedException") {
                    alert("タイムアウトにより認証情報が無効となりました。トップページに遷移します。")
                    // 再認証
                    amplifyAuth();
                } else {
                    alert(e.message !== undefined ? e.message : e);
                }
            });
    };

    // DataGrid行組立と取得
    const getRowsProp = (apiResponse: TPossessionSkills): RowsProp => {
        console.log(apiResponse);
        const rowsResult: RowsProp = apiResponse.possession_skills.map(r => {
            return {
                id: r.possession_skill_id,                  // ID:保有技能ID
                employee_id: r.employee_id,                 // 社員コード:従業員ID
                employee_name: r.employee_name,             // 氏名:従業員名
                skill_field_name: r.skill_field_name,       // 技能分野名
                skill_name: r.skill_name                    // 技能名
            };
        });

        return rowsResult;
    };

    return (
        <>
            {canReference(permissionAuthority, pathname) ? (
                // 画面権限 参照可の場合
                <div className={classes.root}>
                    <Grid container alignItems="center">
                        <Grid item>
                            <Typography component="h2">保有技能 検索</Typography>
                        </Grid>
                        <Grid item xs />
                    </Grid>
                    <div>
                        <Grid container spacing={2}>
                            <Grid container item spacing={1}>
                                <Grid item className={classes.gridItem}>
                                    <TextField id="employee_id" label="社員コード" variant="outlined" size="small" autoFocus value={searchConditions.employee_id} onChange={(event) => handleInputChange(event)} />
                                </Grid>
                                <Grid item className={classes.gridItem}>
                                    <TextField id="employee_name" label="氏名" variant="outlined" size="small" value={searchConditions.employee_name} onChange={(event) => handleInputChange(event)} />
                                </Grid>
                                <Grid item className={classes.gridItem}>
                                    <TextField id="skill_field_code" label="技能分野" variant="outlined" size="small" select className={classes.select}
                                        value={searchConditions.skill_field_code}
                                        onChange={(event) => handleChangeSkillField(event, "skill_field_code")}
                                    >
                                        <MenuItem value="">None</MenuItem>
                                        {skillFields.map((e) =>
                                            <MenuItem key={e.skill_field_code} value={e.skill_field_code}>{e.skill_field_name}</MenuItem>
                                        )}
                                    </TextField>
                                </Grid>
                                <Grid item className={classes.gridItem}>
                                    <TextField id="skill_code" label="技能" variant="outlined" size="small" select className={classes.select}
                                        value={searchConditions.skill_code}
                                        onChange={(event) => handleInputChange(event, "skill_code")}
                                    >
                                        <MenuItem value="">None</MenuItem>
                                        {filteredSkills
                                            // 名称でソート
                                            .sort((a, b) => {
                                                if(a.skill_name > b.skill_name) {
                                                    return 1;
                                                } else {
                                                    return -1;
                                                }
                                            })
                                            .map((e) =>
                                                <MenuItem key={e.skill_code} value={e.skill_code}>{e.skill_name}</MenuItem>
                                        )}
                                    </TextField>
                                </Grid>
                                <Grid item className={classes.gridItem}>
                                    <Button variant="contained" color="primary" startIcon={<Search />} onClick={() => search(searchConditions)}>検索</Button>
                                </Grid>
                                <Grid item xs />
                                    <Grid item className={classes.gridItem}>
                                        {canPost(permissionAuthority, Api.apis.possessionSkills.path) && (
                                            // 画面権限 追加可の場合
                                            <Button variant="contained" color="primary" startIcon={<Add />}
                                                onClick={() => { history.push('/possessionskill/detail', searchConditions) }}
                                            >追加</Button>
                                        )}
                                    </Grid>
                            </Grid>
                        </Grid>
                    </div>
                    <div>
                        <div className={classes.dataGrid}>
                            {/* rowsが更新されるとflex指定が無効化されるbugがあるっぽい https://www.gitmemory.com/issue/mui-org/material-ui-x/716/743188442 */}
                            <DataGrid autoPageSize pagination hideFooterSelectedRowCount rows={rows} columnBuffer={5} columns={[
                                { field: 'employee_id', headerName: '社員コード', width: 120 },
                                { field: 'employee_name', headerName: '氏名', width: 160 },
                                { field: 'skill_field_name', headerName: '技能分野名', width: 320 },
                                { field: 'skill_name', headerName: '技能名', width: 480 },
                                {
                                    field: 'id', headerName: '詳細', width: 80,
                                    renderCell: (params: CellParams) => (
                                        <IconButton color="primary" component={Link} to={{ pathname: `/possessionskill/detail/${params.value}`, state: searchConditions }} size="small"><ArrowForward /></IconButton>
                                    )
                                }
                            ]} />
                        </div>
                    </div>
                </div>
            ) : (
                // 画面権限 参照不可の場合
                <div className={classes.root}>
                    {/* <Typography>参照権限がありません。</Typography> */}
                    <Typography>{permissionAuthorityMessage}</Typography>
                </div>
            )}
        </>
    );
}

// default export
export default SearchPossessionSkill;
