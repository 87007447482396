import { ResponseResolver, MockedRequest, restContext } from 'msw';

const mockPermissions: ResponseResolver<MockedRequest, typeof restContext> = async (req, res, ctx) => {
    console.log(req);

    const permissions = {
        "apis": [
            { "path": "/employees", "method": "GET" },
            { "path": "/employees/{emp_id}", "method": "GET" },
            { "path": "/employees/official_positions", "method": "GET" },
            { "path": "/employees/recruitment_categories", "method": "GET" },
            { "path": "/employees/organizations", "method": "GET" },
            { "path": "/smilev/employees", "method": "POST" },
            { "path": "/smilev/addresses", "method": "POST" },
            { "path": "/smilev/office_organizations", "method": "POST" },
            { "path": "/smilev/concurrent_posts", "method": "POST" },
            { "path": "/smilev/organizations", "method": "POST" },
            { "path": "/smilev/educational_backgrounds", "method": "POST" },
            { "path": "/quicksight/employees_analysis_dashboard_embed_url", "method": "GET" },
            { "path": "/quicksight/employees_skill_and_qualification_analysis_dashboard_embed_url", "method": "GET" },
            { "path": "/skill_fields", "method": "GET" },
            { "path": "/skills", "method": "GET" },
            { "path": "/skill_levels", "method": "GET" },
            { "path": "/possession_skills", "method": "GET" },
            { "path": "/possession_skills", "method": "POST" },
            { "path": "/possession_skills/{possession_skill_id}", "method": "PUT" },
            { "path": "/possession_skills/{possession_skill_id}", "method": "DELETE" },
            { "path": "/qualification_fields", "method": "GET" },
            { "path": "/qualifications", "method": "GET" },
            { "path": "/possession_qualifications", "method": "GET" },
            { "path": "/possession_qualifications", "method": "POST" },
            { "path": "/possession_qualifications/{possession_qualification_id}", "method": "PUT" },
            { "path": "/possession_qualifications/{possession_qualification_id}", "method": "DELETE" },

            { "path": "/project_fields", "method": "GET" },
            { "path": "/business_types", "method": "GET" },
            { "path": "/contract_types", "method": "GET" },
            { "path": "/projects", "method": "GET" },
            { "path": "/projects", "method": "POST" },
            { "path": "/projects/{project_code}", "method": "PUT" },
            { "path": "/projects/{project_code}", "method": "DELETE" },
            { "path": "/obpm/accounts", "method": "POST" }, 
            { "path": "/quicksight/employees_grade_analysis_dashboard_embed_url", "method": "GET" },
            { "path": "/work_histories", "method": "GET" },
            { "path": "/work_histories", "method": "POST" },
            { "path": "/work_histories/{work_history_id}", "method": "PUT" },
            { "path": "/work_histories/{work_history_id}", "method": "DELETE" },
            { "path": "/work_history_sheets", "method": "GET" },
            
            { "path": "/mikiwame/examinees", "method": "POST" }, 
            { "path": "/ris/behavioral_characteristics_evaluations", "method": "POST" }, 
            { "path": "/ris/objective_management_evaluations", "method": "POST" }, 
            { "path": "/quicksight/employees_evaluation_analysis_dashboard_embed_url", "method": "GET" },
        ],
        "menus": [
            { "path": "/employee" },
            { "path": "/smilev/import" },
            { "path": "/employeesanalysis" },
            { "path": "/possessionqualification" },
            { "path": "/possessionskill" },
            { "path": "/skillqualificationanalysis" },

            { "path": "/project" },
            { "path": "/obpm/import" },
            { "path": "/gradeanalysis" },
            { "path": "/workhistory" },
            { "path": "/workhistorysheet" },

            { "path": "/mikiwame/import" },
            { "path": "/evaluation/import" },
            { "path": "/evaluationanalysis" },
        ],
        "forms": [
            { "path": "/employee" },
            { "path": "/employee/detail/:id" },
            { "path": "/smilev/import" },
            { "path": "/employeesanalysis" },
            { "path": "/possessionqualification" },
            { "path": "/possessionqualification/detail" },
            { "path": "/possessionqualification/detail/:id" },
            { "path": "/possessionskill" },
            { "path": "/possessionskill/detail" },
            { "path": "/possessionskill/detail/:id" },
            { "path": "/skillqualificationanalysis" },
            
            { "path": "/project" },
            { "path": "/project/detail" },
            { "path": "/project/detail/:id" },
            { "path": "/obpm/import" },
            { "path": "/gradeanalysis" },
            { "path": "/workhistory" },
            { "path": "/workhistory/detail" },
            { "path": "/workhistory/detail/:id" },
            { "path": "/workhistorysheet" },

            { "path": "/mikiwame/import" },
            { "path": "/evaluation/import" },
            { "path": "/evaluationanalysis" },
        ]
    };

    // 仮想処理時間
    await new Promise(s => setTimeout(s, 2000));

    return res(ctx.json(permissions));
};
  
export default mockPermissions;
