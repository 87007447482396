import React, { createContext, useCallback, useEffect, useState } from 'react';
import Amplify, { API, Auth } from 'aws-amplify';
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth/lib/types";
import clsx from 'clsx';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import { AppBar, Collapse, CssBaseline, Drawer, IconButton, LinearProgress, List, ListItem, ListItemText, Toolbar, Typography } from '@material-ui/core';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { Menu } from '@material-ui/icons';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import ja from 'date-fns/locale/ja';

// import { initializeGA, useTracking } from './Utils/GoogleAnalyticsUtil';

import Home from './Home/Home';
import SearchEmployee from './Employee/SearchEmployee';           // 人事基本情報検索
import DetailEmployee from './Employee/DetailEmployee';           // 人事基本情報詳細
import ImportSmileV from './SmileV/ImportSmileV';                 // SmileVインポート
import EmployeesAnalysis from './QuickSight/EmployeesAnalysis';   // 人事基本情報分析
import SearchPossessionQualification from './PossessionQualification/SearchPossessionQualification';    // 保有資格検索
import DetailPossessionQualification from './PossessionQualification/DetailPossessionQualification';    // 保有資格詳細
import SearchPossessionSkill from './PossessionSkill/SearchPossessionSkill';    // 保有技能検索
import DetailPossessionSkill from './PossessionSkill/DetailPossessionSkill';    // 保有技能詳細
import SkillQualificationAnalysis from './QuickSight/SkillQualificationAnalysis';   // 技能・資格保有者分析
import SearchProject from './Project/SearchProject';    // プロジェクトマスタ検索
import DetailProject from './Project/DetailProject';    // プロジェクトマスタ詳細
import ImportObpm from './Obpm/ImportObpm';             // OBPMインポート
import GradeAnalysis from './QuickSight/GradeAnalysis';   // グレード分析
import SearchWorkHistory from './WorkHistory/SearchWorkHistory';    // 職務経歴検索
import DetailWorkHistory from './WorkHistory/DetailWorkHistory';    // 職務経歴詳細
import ExportWorkHistorySheet from './WorkHistorySheet/ExportWorkHistorySheet';   // 職務経歴書出力
import ImportMikiwame from './Mikiwame/ImportMikiwame';         // ミキワメインポート
import ImportEvaluation from './Evaluation/ImportEvaluation';   // 評価インポート
import EvaluationAnalysis from './QuickSight/EvaluationAnalysis';   // 評価分析

import amplifyConfig from "./amplify.json";
import Api from './api.json';
import ResourcesMenu from "./ApiInterface/ResoucesMenuStub.json";
import { defaultMenuAuthorities, TMenuAuthorities } from './ApiInterface/MenuAuthority';

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
    },
    title: {
      flexGrow: 1,
      textDecoration: 'none',
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    drawerContainer: {
      overflow: 'auto',
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(2),
      backgroundColor: theme.palette.background.paper,
    },
  }),
);

type LoadingContext = {
  loading: boolean;
  setIsLoading: (isLoading: boolean) => void;
};

const defaultLoadingContext: LoadingContext = {
  loading: false,
  setIsLoading: () => { },
};

export const loadingContext = createContext<LoadingContext>(defaultLoadingContext);

// ローディング制御
export const useLoading = (): LoadingContext => {
  const [loading, setLoading] = useState(false);
  const setIsLoading = useCallback((current: boolean): void => {
    setLoading(current);
  }, []);
  return { loading, setIsLoading };
};

// Amplify認証
export const amplifyAuth = async () => {
  // ログイン情報取得
  await Auth.currentAuthenticatedUser()
  .then(r => {
    console.log("currentAuthenticatedUser:", r);
  })
  .catch(e => {
    console.log("currentAuthenticatedUser error:", e)
    const f = async () => {
      // ソーシャルログイン
      await Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Google })
      .then(() => {
        console.log("federatedSignIn");
      })
      .catch(e => {
        console.log("federatedSignIn error:", e);
      });
    };
    f();
  });
};

// default function
export default function App() {
  const classes = useStyles();

  // const googleAnalyticsId ="G-JXTC6QC3XR";
  // useEffect(() => {
  //   // GoogleAnalytics gtag.js読み込み
  //   initializeGA(googleAnalyticsId);
  // }, [googleAnalyticsId]);
  // // GoogleAnalytics トラッキング
  // useTracking(googleAnalyticsId);

  // Amplify設定値を環境変数で設定
  amplifyConfig.Auth.userPoolId = process.env.REACT_APP_USER_POOL_ID || "ap-northeast-1_GukO6KSmR";
  amplifyConfig.Auth.userPoolWebClientId = process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID || "169qr2q1mnbeeihqv62gecmvmm";
  amplifyConfig.Auth.oauth.domain = process.env.REACT_APP_DOMAIN || 'ris-hrms-dev.auth.ap-northeast-1.amazoncognito.com';
  amplifyConfig.Auth.oauth.redirectSignIn = process.env.REACT_APP_REDIRECT_SIGN_IN || 'http://localhost:3000/';
  amplifyConfig.Auth.oauth.redirectSignOut = process.env.REACT_APP_REDIRECT_SIGN_OUT || 'http://localhost:3000/';
  amplifyConfig.API.endpoints[0].endpoint = process.env.REACT_APP_API_ENDPOINT || 'https://rj10qkhj6f.execute-api.ap-northeast-1.amazonaws.com/dev';
  // console.log(amplifyConfig);
  Amplify.configure(amplifyConfig);

  // サインイン状況
  const [isSignIn, setIsSignIn] = useState(false);
  // 初期表示時ユーザ認証
  useEffect(() => {
    const f = async () => {
      // ログイン情報取得
      await Auth.currentAuthenticatedUser()
        .then(r => {
          console.log("currentAuthenticatedUser:", r);
          setIsSignIn(true);
        })
        .catch(e => {
          console.log("currentAuthenticatedUser error:", e);
          setIsSignIn(false);
          const f = async () => {
            // ソーシャルログイン
            await Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Google })
              .then(() => {
                console.log("federatedSignIn");
              })
              .catch(e => {
                console.log("federatedSignIn error:", e);
              });
          };
          f();
        });
    };
    f();
  }, []);


  useEffect(() => {
    if (isSignIn) {
      // メニュー取得
      loading.setIsLoading(true);
      getMenus().then(() => loading.setIsLoading(false));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSignIn]);


  // Drawer開閉制御
  const [isOpen, setIsOpen] = useState(true);
  const toggleDeawer = () => {
    // prevState:以前の状態
    setIsOpen(prevState => !prevState);
  };

  const loading = useLoading();

  const [menus, setMenus] = useState<TMenuAuthorities>(defaultMenuAuthorities);

  // メニュー取得
  const getMenus = async () => {
    await Auth.currentSession()
    .then(r => {
        return r.getIdToken().getJwtToken();
    })
    .then(async (jwtToken) => {
        const apiInit = {
            headers: {
                Authorization: jwtToken
            }
        };

        // APIコール メニューリスト取得
        await API.get(Api.apiName, Api.apis.menus.path, apiInit)
            .then(r => {
                console.log("API Response", r);
                // メニューリストを取得しStateに設定
                setMenus(r);
            })
            .catch(e => {
                console.log("API.get error:", e);
                alert(e);
            });
    })
    .catch(e => {
        console.log("Auth.currentSession error", e);
        // {code: "NotAuthorizedException", name: "NotAuthorizedException", message: "Refresh Token has expired"}が返った場合は再認証。
        if (e.code === "NotAuthorizedException") {
            alert("タイムアウトにより認証情報が無効となりました。トップページに遷移します。")
            // 再認証
            amplifyAuth();
        } else {
            alert(e.message !== undefined ? e.message : e);
        }
    });    
  };
  

  // メニュー選択表示制御
  const [selectedMenuId, setSelectedMenuId] = useState<number | null>(null);
  const handleMenuItemClick = (id: number | null) => {
    setSelectedMenuId(id);
  };

  return (
    <>
      {isSignIn ? (
        // サインイン済の表示
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ja}>
          <div className={classes.root}>
            <Router>
              <loadingContext.Provider value={loading}>
                <CssBaseline />
                <AppBar position="fixed" className={classes.appBar}>
                  <Toolbar>
                    <IconButton color="inherit" onClick={toggleDeawer} edge="start"><Menu /></IconButton>
                    <Typography variant="h6" noWrap component={Link} to="/" color="inherit" style={{ textDecoration: 'none' }} onClick={() => handleMenuItemClick(null)}>HRMS</Typography>
                    <Typography style={{ flexGrow: 1 }} />
                  </Toolbar>
                  <Collapse in={loading.loading}>
                    <LinearProgress color="secondary" />
                  </Collapse >
                </AppBar>
                <Drawer
                  variant="permanent"
                  className={clsx(classes.drawer, {
                    [classes.drawerOpen]: isOpen,
                    [classes.drawerClose]: !isOpen,
                  })}
                  classes={{
                    paper: clsx({
                      [classes.drawerOpen]: isOpen,
                      [classes.drawerClose]: !isOpen,
                    }),
                  }}
                >
                  <Toolbar />
                  <div className={classes.drawerContainer}>
                    <List>
                      {/* Menuオブジェクト配列から構成 */}
                      {
                        ResourcesMenu.menus.filter(item =>
                            // 全メニューのうちapiレスポンスのpathに含まれるもの
                            menus.paths.some(p => p.path === item.path)
                        ).map(item =>
                            <ListItem key={item.menu_id} button component={Link} to={item.path}
                              selected={selectedMenuId === item.menu_id || new URL(window.location.href).pathname === item.path}
                              onClick={() => handleMenuItemClick(item.menu_id)}
                            >
                                <ListItemText primary={item.name} />
                            </ListItem>
                        )
                      }
                    </List>
                  </div>
                </Drawer>
                <main className={classes.content}>
                  <Toolbar />
                  <Switch>
                    {/* 人事基本情報検索 */}
                    <Route path="/employee" exact component={SearchEmployee} />
                    {/* 人事基本情報詳細 */}
                    <Route path="/employee/detail/:id" exact component={DetailEmployee} />
                    {/* SmileVインポート */}
                    <Route path="/smilev/import" exact component={ImportSmileV} />
                    {/* 人事基本情報分析 */}
                    <Route path="/employeesanalysis" exact component={EmployeesAnalysis} />
                    {/* 保有資格検索 */}
                    <Route path="/possessionqualification" exact component={SearchPossessionQualification} />
                    {/* 保有資格詳細(idなし) */}
                    <Route path="/possessionqualification/detail" exact component={DetailPossessionQualification} />
                    {/* 保有資格詳細(idあり) */}
                    <Route path="/possessionqualification/detail/:id" exact component={DetailPossessionQualification} />
                    {/* 保有技能検索 */}
                    <Route path="/possessionskill" exact component={SearchPossessionSkill} />
                    {/* 保有技能詳細(idなし) */}
                    <Route path="/possessionskill/detail" exact component={DetailPossessionSkill} />
                    {/* 保有技能詳細(idあり) */}
                    <Route path="/possessionskill/detail/:id" exact component={DetailPossessionSkill} />
                    {/* 技能・資格保有者分析 */}
                    <Route path="/skillqualificationanalysis" exact component={SkillQualificationAnalysis} />
                    {/* プロジェクトマスタ検索 */}
                    <Route path="/project" exact component={SearchProject} />
                    {/* プロジェクトマスタ詳細(idなし) */}
                    <Route path="/project/detail" exact component={DetailProject} />
                    {/* プロジェクトマスタ詳細(idあり) */}
                    <Route path="/project/detail/:id" exact component={DetailProject} />
                    {/* OBPMインポート */}
                    <Route path="/obpm/import" exact component={ImportObpm} />
                    {/* グレード分析 */}
                    <Route path="/gradeanalysis" exact component={GradeAnalysis} />
                    {/* 職務経歴検索 */}
                    <Route path="/workhistory" exact component={SearchWorkHistory} />
                    {/* 職務経歴詳細(idなし) */}
                    <Route path="/workhistory/detail" exact component={DetailWorkHistory} />
                    {/* 職務経歴詳細(idあり) */}
                    <Route path="/workhistory/detail/:id" exact component={DetailWorkHistory} />
                    {/* 職務経歴書出力 */}
                    <Route path="/workhistorysheet" exact component={ExportWorkHistorySheet} />
                    {/* ミキワメインポート */}
                    <Route path="/mikiwame/import" exact component={ImportMikiwame} />
                    {/* 評価インポート */}
                    <Route path="/evaluation/import" exact component={ImportEvaluation} />
                    {/* 評価分析 */}
                    <Route path="/evaluationanalysis" exact component={EvaluationAnalysis} />
                    {/* Home */}
                    <Route path="/" exact component={Home} />
                    <Route component={Home} />
                  </Switch>
                </main>
              </loadingContext.Provider>
            </Router>
          </div>
        </MuiPickersUtilsProvider>
      ) : (
        // 未サインインの表示
        <LinearProgress color="secondary" />
      )}
    </>
  );
}
