import { useContext, useEffect, useState } from 'react';
import { API, Auth } from 'aws-amplify';
import { Link, useHistory } from 'react-router-dom';
import { Button, Grid, IconButton, MenuItem, TextField, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { DataGrid, RowsProp, CellParams } from '@material-ui/data-grid';
import { Search, ArrowForward, Add } from '@material-ui/icons';

import { amplifyAuth, loadingContext } from '../App';
import Api from '../api.json';
import { TPossessionQualifications } from '../ApiInterface/PossessionQualification';
import { TQualificationField } from '../ApiInterface/QualificationField';
import { TQualification } from '../ApiInterface/Qualification';
import { defaultPermissionAuthority, TPermissionAuthority } from '../ApiInterface/PermissionAuthority';
import { getPermissionAuthority, canReference, canPost } from '../Authority/PermissionAuthority';


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            '& > *': {
                margin: theme.spacing(1),
            },
        },
        gridItem: {
            marginBottom: theme.spacing(1),
        },
        select: {
            minWidth: 210,   // TextField.width:210
            width: 210
        },
        dataGrid: {
            marginTop: theme.spacing(2),
            height: 56 + 52 * 10 + 56,     // 632 = header:56 + row:52 * 10rows + footer:56?
            width: '100%'
        }
    }),
);

// 検索条件interface
export interface ISearchConditions {
    employee_id: string;                    // 社員コード
    employee_name: string;                  // 氏名
    qualification_field_code: string;       // 資格分野コード
    qualification_code: string;             // 資格コード
    is_searched: boolean;                   // 検索済
}

// default function
const SearchPossessionQualification = () => {
    const classes = useStyles();

    // 検索条件hooks
    const [searchConditions, setSearchConditions] = useState<ISearchConditions>({
        employee_id: "",                    // 社員コード
        employee_name: "",                  // 氏名
        qualification_field_code: "",       // 資格分野コード
        qualification_code: "",             // 資格コード
        is_searched: false,                 // 検索済
    });
    
    const history = useHistory<ISearchConditions>();

    // pathname
    const pathname = window.location.pathname;

    // 画面権限 State
    const [permissionAuthority, setPermissionAuthority] = useState<TPermissionAuthority>(defaultPermissionAuthority);
    const [permissionAuthorityMessage, setPermissionAuthorityMesssage] = useState('Loading...');

    // 資格分野リストState
    const [qualificationFields, setQualificationFields] = useState<TQualificationField[]>([]);

    // 資格リストState
    const [qualifications, setQualifications] = useState<TQualification[]>([]);
    const [filteredQualifications, setFilteredQualifications] = useState<TQualification[]>([]);
    
    // location.stateから検索条件を取得し検索
    useEffect(() => {
        if (history?.location.state !== undefined) {
            console.log(history.location.state);
            setSearchConditions(history.location.state);

            // 資格リストフィルタ
            setFilteredQualifications(qualifications.filter(qualification => { return qualification.qualification_field_code === history.location.state.qualification_field_code }));

            // 検索済の場合再検索
            if (history.location.state.is_searched) {
                search(history.location.state);
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [qualifications]);

    useEffect(() => {
        // リストリソース取得
        getResources();

        // 画面権限取得
        loading.setIsLoading(true);
        getPermissionAuthority().then(r => {
            setPermissionAuthority(r);
            if (!canReference(r, pathname)) {
                setPermissionAuthorityMesssage("参照権限がありません。");
            }
        }).finally(() => {
            loading.setIsLoading(false);
        });

        // 初回のみ実行するため、第2引数の指定は無し。
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // リストリソース取得非同期関数
    const getResources = async () => {
        await Auth.currentSession()
            .then(r => {
                return r.getIdToken().getJwtToken();
            })
            .then(async (jwtToken) => {
                const apiInit = {
                    headers: {
                        Authorization: jwtToken
                    }
                };

                // APIコール 資格分野リスト取得
                API.get(Api.apiName, Api.apis.qualificationFields.path, apiInit)
                    .then(r => {
                        console.log("API Response", r);
                        // 資格分野リストを取得しStateに設定
                        setQualificationFields(r.qualification_fields);
                    })
                    .catch(e => {
                        console.log("API.get error:", e);
                        alert(e);
                    });

                // APIコール 資格リスト取得
                API.get(Api.apiName, Api.apis.qualifications.path, apiInit)
                    .then(r => {
                        console.log("API Response", r);
                        // 資格リストを取得しStateに設定
                        setQualifications(r.qualifications);
                    })
                    .catch(e => {
                        console.log("API.get error:", e);
                        alert(e);
                    });
            })
            .catch(e => {
                console.log("Auth.currentSession error", e);
                // {code: "NotAuthorizedException", name: "NotAuthorizedException", message: "Refresh Token has expired"}が返った場合は再認証。
                if (e.code === "NotAuthorizedException") {
                    alert("タイムアウトにより認証情報が無効となりました。トップページに遷移します。")
                    // 再認証
                    amplifyAuth();
                } else {
                    alert(e.message !== undefined ? e.message : e);
                }
            });
    };


    // 検索条件入力変更ハンドル
    const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, id?: string) => {
        const targetId = id == null ? event.target.id : id;
        setSearchConditions({ ...searchConditions, [targetId]: event.target.value });
    }

    // 分野変更ハンドル
    const handleChangeQualificationField = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, id: string) => {
        // 資格分野変更、資格クリア
        setSearchConditions({ ...searchConditions, [id]: event.target.value, qualification_code: "" });
        // 資格リストフィルタ
        setFilteredQualifications(qualifications.filter(qualification => { return qualification.qualification_field_code === event.target.value }));
    };

    const [rows, setRows] = useState<RowsProp>([]);

    const loading = useContext(loadingContext);

    // 検索処理定義
    const search = (searchConditions: ISearchConditions) => {
        loading.setIsLoading(true);
        setRows([]);
        // 検索条件.検索済
        setSearchConditions({ ...searchConditions, is_searched: true });
        // 保有資格取得
        getPossessionQualifications(searchConditions).then(() => loading.setIsLoading(false));
    };

    // 保有資格取得
    const getPossessionQualifications = async (searchConditions: ISearchConditions) => {
        await Auth.currentSession()
            .then(r => {
                return r.getIdToken().getJwtToken();
            })
            .then(async (jwtToken) => {
                const apiInit = {
                    headers: {
                        Authorization: jwtToken
                    }
                };

                // 検索条件指定
                let params = {};
                // 社員コード
                if (searchConditions.employee_id.length !== 0) { params = { ...params, employee_id: searchConditions.employee_id }; }
                // 氏名
                if (searchConditions.employee_name.length !== 0) { params = { ...params, employee_name: searchConditions.employee_name }; }
                // 資格分野コード
                if (searchConditions.qualification_field_code.length !== 0) { params = { ...params, qualification_field_code: searchConditions.qualification_field_code }; }
                // 資格コード
                if (searchConditions.qualification_code.length !== 0) { params = { ...params, qualification_code: searchConditions.qualification_code }; }

                const getParams: string = Object.entries(params).length > 0 ? "?" + Object.entries(params).map((e) => `${e[0]}=${e[1]}`).join("&") : "";
                console.log(getParams);

                // APIコール
                await API.get(Api.apiName, Api.apis.possessionQualifications.path + getParams, apiInit)
                    .then(r => {
                        console.log(r);
                        if (r !== null) {
                            setRows(getRowsProp(r));
                        }
                    })
                    .catch(e => {
                        console.log("API.get error:", e);
                        alert(e);
                    });
            })
            .catch(e => {
                console.log("Auth.currentSession error", e);
                // {code: "NotAuthorizedException", name: "NotAuthorizedException", message: "Refresh Token has expired"}が返った場合は再認証。
                if (e.code === "NotAuthorizedException") {
                    alert("タイムアウトにより認証情報が無効となりました。トップページに遷移します。")
                    // 再認証
                    amplifyAuth();
                } else {
                    alert(e.message !== undefined ? e.message : e);
                }
            });
    };

    // DataGrid行組立と取得
    const getRowsProp = (apiResponse: TPossessionQualifications): RowsProp => {
        console.log(apiResponse);
        const rowsResult: RowsProp = apiResponse.possession_qualifications.map(r => {
            return {
                id: r.possession_qualification_id,                          // ID:保有資格ID
                employee_id: r.employee_id,                                 // 社員コード:従業員ID
                employee_name: r.employee_name,                             // 氏名:従業員名
                qualification_field_name: r.qualification_field_name,       // 資格分野名
                qualification_name: r.qualification_name                    // 資格名
            };
        });

        return rowsResult;
    };

    return (
        <>
            {canReference(permissionAuthority, pathname) ? (
                // 画面権限 参照可の場合
                <div className={classes.root}>
                    <Grid container alignItems="center">
                        <Grid item>
                            <Typography component="h2">保有資格 検索</Typography>
                        </Grid>
                        <Grid item xs />
                    </Grid>
                    <div>
                        <Grid container spacing={2}>
                            <Grid container item spacing={1}>
                                <Grid item className={classes.gridItem}>
                                    <TextField id="employee_id" label="社員コード" variant="outlined" size="small" autoFocus value={searchConditions.employee_id} onChange={(event) => handleInputChange(event)} />
                                </Grid>
                                <Grid item className={classes.gridItem}>
                                    <TextField id="employee_name" label="氏名" variant="outlined" size="small" value={searchConditions.employee_name} onChange={(event) => handleInputChange(event)} />
                                </Grid>
                                <Grid item className={classes.gridItem}>
                                    <TextField id="qualification_field_code" label="資格分野" variant="outlined" size="small" select className={classes.select}
                                        value={searchConditions.qualification_field_code}
                                        onChange={(event) => handleChangeQualificationField(event, "qualification_field_code")}
                                    >
                                        <MenuItem value="">None</MenuItem>
                                        {qualificationFields.map((e) =>
                                            <MenuItem key={e.qualification_field_code} value={e.qualification_field_code}>{e.qualification_field_name}</MenuItem>
                                        )}
                                    </TextField>
                                </Grid>
                                <Grid item className={classes.gridItem}>
                                    <TextField id="qualification_code" label="資格" variant="outlined" size="small" select className={classes.select}
                                        value={searchConditions.qualification_code}
                                        onChange={(event) => handleInputChange(event, "qualification_code")}
                                    >
                                        <MenuItem value="">None</MenuItem>
                                        {filteredQualifications
                                            // 名称でソート
                                            .sort((a, b) => {
                                                if(a.qualification_name > b.qualification_name) {
                                                    return 1;
                                                } else {
                                                    return -1;
                                                }
                                            })
                                            .map((e) =>
                                                <MenuItem key={e.qualification_code} value={e.qualification_code}>{e.qualification_name}</MenuItem>
                                        )}
                                    </TextField>
                                </Grid>
                                <Grid item className={classes.gridItem}>
                                    <Button variant="contained" color="primary" startIcon={<Search />} onClick={() => search(searchConditions)}>検索</Button>
                                </Grid>
                                <Grid item xs />
                                    <Grid item className={classes.gridItem}>
                                        {canPost(permissionAuthority, Api.apis.possessionQualifications.path) && (
                                            // 画面権限 追加可の場合
                                            <Button variant="contained" color="primary" startIcon={<Add />}
                                                onClick={() => { history.push('/possessionqualification/detail', searchConditions) }}
                                            >追加</Button>
                                        )}
                                    </Grid>
                            </Grid>
                        </Grid>
                    </div>
                    <div>
                        <div className={classes.dataGrid}>
                            {/* rowsが更新されるとflex指定が無効化されるbugがあるっぽい https://www.gitmemory.com/issue/mui-org/material-ui-x/716/743188442 */}
                            <DataGrid autoPageSize pagination hideFooterSelectedRowCount rows={rows} columnBuffer={5} columns={[
                                { field: 'employee_id', headerName: '社員コード', width: 120 },
                                { field: 'employee_name', headerName: '氏名', width: 160 },
                                { field: 'qualification_field_name', headerName: '資格分野名', width: 320 },
                                { field: 'qualification_name', headerName: '資格名', width: 480 },
                                {
                                    field: 'id', headerName: '詳細', width: 80,
                                    renderCell: (params: CellParams) => (
                                        <IconButton color="primary" component={Link} to={{ pathname: `/possessionqualification/detail/${params.value}`, state: searchConditions }} size="small"><ArrowForward /></IconButton>
                                    )
                                }
                            ]} />
                        </div>
                    </div>
                </div>
            ) : (
                // 画面権限 参照不可の場合
                <div className={classes.root}>
                    {/* <Typography>参照権限がありません。</Typography> */}
                    <Typography>{permissionAuthorityMessage}</Typography>
                </div>
            )}
        </>
    );
}

// default export
export default SearchPossessionQualification;
