import React, { useContext, useEffect, useState } from 'react';
import { API, Auth } from 'aws-amplify';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import { Button, Checkbox, Chip, FormControlLabel, FormGroup, Grid, IconButton, MenuItem, Radio, RadioGroup, TextField, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Add, ArrowBack, Delete, Save } from '@material-ui/icons';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { format } from 'date-fns';

import { amplifyAuth, loadingContext } from '../App';
import { ISearchConditions } from './SearchWorkHistory';
import Api from '../api.json';
import { defaultWorkHistory, TUseSkill, TWorkHistory } from '../ApiInterface/WorkHistory';
import { TProject } from '../ApiInterface/Project';
import { TProjectPosition } from '../ApiInterface/ProjectPosition';
import { TProjectProcess } from '../ApiInterface/ProjectProcess';
import { TSkillField } from '../ApiInterface/SkillField';
import { TSkill } from '../ApiInterface/Skill';
import { defaultPermissionAuthority, TPermissionAuthority } from '../ApiInterface/PermissionAuthority';
import { getPermissionAuthority, canReference, canPost, canPut, canDelete } from '../Authority/PermissionAuthority';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            '& > *': {
                margin: theme.spacing(1),
            },
        },
        gridRow: {
            // marginBottom: theme.spacing(1),
        },
        gridItem: {
            marginBottom: theme.spacing(1),
        },
        halfWidth: {
            width: 210 / 2 - 8
        },
        doubleWidth: {
            width: 210 * 2 + 8
        },
        QuadrupleWidth: {
            width: 210 * 4 + 8*3
        },
        select: {
            minWidth: 210,  // TextField.width:210
            width: 210
        },
        selectHalfWidth: {
            minWidth: 210 / 2 -4, 
            width: 210 / 2 - 4
        },
        selectDoubleWidth: {
            minWidth: 210 * 2 + 8, 
            width: 210 * 2 + 8
        },
        selectQuadrupleWidth: {
            minWidth: 210 * 4 + 8*3, 
            width: 210 * 4 + 8*3
        },
        paper: {
            marginBottom: theme.spacing(1),
            padding: theme.spacing(1),
            paddingTop: theme.spacing(2),
            width: "100%"
        }
    }),
);

// default function
const DetailWorkHistory = () => {
    const classes = useStyles();

    const history = useHistory();
    const location = useLocation<ISearchConditions>();
    // console.log(location);

    // pathname
    const pathname = window.location.pathname;

    const [inputValues, setInputValues] = useState<TWorkHistory>(defaultWorkHistory);

    const [inputSkillFieldValues, setInputSkillFieldValues] = useState<string>("");
    const [inputSkillValues, setInputSkillValues] = useState<string>("");

    // Error State Type定義
    type TErrorState = {
        employee_id: boolean;               // 社員コード
        project_code: boolean;              // プロジェクト
        start_date: boolean;                // 開始日
        end_date: boolean;                  // 終了日
        work_content: boolean;              // 職務内容
        project_position_code: boolean;     // 役割
    };
    // Error State
    const [errorState, setErrorState] = useState<TErrorState>({
        employee_id: false,
        project_code: false,
        start_date: false,
        end_date: false,
        work_content: false,
        project_position_code: false,
    });

    // 画面権限 State
    const [permissionAuthority, setPermissionAuthority] = useState<TPermissionAuthority>(defaultPermissionAuthority);
    const [permissionAuthorityMessage, setPermissionAuthorityMesssage] = useState('Loading...');

    // プロジェクトリストState
    const [projects, setProjects] = useState<TProject[]>([]);

    // 役割リストstate
    const [projectPositions, setProjectPositions] = useState<TProjectPosition[]>([]);

    // 工程リストstate
    const [projectProcesses, setProjectProcesses] = useState<TProjectProcess[]>([]);
    
    // 技能分野リストState
    const [skillFields, setSkillFields] = useState<TSkillField[]>([]);

    // 技能リストState
    const [skills, setSkills] = useState<TSkill[]>([]);
    const [filteredSkills, setFilteredSkills] = useState<TSkill[]>([]);

    // // 重複State
    // const [isDuplicate, setIsDuplicate] = useState<boolean>(false);

    // リストリソース取得非同期関数
    const getResources = async () => {
        await Auth.currentSession()
            .then(r => {
                return r.getIdToken().getJwtToken();
            })
            .then(async (jwtToken) => {
                const apiInit = {
                    headers: {
                        Authorization: jwtToken
                    }
                };

                // APIコール プロジェクトリスト取得
                API.get(Api.apiName, Api.apis.projects.path, apiInit)
                    .then(r => {
                        console.log("API Response", r);
                        // プロジェクトリストを取得しStateに設定
                        setProjects(r.projects);
                    })
                    .catch(e => {
                        console.log("API.get error:", e);
                        alert(e);
                    });

                // APIコール 役割リスト取得
                API.get(Api.apiName, Api.apis.projectPositions.path, apiInit)
                    .then(r => {
                        console.log("API Response", r);
                        // 役割リストを取得しStateに設定
                        setProjectPositions(r.project_positions);
                    })
                    .catch(e => {
                        console.log("API.get error:", e);
                        alert(e);
                    });

                // APIコール 工程リスト取得
                API.get(Api.apiName, Api.apis.projectProcesses.path, apiInit)
                    .then(r => {
                        console.log("API Response", r);
                        // 工程リストを取得しStateに設定
                        setProjectProcesses(r.project_processes);
                    })
                    .catch(e => {
                        console.log("API.get error:", e);
                        alert(e);
                    });

                // APIコール 技能分野リスト取得
                await API.get(Api.apiName, Api.apis.skillFields.path, apiInit)
                    .then(r => {
                        console.log("API Response", r);
                        // 技能分野リストを取得しStateに設定
                        setSkillFields(r.skill_fields);
                    })
                    .catch(e => {
                        console.log("API.get error:", e);
                        alert(e);
                    });

                // APIコール 技能リスト取得
                await API.get(Api.apiName, Api.apis.skills.path, apiInit)
                    .then(r => {
                        console.log("API Response", r);
                        // 技能リストを取得しStateに設定
                        setSkills(r.skills);
                    })
                    .catch(e => {
                        console.log("API.get error:", e);
                        alert(e);
                    });

            })
            .catch(e => {
                console.log("Auth.currentSession error", e);
                // {code: "NotAuthorizedException", name: "NotAuthorizedException", message: "Refresh Token has expired"}が返った場合は再認証。
                if (e.code === "NotAuthorizedException") {
                    alert("タイムアウトにより認証情報が無効となりました。トップページに遷移します。")
                    // 再認証
                    amplifyAuth();
                } else {
                    alert(e.message !== undefined ? e.message : e);
                }
            });
    };

    // 入力値変更ハンドル
    const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, id?: string) => {
        const targetId = id == null ? event.target.id : id;
        setInputValues({ ...inputValues, [targetId]: event.target.value });
    }

    // 日付入直値変更ハンドル
    const handleDateChange = (date: Date | null, id: string) => {
        setInputValues({ ...inputValues, [id]: date });
    }
    
    // 役割変更ハンドル
    const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>, id: string) => {
        // console.log('radio:', (event.target as HTMLInputElement).value);
        setInputValues({ ...inputValues, [id]: (event.target as HTMLInputElement).value});
    };

    // 工程変更ハンドル
    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        // let projectProcesses = inputValues.project_processes;
        // オブジェクトコピー ※単純な参照代入だと再表示時に値が残る
        let projectProcesses = [...inputValues.project_processes];
        if (checked) {
            // 選択工程の追加
            projectProcesses.push({project_process_code: (event.target as HTMLInputElement).value, project_process_name: ''});
        } else {
            // 選択工程の削除
            const deleteProjectProcessIndex = projectProcesses.findIndex(v => v.project_process_code === (event.target as HTMLInputElement).value);
            delete projectProcesses[deleteProjectProcessIndex];
            projectProcesses = projectProcesses.filter(Boolean);
        }
        setInputValues({ ...inputValues, project_processes: projectProcesses});

        // console.log('inputValues:', inputValues);
    };

    // 技能分野変更ハンドル
    const handleChangeSkillField = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, id: string) => {
        // 技能分野変更、技能クリア
        setInputSkillFieldValues(event.target.value);
        setInputSkillValues("");
    };

    // 技能変更ハンドル
    const handleChangeSkill = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, id: string) => {
        // 技能変更
        setInputSkillValues(event.target.value);
    };

    // 技能追加
    const handleAddUseSkill = (skill_code: string) => {
        if (!isExistSkill(inputValues.use_skills, skill_code)) {
            // let useSkills = inputValues.use_skills;
            // オブジェクトコピー ※単純な参照代入だと再表示時に値が残る
            let useSkills = [...inputValues.use_skills];
            // 選択技能の追加
            useSkills.push({skill_code: skill_code, skill_name: getSkillName(skill_code)});
            setInputValues({ ...inputValues, use_skills: useSkills});
        }
        setInputSkillFieldValues("");
        setInputSkillValues("");
        // console.log('inputValues:', inputValues);
    };

    // 技能削除
    const handleDeleteUseSkill = (skill_code: string) => {
        // let useSkills = inputValues.use_skills;
        // オブジェクトコピー ※単純な参照代入だと再表示時に値が残る
        let useSkills = [...inputValues.use_skills];
        // 選択技能の削除
        const deleteUseSkillIndex = useSkills.findIndex(v => v.skill_code === skill_code);
        delete useSkills[deleteUseSkillIndex];
        useSkills = useSkills.filter(Boolean);
        setInputValues({ ...inputValues, use_skills: useSkills});
        // console.log('inputValues:', inputValues);
    };

    // 技能名取得(コード指定)
    const getSkillName = (skill_code: string): string => {
        const findSkill = skills.find(v => v.skill_code === skill_code);
        if (findSkill) {
            return findSkill.skill_name;
        } else {
            return '';
        }
    };

    // 技能コード存在確認
    const isExistSkill = (skills: TUseSkill[], skill_code: string): Boolean => {
        return skills.findIndex(v => v.skill_code === skill_code) !== -1
    };

    // 技能分野変更時Effect
    useEffect(() => {
        // 技能リストフィルタ
        setFilteredSkills(skills.filter(skill => { return skill.skill_field_code === inputSkillFieldValues }));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputSkillFieldValues]);

    // URLパラメータ
    const { id } = useParams<{id: string}>();
    // console.log('id: ', id);

    const loading = useContext(loadingContext);

    useEffect(() => {

        // 画面権限取得
        getPermissionAuthority().then(r => {
            setPermissionAuthority(r);
            if (!canReference(r, pathname, id)) {
                setPermissionAuthorityMesssage("参照権限がありません。");
            }
        });

        // リストリソース取得
        loading.setIsLoading(true);
        getResources().then(r => {
            if (id !== undefined) {
                loading.setIsLoading(true);
                getWorkHistories().then(() => loading.setIsLoading(false));
            }
            console.log('inputValues', inputValues);    
            loading.setIsLoading(false);
        });

        // 初回のみ実行するため、第2引数の指定は無し。
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // 職務経歴取得(id指定)
    const getWorkHistories = async () => {
        await Auth.currentSession()
            .then(r => {
                return r.getIdToken().getJwtToken();
            })
            .then(async (jwtToken) => {
                const apiInit = {
                    headers: {
                        Authorization: jwtToken
                    }
                };

                // APIコール
                await API.get(Api.apiName, Api.apis.workHistories.path + "?work_history_id=" + id, apiInit)
                    .then(r => {
                        console.log(r);
                        if (r.work_histories[0].work_history_id !== undefined) {
                            setForm(r.work_histories[0]);
                        }
                    })
                    .catch(e => {
                        console.log("API.get error:", e);
                        alert(e);
                    });
            })
            .catch(e => {
                console.log("Auth.currentSession error", e);
                // {code: "NotAuthorizedException", name: "NotAuthorizedException", message: "Refresh Token has expired"}が返った場合は再認証。
                if (e.code === "NotAuthorizedException") {
                    alert("タイムアウトにより認証情報が無効となりました。トップページに遷移します。")
                    // 再認証
                    amplifyAuth();
                } else {
                    alert(e.message !== undefined ? e.message : e);
                }
            });
    };

    // 画面項目値設定
    const setForm = (apiResponse: TWorkHistory) => {
        console.log('apiResponse', apiResponse);
        setInputValues(apiResponse);
    };

    // // 重複チェック
    // useEffect(() => {
    //     if (!id) {
    //         if (inputValues.employee_id.length === 6 && inputValues.skill_field_code !== '' && inputValues.skill_code !== '') {
    //             loading.setIsLoading(true);
    //             // 重複保有技能取得
    //             getDuplicatePossessionSkills().then(() => loading.setIsLoading(false));
    //         } else {
    //             setIsDuplicate(false);
    //         }
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [inputValues.employee_id, inputValues.skill_field_code, inputValues.skill_code]);

    // // 重複保有技能取得(社員コード, 技能分野, 技能指定)
    // const getDuplicatePossessionSkills = async () => {
    //     await Auth.currentSession()
    //         .then(r => {
    //             return r.getIdToken().getJwtToken();
    //         })
    //         .then(async (jwtToken) => {
    //             const apiInit = {
    //                 headers: {
    //                     Authorization: jwtToken
    //                 }
    //             };

    //             // APIコール
    //             await API.get(Api.apiName, Api.apis.possessionSkills.path
    //                 + "?employee_id=" + inputValues.employee_id
    //                 + "&skill_field_code=" + inputValues.skill_field_code
    //                 + "&skill_code=" + inputValues.skill_code, apiInit)
    //                 .then(r => {
    //                     console.log(r);
    //                     if (r.possession_skills.length > 0) {
    //                         setIsDuplicate(true);
    //                         alert("登録済みの保有技能です。");
    //                     } else{
    //                         setIsDuplicate(false);
    //                     }
    //                 })
    //                 .catch(e => {
    //                     console.log("API.get error:", e);
    //                     alert(e);
    //                 });
    //         })
    //         .catch(e => {
    //             console.log("Auth.currentSession error", e);
    //             // {code: "NotAuthorizedException", name: "NotAuthorizedException", message: "Refresh Token has expired"}が返った場合は再認証。
    //             if (e.code === "NotAuthorizedException") {
    //                 alert("タイムアウトにより認証情報が無効となりました。トップページに遷移します。")
    //                 // 再認証
    //                 amplifyAuth();
    //             } else {
    //                 alert(e.message !== undefined ? e.message : e);
    //             }
    //         });
    // };

    // 登録処理
    const saveData = () => {
        if (id) {
            // 変更
            loading.setIsLoading(true);
            putWorkHistories().then(() => {
                loading.setIsLoading(false);

                // 画面遷移
                history.push("/workhistory",  location.state);
            });

        } else {
            // 追加
            loading.setIsLoading(true);
            postWorkHistories().then(() => {
                loading.setIsLoading(false);

                // 画面遷移
                history.push("/workhistory",  location.state);
            });
        }
    };

    // 職務経歴登録(追加)
    const postWorkHistories = async () => {
        await Auth.currentSession()
            .then(r => {
                return r.getIdToken().getJwtToken();
            })
            .then(async (jwtToken) => {
                const apiInit = {
                    headers: {
                        Authorization: jwtToken
                    },
                    body: {
                        work_histories: [
                            {
                                employee_id: inputValues.employee_id,                           // 社員コード
                                project_code: inputValues.project_code,                         // プロジェクトコード
                                start_date: inputValues.start_date ? format(new Date(inputValues.start_date), "yyyy-MM-dd") : null,      // 開始年月日
                                end_date: inputValues.end_date ? format(new Date(inputValues.end_date), "yyyy-MM-dd") : null,            // 終了年月日
                                work_content: inputValues.work_content,                         // 職務内容
                                project_position_code: inputValues.project_position_code,       // 役割コード
                                project_processes: inputValues.project_processes.map(e => ({project_process_code: e.project_process_code})),        // 工程リスト
                                use_skills: inputValues.use_skills.map(e => ({skill_code: e.skill_code})),                                          // 使用技能リスト
                            }
                        ]
                    }
                };

                // console.log('start_date', inputValues.start_date);
                // console.log('end_date', inputValues.end_date);

                console.log('apiInit:', apiInit);

                // APIコール
                await API.post(Api.apiName, Api.apis.workHistories.path, apiInit)
                    .then(r => {
                        console.log(r);
                    })
                    .catch(e => {
                        console.log("API.post error:", e);
                        alert(e);
                    });
            })
            .catch(e => {
                console.log("Auth.currentSession error", e);
                // {code: "NotAuthorizedException", name: "NotAuthorizedException", message: "Refresh Token has expired"}が返った場合は再認証。
                if (e.code === "NotAuthorizedException") {
                    alert("タイムアウトにより認証情報が無効となりました。トップページに遷移します。")
                    // 再認証
                    amplifyAuth();
                } else {
                    alert(e.message !== undefined ? e.message : e);
                }
            });
    };

    // 職務経歴登録(変更)
    const putWorkHistories = async () => {
        await Auth.currentSession()
            .then(r => {
                return r.getIdToken().getJwtToken();
            })
            .then(async (jwtToken) => {
                const apiInit = {
                    headers: {
                        Authorization: jwtToken
                    },
                    body: {
                        project_code: inputValues.project_code,                         // プロジェクトコード
                        start_date: inputValues.start_date ? format(new Date(inputValues.start_date), "yyyy-MM-dd") : null,      // 開始年月日
                        end_date: inputValues.end_date ? format(new Date(inputValues.end_date), "yyyy-MM-dd") : null,            // 終了年月日
                        work_content: inputValues.work_content,                         // 職務内容
                        project_position_code: inputValues.project_position_code,       // 役割コード
                        project_processes: inputValues.project_processes.map(e => ({project_process_code: e.project_process_code})),        // 工程リスト
                        use_skills: inputValues.use_skills.map(e => ({skill_code: e.skill_code})),                                          // 使用技能リスト
                    }
                };

                console.log('apiInit:', apiInit);

                // APIコール
                await API.put(Api.apiName, Api.apis.workHistories.path + "/" + id, apiInit)
                    .then(r => {
                        console.log(r);
                    })
                    .catch(e => {
                        console.log("API.put error:", e);
                        alert(e);
                    });
            })
            .catch(e => {
                console.log("Auth.currentSession error", e);
                // {code: "NotAuthorizedException", name: "NotAuthorizedException", message: "Refresh Token has expired"}が返った場合は再認証。
                if (e.code === "NotAuthorizedException") {
                    alert("タイムアウトにより認証情報が無効となりました。トップページに遷移します。")
                    // 再認証
                    amplifyAuth();
                } else {
                    alert(e.message !== undefined ? e.message : e);
                }
            });
    };

    // 削除処理
    const deleteData = () => {
        loading.setIsLoading(true);
        deleteWorkHistories().then(() => {
            loading.setIsLoading(false);

            // 画面遷移
            history.push("/workhistory",  location.state);
        });

    };

    // 職務経歴削除
    const deleteWorkHistories = async () => {
        await Auth.currentSession()
            .then(r => {
                return r.getIdToken().getJwtToken();
            })
            .then(async (jwtToken) => {
                const apiInit = {
                    headers: {
                        Authorization: jwtToken
                    }
                };

                // APIコール
                await API.del(Api.apiName, Api.apis.workHistories.path + "/" + id, apiInit)
                    .then(r => {
                        console.log(r);
                    })
                    .catch(e => {
                        console.log("API.del error:", e);
                        alert(e);
                    });
            })
            .catch(e => {
                console.log("Auth.currentSession error", e);
                // {code: "NotAuthorizedException", name: "NotAuthorizedException", message: "Refresh Token has expired"}が返った場合は再認証。
                if (e.code === "NotAuthorizedException") {
                    alert("タイムアウトにより認証情報が無効となりました。トップページに遷移します。")
                    // 再認証
                    amplifyAuth();
                } else {
                    alert(e.message !== undefined ? e.message : e);
                }
            });
    };

    // 社員コード変更時
    useEffect(() => {
        if (!id) {
            if (inputValues.employee_id.length === 6) {
                loading.setIsLoading(true);
                getEmployees(inputValues.employee_id).then(() => loading.setIsLoading(false));
            } else {
                setInputValues({...inputValues, employee_name: ''});
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputValues.employee_id])

    // 人事基本情報取得(氏名)
    const getEmployees = async (employee_id: string) => {
        await Auth.currentSession()
            .then(r => {
                return r.getIdToken().getJwtToken();
            })
            .then(async (jwtToken) => {
                const apiInit = {
                    headers: {
                        Authorization: jwtToken
                    }
                };

                // APIコール
                await API.get(Api.apiName, Api.apis.employees.path + "?employee_id=" + employee_id, apiInit)
                    .then(r => {
                        console.log(r);
                        if (r.employees[0]?.employee_name !== undefined) {
                            // setInputValues({...inputValues, employee_name: r.employees[0].employee_name})
                            setInputValues(prevValues => ({...prevValues, employee_name: r.employees[0].employee_name}))
                        }
                    })
                    .catch(e => {
                        console.log("API.get error:", e);
                        alert(e);
                    });
            })
            .catch(e => {
                console.log("Auth.currentSession error", e);
                // {code: "NotAuthorizedException", name: "NotAuthorizedException", message: "Refresh Token has expired"}が返った場合は再認証。
                if (e.code === "NotAuthorizedException") {
                    alert("タイムアウトにより認証情報が無効となりました。トップページに遷移します。")
                    // 再認証
                    amplifyAuth();
                } else {
                    alert(e.message !== undefined ? e.message : e);
                }
            });
    };

    return (
        <>
            {canReference(permissionAuthority, pathname, id) ? (
                // 画面権限 参照可の場合
                <div className={classes.root}>
                    <Grid container alignItems="center">
                        <Grid item>
                            <IconButton component={Link} to={{ pathname: "/workhistory", state: location.state }} size="small"><ArrowBack /></IconButton>
                        </Grid>
                        <Grid item>
                            <Typography component="h2">職務経歴 詳細</Typography>
                        </Grid>
                        <Grid item xs />
                    </Grid>
                    <div>
                        <Grid container spacing={2}>
                            <Grid container item spacing={1} className={classes.gridRow} >
                                <Grid item className={classes.gridItem}>
                                    {id === undefined ? (
                                        // 職務経歴IDがない場合(変更可)
                                        <TextField id="employee_id" label="社員コード" variant="outlined" size="small" autoFocus
                                            value={inputValues.employee_id}
                                            required
                                            error={errorState.employee_id}
                                            onChange={(event) => {
                                                handleInputChange(event);
                                                // 入力チェック
                                                if (event.target.value.length === 6) {
                                                    setErrorState({ ...errorState, [event.target.id]: false});
                                                } else {
                                                    setErrorState({ ...errorState, [event.target.id]: true});
                                                }
                                            }}
                                            inputProps={{maxLength: 6}}
                                        />
                                    ) : (
                                        // 職務経歴IDがある場合(変更不可)
                                        <TextField id="employee_id" label="社員コード" variant="outlined" size="small"
                                            value={inputValues.employee_id}
                                            disabled
                                        />
                                    )}
                                </Grid>
                                <Grid item className={classes.gridItem}>
                                    <TextField id="employee_name" label="氏名" variant="outlined" size="small"
                                        value={inputValues.employee_name}
                                        disabled
                                    />
                                </Grid>
                                <Grid item className={classes.gridItem}>
                                    <TextField id="project_code" label="プロジェクト" variant="outlined" size="small" select className={classes.selectDoubleWidth}
                                        autoFocus={id !== undefined}
                                        value={inputValues.project_code}
                                        required
                                        onChange={(event) => handleInputChange(event, "project_code")}
                                    >
                                        <MenuItem value="">None</MenuItem>
                                        {projects.map((e) =>
                                            <MenuItem key={e.project_code} value={e.project_code}>{e.project_code}:{e.project_name}</MenuItem>
                                        )}
                                    </TextField>
                                </Grid>
                                <Grid item className={classes.gridItem}>
                                    <KeyboardDatePicker id="start_date" label="開始日" autoOk disableToolbar
                                        value={inputValues.start_date}
                                        inputVariant="outlined" variant="inline" size="small" format="yyyy/MM/dd" className={classes.select}
                                        required
                                        error={errorState.start_date}
                                        onChange={(date) => {
                                            handleDateChange(date, "start_date");
                                            // 入力チェック
                                            if (date === null || isNaN(date.getTime())) {
                                                setErrorState({ ...errorState, start_date: true});
                                            } else {
                                                setErrorState({ ...errorState, start_date: false});
                                            }
                                            // 開始日終了日相関チェック
                                            if (date !== null && inputValues.end_date !== null) {
                                                if (date.getTime() > new Date(inputValues.end_date).getTime()) {
                                                    setErrorState({ ...errorState, start_date: true, end_date: true});
                                                } else {
                                                    setErrorState({ ...errorState, start_date: false, end_date: false});
                                                }
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid item className={classes.gridItem}>
                                    <KeyboardDatePicker id="end_date" label="終了日" autoOk disableToolbar
                                        value={inputValues.end_date}
                                        inputVariant="outlined" variant="inline" size="small" format="yyyy/MM/dd" className={classes.select}
                                        required
                                        error={errorState.end_date}
                                        onChange={(date) => {
                                            handleDateChange(date, "end_date");
                                            // 入力チェック
                                            if (date === null || isNaN(date.getTime())) {
                                                setErrorState({ ...errorState, end_date: true});
                                            } else {
                                                setErrorState({ ...errorState, end_date: false});
                                            }
                                            // 開始日終了日相関チェック
                                            if (date !== null && inputValues.start_date !== null) {
                                                if (new Date(inputValues.start_date).getTime() > date.getTime()) {
                                                    setErrorState({ ...errorState, start_date: true, end_date: true});
                                                } else {
                                                    setErrorState({ ...errorState, start_date: false, end_date: false});
                                                }
                                            }
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item spacing={1} className={classes.gridRow}>
                                <Grid item className={classes.gridItem}>
                                    <TextField id="work_content" label="職務内容" variant="outlined" size="small" className={classes.QuadrupleWidth}
                                        value={inputValues.work_content}
                                        required
                                        error={errorState.work_content}
                                        onChange={(event) => {
                                            handleInputChange(event);
                                            // 入力チェック
                                            if (event.target.value.length > 0) {
                                                setErrorState({ ...errorState, [event.target.id]: false});
                                            } else {
                                                setErrorState({ ...errorState, [event.target.id]: true});
                                            }
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item spacing={1} className={classes.gridRow}>
                                <Grid item>
                                    <Typography component="h2">役割*</Typography>
                                </Grid>
                            </Grid>
                            <Grid container item spacing={1} className={classes.gridRow}>
                                <Grid item className={classes.gridItem}>
                                    <RadioGroup
                                        id="project_position_code"
                                        row
                                        value={inputValues.project_position_code}
                                        onChange={(event) => {handleRadioChange(event, "project_position_code");}}
                                    >
                                        {projectPositions.map((e) =>
                                            <FormControlLabel value={e.project_position_code} control={<Radio color='primary' />} label={e.project_position_name} />
                                        )}
                                    </RadioGroup>
                                </Grid>
                            </Grid>
                            <Grid container item spacing={1} className={classes.gridRow}>
                                <Grid item>
                                    <Typography component="h2">工程</Typography>
                                </Grid>
                            </Grid>
                            <Grid container item spacing={1} className={classes.gridRow}>
                                <Grid item className={classes.gridItem}>
                                    <FormGroup row>
                                        {projectProcesses.map((e) =>
                                            <FormControlLabel
                                                value={e.project_process_code}
                                                control={
                                                    <Checkbox
                                                        color='primary'
                                                        checked={inputValues.project_processes.findIndex(v => v.project_process_code === e.project_process_code) !== -1}
                                                        onChange={handleCheckboxChange}
                                                    />
                                                }
                                                label={e.project_process_name}
                                            />
                                        )}
                                    </FormGroup>
                                </Grid>
                            </Grid>
                            <Grid container item spacing={1} className={classes.gridRow}>
                                <Grid item>
                                    <Typography component="h2">使用技能</Typography>
                                </Grid>
                            </Grid>
                            <Grid container item spacing={1} className={classes.gridRow}>
                                <Grid item className={classes.gridItem}>
                                    <TextField id="skill_field_code" label="技能分野" variant="outlined" size="small" select className={classes.selectDoubleWidth} 
                                        value={inputSkillFieldValues}
                                        onChange={(event) => {
                                            handleChangeSkillField(event, "skill_field_code");
                                        }}
                                    >
                                        <MenuItem value="">None</MenuItem>
                                        {skillFields.map((e) =>
                                            <MenuItem key={e.skill_field_code} value={e.skill_field_code}>{e.skill_field_name}</MenuItem>
                                        )}
                                    </TextField>
                                </Grid>
                                <Grid item className={classes.gridItem}>
                                    <TextField id="skill_code" label="技能" variant="outlined" size="small" select className={classes.selectQuadrupleWidth} 
                                        value={inputSkillValues}
                                        onChange={(event) => {
                                            handleChangeSkill(event, "skill_code");
                                        }}
                                    >
                                        <MenuItem value="">None</MenuItem>
                                        {filteredSkills
                                            // 名称でソート
                                            .sort((a, b) => {
                                                if(a.skill_name > b.skill_name) {
                                                    return 1;
                                                } else {
                                                    return -1;
                                                }
                                            })
                                            .map((e) =>
                                                <MenuItem key={e.skill_code} value={e.skill_code}>{e.skill_name}</MenuItem>
                                        )}
                                    </TextField>
                                </Grid>
                                <Grid item className={classes.gridItem}>
                                    <Button variant="outlined" startIcon={<Add />}
                                        onClick={() => handleAddUseSkill(inputSkillValues)}
                                        // 非活性条件
                                        disabled={
                                            inputSkillFieldValues === ''        // 技能分野未選択
                                            || inputSkillValues === ''          // 技能未選択
                                        }
                                    >追加</Button>
                                </Grid>
                            </Grid>
                            <Grid container item spacing={1} className={classes.gridRow}>
                                {inputValues.use_skills
                                    .map((e) =>
                                        <Grid item className={classes.gridItem}>
                                            <Chip
                                                label={e.skill_name}
                                                onDelete={() => handleDeleteUseSkill(e.skill_code)}
                                                variant='outlined'
                                            />
                                        </Grid>
                                    )
                                }
                            </Grid>
                            <Grid item>
                                <Typography component="h2">最終更新</Typography>
                            </Grid>
                            <Grid container item spacing={1} className={classes.gridRow}>
                                <Grid item className={classes.gridItem}>
                                    <TextField id="updated_at" label="更新日時" variant="outlined" size="small" 
                                        value={inputValues.updated_at ? format(Date.parse(inputValues.updated_at), "Y/M/d H:m:s") : ""}
                                        disabled
                                    />
                                </Grid>
                                <Grid item className={classes.gridItem}>
                                    <TextField id="updated_user_name" label="更新者" variant="outlined" size="small"
                                        value={inputValues.updated_user_name}
                                        disabled
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item spacing={1} className={classes.gridRow}>
                                {((canPost(permissionAuthority, Api.apis.workHistories.path) && !id) || (canPut(permissionAuthority, Api.apis.workHistories.path) && id)) && (
                                    // 画面権限 追加可または更新可の場合
                                    <Grid item className={classes.gridItem}>
                                        <Button variant="contained" color="primary" startIcon={<Save />}
                                            onClick={() => saveData()}
                                            // 非活性条件
                                            disabled={
                                                inputValues.employee_id.length !== 6            // 社員コード6桁以外
                                                || inputValues.employee_name === ''             // 氏名未取得
                                                || inputValues.project_code === ''              // プロジェクトコード未選択
                                                || inputValues.start_date === null              // 開始日未入力
                                                || (inputValues.start_date && isNaN(new Date(inputValues.start_date).getTime()))    // 開始日不正
                                                || inputValues.end_date === null                // 終了日未入力
                                                || (inputValues.end_date && isNaN(new Date(inputValues.end_date).getTime()))        // 終了日不正
                                                || new Date(inputValues.start_date).getTime() > new Date(inputValues.end_date).getTime()        // 開始日終了日逆転
                                                || inputValues.work_content === ''              // 職務内容未入力
                                                || inputValues.project_position_code === ''     // 役割未選択
                                                
                                                // || isDuplicate                              // 重複
                                            }
                                        >登録</Button>
                                    </Grid>
                                )}
                                <Grid item xs />
                                {canDelete(permissionAuthority, Api.apis.workHistories.path) && id && (
                                    // 画面権限 削除可の場合
                                    <Grid item className={classes.gridItem}>
                                        <Button variant="contained" color="secondary" startIcon={<Delete />}
                                            onClick={() => deleteData()}
                                            // 非活性条件
                                            disabled={
                                                id === undefined        // 保有技能ID未設定
                                            }
                                        >削除</Button>
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                    </div>
                </div>
            ) : (
                // 画面権限 参照不可の場合
                <div className={classes.root}>
                    {/* <Typography>参照権限がありません。</Typography> */}
                    <Typography>{permissionAuthorityMessage}</Typography>
                </div>
            )}
                
        </>
    );
}

// default export
export default DetailWorkHistory;
