import { ResponseResolver, MockedRequest, restContext } from 'msw';

const mockProjectPositions: ResponseResolver<MockedRequest, typeof restContext> = async (req, res, ctx) => {
    console.log(req);

    const projectPositions = {
        "count": 5,
        "project_positions": [
            { "project_position_code": "PM", "project_position_name": "プロジェクトマネージャー" },
            { "project_position_code": "PL", "project_position_name": "プロジェクトリーダー" },
            { "project_position_code": "L", "project_position_name": "チームリーダー" },
            { "project_position_code": "M", "project_position_name": "メンバー" },

            { "project_position_code": "99", "project_position_name": "mock" },
        ]
    };

    // 仮想処理時間
    await new Promise(s => setTimeout(s, 2000));

    return res(ctx.json(projectPositions));
};
  
export default mockProjectPositions;
