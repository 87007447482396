import { useContext, useEffect, useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import { loadingContext } from '../App';
import FileUpload from './FileUpload';
import Api from '../api.json';
import { defaultPermissionAuthority, TPermissionAuthority } from '../ApiInterface/PermissionAuthority';
import { getPermissionAuthority, canReference, canPost } from '../Authority/PermissionAuthority';


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            '& > *': {
                margin: theme.spacing(1),
            },
        },
        gridItem: {
            marginBottom: theme.spacing(1),
        },
    }),
);

// default関数
const ImportObpm = () => {
    const classes = useStyles();

    // pathname
    const pathname = window.location.pathname;

    // 画面権限 State
    const [permissionAuthority, setPermissionAuthority] = useState<TPermissionAuthority>(defaultPermissionAuthority);
    const [permissionAuthorityMessage, setPermissionAuthorityMesssage] = useState('Loading...');

    const loading = useContext(loadingContext);

    useEffect(() => {
        // 画面権限取得
        loading.setIsLoading(true);
        getPermissionAuthority().then(r => {
            setPermissionAuthority(r);
            if (!canReference(r, pathname)) {
                setPermissionAuthorityMesssage("参照権限がありません。");
            }
        }).finally(() => {
            loading.setIsLoading(false);
        });

        // 初回のみ実行するため、第2引数の指定は無し。
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {canReference(permissionAuthority, pathname) ? (
                // 画面権限 参照可の場合
                <div className={classes.root}>
                    <Grid container alignItems="center">
                        <Grid item className={classes.gridItem}>
                            <Typography component="h2">OBPMインポート</Typography>
                        </Grid>
                        <Grid item xs />
                    </Grid>
                    <div>
                        <Grid container spacing={2}>
                            <FileUpload
                                title="アカウント一覧"
                                apiName={Api.apiName}
                                apiPath={Api.apis.obpmAccounts.path}
                                permissionAuthorityPost={canPost(permissionAuthority, Api.apis.obpmAccounts.path)}
                            />
                        </Grid>
                    </div>
                </div>                
            ) : (
                // 画面権限 参照不可の場合
                <div className={classes.root}>
                    <Typography>{permissionAuthorityMessage}</Typography>
                </div>
            )}
        </>
    );    
};

// defalut export
export default ImportObpm;
