import { useContext, useEffect, useState } from 'react';
import { API, Auth } from 'aws-amplify';
import { Link, useHistory } from 'react-router-dom';
import { Button, Grid, IconButton, MenuItem, TextField, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { DataGrid, RowsProp, CellParams } from '@material-ui/data-grid';
import { Search, ArrowForward, Add } from '@material-ui/icons';

import { amplifyAuth, loadingContext } from '../App';
import Api from '../api.json';
import { TProjects } from '../ApiInterface/Project';
import { TProjectField } from '../ApiInterface/ProjectField';
import { TBusinessType } from '../ApiInterface/BusinessType';
import { TContractType } from '../ApiInterface/ContractType';
import { defaultPermissionAuthority, TPermissionAuthority } from '../ApiInterface/PermissionAuthority';
import { getPermissionAuthority, canReference, canPost } from '../Authority/PermissionAuthority';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            '& > *': {
                margin: theme.spacing(1),
            },
        },
        gridItem: {
            marginBottom: theme.spacing(1),
        },
        select: {
            minWidth: 210,   // TextField.width:210
            width: 210
        },
        dataGrid: {
            marginTop: theme.spacing(2),
            height: 56 + 52 * 10 + 56,     // 632 = header:56 + row:52 * 10rows + footer:56?
            width: '100%'
        }
    }),
);

// 検索条件interface
export interface ISearchConditions {
    project_code: string;           // プロジェクトコード
    project_name: string;           // プロジェクト名
    project_field_code: string;     // プロジェクト分野コード
    business_type_code: string;     // 業種コード
    contract_type_code: string;     // 契約形態コード
    is_searched: boolean;           // 検索済
}

// default function
const SearchProject = () => {
    const classes = useStyles();

    // 検索条件hooks
    const [searchConditions, setSearchConditions] = useState<ISearchConditions>({
        project_code: "",           // プロジェクトコード
        project_name: "",           // プロジェクト名
        project_field_code: "",     // プロジェクト分野コード
        business_type_code: "",     // 業種コード
        contract_type_code: "",     // 契約形態コード
        is_searched: false,         // 検索済
    });
    
    const history = useHistory<ISearchConditions>();

    // pathname
    const pathname = window.location.pathname;

    // 画面権限 State
    const [permissionAuthority, setPermissionAuthority] = useState<TPermissionAuthority>(defaultPermissionAuthority);
    const [permissionAuthorityMessage, setPermissionAuthorityMesssage] = useState('Loading...');

    // プロジェクト分野リストState
    const [projectFields, setProjectFields] = useState<TProjectField[]>([]);

    // 業種リストState
    const [businessTypes, setBusinessTypes] = useState<TBusinessType[]>([]);

    // 契約形態リストState
    const [contractTypes, setContractTypes] = useState<TContractType[]>([]);

    // location.stateから検索条件を取得し検索
    useEffect(() => {
        if (history?.location.state !== undefined) {
            console.log(history.location.state);
            setSearchConditions(history.location.state);

            // 検索済の場合再検索
            if (history.location.state.is_searched) {
                search(history.location.state);
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        // リストリソース取得
        getResources();

        // 画面権限取得
        loading.setIsLoading(true);
        getPermissionAuthority().then(r => {
            setPermissionAuthority(r);
            if (!canReference(r, pathname)) {
                setPermissionAuthorityMesssage("参照権限がありません。");
            }
        }).finally(() => {
            loading.setIsLoading(false);
        });

        // 初回のみ実行するため、第2引数の指定は無し。
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // リストリソース取得非同期関数
    const getResources = async () => {
        await Auth.currentSession()
            .then(r => {
                return r.getIdToken().getJwtToken();
            })
            .then(async (jwtToken) => {
                const apiInit = {
                    headers: {
                        Authorization: jwtToken
                    }
                };

                // APIコール プロジェクト分野リスト取得
                API.get(Api.apiName, Api.apis.projectFields.path, apiInit)
                    .then(r => {
                        console.log("API Response", r);
                        // プロジェクト分野リストを取得しStateに設定
                        setProjectFields(r.project_fields);
                    })
                    .catch(e => {
                        console.log("API.get error:", e);
                        alert(e);
                    });

                // APIコール 業種リスト取得
                API.get(Api.apiName, Api.apis.businessTypes.path, apiInit)
                    .then(r => {
                        console.log("API Response", r);
                        // 業種リストを取得しStateに設定
                        setBusinessTypes(r.business_types);
                    })
                    .catch(e => {
                        console.log("API.get error:", e);
                        alert(e);
                    });

                // APIコール 契約形態リスト取得
                API.get(Api.apiName, Api.apis.contractTypes.path, apiInit)
                    .then(r => {
                        console.log("API Response", r);
                        // 契約形態リストを取得しStateに設定
                        setContractTypes(r.contract_types);
                    })
                    .catch(e => {
                        console.log("API.get error:", e);
                        alert(e);
                    });
            })
            .catch(e => {
                console.log("Auth.currentSession error", e);
                // {code: "NotAuthorizedException", name: "NotAuthorizedException", message: "Refresh Token has expired"}が返った場合は再認証。
                if (e.code === "NotAuthorizedException") {
                    alert("タイムアウトにより認証情報が無効となりました。トップページに遷移します。")
                    // 再認証
                    amplifyAuth();
                } else {
                    alert(e.message !== undefined ? e.message : e);
                }
            });
    };


    // 検索条件入力変更ハンドル
    const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, id?: string) => {
        const targetId = id == null ? event.target.id : id;
        setSearchConditions({ ...searchConditions, [targetId]: event.target.value });
    }

    const [rows, setRows] = useState<RowsProp>([]);

    const loading = useContext(loadingContext);

    // 検索処理定義
    const search = (searchConditions: ISearchConditions) => {
        loading.setIsLoading(true);
        setRows([]);
        // 検索条件.検索済
        setSearchConditions({ ...searchConditions, is_searched: true });
        // プロジェクト取得
        getProjects(searchConditions).then(() => loading.setIsLoading(false));
    };

    // プロジェクト取得
    const getProjects = async (searchConditions: ISearchConditions) => {        
        await Auth.currentSession()
            .then(r => {
                return r.getIdToken().getJwtToken();
            })
            .then(async (jwtToken) => {
                const apiInit = {
                    headers: {
                        Authorization: jwtToken
                    }
                };

                // 検索条件指定
                let params = {};
                // プロジェクトコード
                if (searchConditions.project_code.length !== 0) { params = { ...params, project_code: searchConditions.project_code }; }
                // プロジェクト名
                if (searchConditions.project_name.length !== 0) { params = { ...params, project_name: searchConditions.project_name }; }
                // プロジェクト分野コード
                if (searchConditions.project_field_code.length !== 0) { params = { ...params, project_field_code: searchConditions.project_field_code }; }
                // 業種コード
                if (searchConditions.business_type_code.length !== 0) { params = { ...params, business_type_code: searchConditions.business_type_code }; }
                // 契約形態コード
                if (searchConditions.contract_type_code.length !== 0) { params = { ...params, contract_type_code: searchConditions.contract_type_code }; }

                const getParams: string = Object.entries(params).length > 0 ? "?" + Object.entries(params).map((e) => `${e[0]}=${e[1]}`).join("&") : "";
                console.log(getParams);

                // APIコール
                await API.get(Api.apiName, Api.apis.projects.path + getParams, apiInit)
                    .then(r => {
                        console.log(r);
                        if (r !== null) {
                            setRows(getRowsProp(r));
                        }
                    })
                    .catch(e => {
                        console.log("API.get error:", e);
                        alert(e);
                    });
            })
            .catch(e => {
                console.log("Auth.currentSession error", e);
                // {code: "NotAuthorizedException", name: "NotAuthorizedException", message: "Refresh Token has expired"}が返った場合は再認証。
                if (e.code === "NotAuthorizedException") {
                    alert("タイムアウトにより認証情報が無効となりました。トップページに遷移します。")
                    // 再認証
                    amplifyAuth();
                } else {
                    alert(e.message !== undefined ? e.message : e);
                }
            });
    };

    // DataGrid行組立と取得
    const getRowsProp = (apiResponse: TProjects): RowsProp => {
        console.log(apiResponse);
        const rowsResult: RowsProp = apiResponse.projects.map(r => {
            return {
                id: r.project_code,                             // ID:プロジェクトコード
                project_code: r.project_code,                   // プロジェクトコード
                project_name: r.project_name,                   // プロジェクト名
                period: r.start_date + "～" + r.end_date,       // 期間:開始年月日～終了年月日
                project_field_name: r.project_field_name,       // プロジェクト分野名
                business_type_name: r.business_type_name,       // 業種名
                contract_type_name: r.contract_type_name        // 契約形態名
            };
        });

        return rowsResult;
    };

    return (
        <>
            {canReference(permissionAuthority, pathname) ? (
                // 画面権限 参照可の場合
                <div className={classes.root}>
                    <Grid container alignItems="center">
                        <Grid item>
                            <Typography component="h2">プロジェクトマスタ 検索</Typography>
                        </Grid>
                        <Grid item xs />
                    </Grid>
                    <div>
                        <Grid container spacing={2}>
                            <Grid container item spacing={1}>
                                <Grid item className={classes.gridItem}>
                                    <TextField id="project_code" label="プロジェクトコード" variant="outlined" size="small" autoFocus value={searchConditions.project_code} onChange={(event) => handleInputChange(event)} />
                                </Grid>
                                <Grid item className={classes.gridItem}>
                                    <TextField id="project_name" label="プロジェクト名" variant="outlined" size="small" value={searchConditions.project_name} onChange={(event) => handleInputChange(event)} />
                                </Grid>
                                <Grid item className={classes.gridItem}>
                                    <TextField id="project_field_code" label="プロジェクト分野" variant="outlined" size="small" select className={classes.select}
                                        value={searchConditions.project_field_code}
                                        onChange={(event) => handleInputChange(event, "project_field_code")}
                                    >
                                        <MenuItem value="">None</MenuItem>
                                        {projectFields.map((e) =>
                                            <MenuItem key={e.project_field_code} value={e.project_field_code}>{e.project_field_name}</MenuItem>
                                        )}
                                    </TextField>
                                </Grid>
                                <Grid item className={classes.gridItem}>
                                    <TextField id="business_type_code" label="業種" variant="outlined" size="small" select className={classes.select}
                                        value={searchConditions.business_type_code}
                                        onChange={(event) => handleInputChange(event, "business_type_code")}
                                    >
                                        <MenuItem value="">None</MenuItem>
                                        {businessTypes.map((e) =>
                                            <MenuItem key={e.business_type_code} value={e.business_type_code}>{e.business_type_name}</MenuItem>
                                        )}
                                    </TextField>
                                </Grid>
                                <Grid item className={classes.gridItem}>
                                    <TextField id="contract_type_code" label="契約形態" variant="outlined" size="small" select className={classes.select}
                                        value={searchConditions.contract_type_code}
                                        onChange={(event) => handleInputChange(event, "contract_type_code")}
                                    >
                                        <MenuItem value="">None</MenuItem>
                                        {contractTypes.map((e) =>
                                            <MenuItem key={e.contract_type_code} value={e.contract_type_code}>{e.contract_type_name}</MenuItem>
                                        )}
                                    </TextField>
                                </Grid>
                                <Grid item className={classes.gridItem}>
                                    <Button variant="contained" color="primary" startIcon={<Search />} onClick={() => search(searchConditions)}>検索</Button>
                                </Grid>
                                <Grid item xs />
                                <Grid item className={classes.gridItem}>
                                    {canPost(permissionAuthority, Api.apis.projects.path) && (
                                        // 画面権限 追加可の場合
                                        <Button variant="contained" color="primary" startIcon={<Add />}
                                            onClick={() => { history.push('/project/detail', searchConditions) }}
                                        >追加</Button>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                    <div>
                        <div className={classes.dataGrid}>
                            {/* rowsが更新されるとflex指定が無効化されるbugがあるっぽい https://www.gitmemory.com/issue/mui-org/material-ui-x/716/743188442 */}
                            <DataGrid autoPageSize pagination hideFooterSelectedRowCount rows={rows} columnBuffer={5} columns={[
                                { field: 'project_code', headerName: 'プロジェクトコード', width: 160 },
                                { field: 'project_name', headerName: 'プロジェクト名', width: 400 },
                                { field: 'period', headerName: '期間', width: 240 },
                                { field: 'project_field_name', headerName: 'プロジェクト分野名', width: 240 },
                                { field: 'business_type_name', headerName: '業種名', width: 200 },
                                { field: 'contract_type_name', headerName: '契約形態名', width: 160 },
                                {
                                    field: 'id', headerName: '詳細', width: 80,
                                    renderCell: (params: CellParams) => (
                                        <IconButton color="primary" component={Link} to={{ pathname: `/project/detail/${params.value}`, state: searchConditions }} size="small"><ArrowForward /></IconButton>
                                    )
                                }
                            ]} />
                        </div>
                    </div>
                </div>
            ) : (
                // 画面権限 参照不可の場合
                <div className={classes.root}>
                    {/* <Typography>参照権限がありません。</Typography> */}
                    <Typography>{permissionAuthorityMessage}</Typography>
                </div>
            )}
        </>
    );
}

// default export
export default SearchProject;
