import { useContext, useEffect, useState } from 'react';
import { API, Auth } from 'aws-amplify';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import { Button, Grid, IconButton, MenuItem, TextField, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { ArrowBack, Delete, Save } from '@material-ui/icons';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { format } from 'date-fns';

import { amplifyAuth, loadingContext } from '../App';
import { ISearchConditions } from './SearchProject';
import Api from '../api.json';
import { defaultProject, TProject } from '../ApiInterface/Project';
import { TProjectField } from '../ApiInterface/ProjectField';
import { TBusinessType } from '../ApiInterface/BusinessType';
import { TContractType } from '../ApiInterface/ContractType';
import { defaultPermissionAuthority, TPermissionAuthority } from '../ApiInterface/PermissionAuthority';
import { getPermissionAuthority, canReference, canPost, canPut, canDelete } from '../Authority/PermissionAuthority';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            '& > *': {
                margin: theme.spacing(1),
            },
        },
        gridRow: {
            // marginBottom: theme.spacing(1),
        },
        gridItem: {
            marginBottom: theme.spacing(1),
        },
        halfWidth: {
            width: 210 / 2 - 8
        },
        doubleWidth: {
            width: 210 * 2 + 8
        },
        QuadrupleWidth: {
            width: 210 * 4 + 8*3
        },
        select: {
            minWidth: 210,  // TextField.width:210
            width: 210
        },
        selectHalfWidth: {
            minWidth: 210 / 2 -4, 
            width: 210 / 2 - 4
        },
        selectDoubleWidth: {
            minWidth: 210 * 2 + 8, 
            width: 210 * 2 + 8
        },
        selectQuadrupleWidth: {
            minWidth: 210 * 4 + 8*3, 
            width: 210 * 4 + 8*3
        },
        paper: {
            marginBottom: theme.spacing(1),
            padding: theme.spacing(1),
            paddingTop: theme.spacing(2),
            width: "100%"
        }
    }),
);

// default function
const DetailProject = () => {
    const classes = useStyles();

    const history = useHistory();
    const location = useLocation<ISearchConditions>();
    console.log(location);

    // pathname
    const pathname = window.location.pathname;

    const [inputValues, setInputValues] = useState<TProject>(defaultProject);

    // Error State Type定義
    type TErrorState = {
        project_code: boolean;              // プロジェクトコード
        project_name: boolean;              // プロジェクト名
        start_date: boolean;                // 開始年月日
        end_date: boolean;                  // 終了年月日
        project_field_code: boolean;        // プロジェクト分野コード
        business_type_code: boolean;        // 業種コード
        project_content: boolean;           // 内容
        project_scale: boolean;             // 規模
        contract_type_code: boolean;        // 契約形態コード
    };
    // Error State
    const [errorState, setErrorState] = useState<TErrorState>({
        project_code: false,            // プロジェクトコード
        project_name: false,            // プロジェクト名
        start_date: false,              // 開始年月日
        end_date: false,                // 終了年月日
        project_field_code: false,      // プロジェクト分野コード
        business_type_code: false,      // 業種コード
        project_content: false,         // 内容
        project_scale: false,           // 規模
        contract_type_code: false,      // 契約形態コード
    });

    // 画面権限 State
    const [permissionAuthority, setPermissionAuthority] = useState<TPermissionAuthority>(defaultPermissionAuthority);
    const [permissionAuthorityMessage, setPermissionAuthorityMesssage] = useState('Loading...');
    
    // プロジェクト分野リストState
    const [projectFields, setProjectFields] = useState<TProjectField[]>([]);

    // 業種リストState
    const [businessTypes, setBusinessTypes] = useState<TBusinessType[]>([]);

    // 契約形態リストState
    const [contractTypes, setContractTypes] = useState<TContractType[]>([]);

    // 重複State
    const [isDuplicate, setIsDuplicate] = useState<boolean>(false);

    // リストリソース取得非同期関数
    const getResources = async () => {
        await Auth.currentSession()
            .then(r => {
                return r.getIdToken().getJwtToken();
            })
            .then(async (jwtToken) => {
                const apiInit = {
                    headers: {
                        Authorization: jwtToken
                    }
                };

                // APIコール プロジェクト分野リスト取得
                API.get(Api.apiName, Api.apis.projectFields.path, apiInit)
                    .then(r => {
                        console.log("API Response", r);
                        // プロジェクト分野リストを取得しStateに設定
                        setProjectFields(r.project_fields);
                    })
                    .catch(e => {
                        console.log("API.get error:", e);
                        alert(e);
                    });

                // APIコール 業種リスト取得
                API.get(Api.apiName, Api.apis.businessTypes.path, apiInit)
                    .then(r => {
                        console.log("API Response", r);
                        // 業種リストを取得しStateに設定
                        setBusinessTypes(r.business_types);
                    })
                    .catch(e => {
                        console.log("API.get error:", e);
                        alert(e);
                    });

                // APIコール 契約形態リスト取得
                API.get(Api.apiName, Api.apis.contractTypes.path, apiInit)
                    .then(r => {
                        console.log("API Response", r);
                        // 契約形態リストを取得しStateに設定
                        setContractTypes(r.contract_types);
                    })
                    .catch(e => {
                        console.log("API.get error:", e);
                        alert(e);
                    });

            })
            .catch(e => {
                console.log("Auth.currentSession error", e);
                // {code: "NotAuthorizedException", name: "NotAuthorizedException", message: "Refresh Token has expired"}が返った場合は再認証。
                if (e.code === "NotAuthorizedException") {
                    alert("タイムアウトにより認証情報が無効となりました。トップページに遷移します。")
                    // 再認証
                    amplifyAuth();
                } else {
                    alert(e.message !== undefined ? e.message : e);
                }
            });
    };

    // 入力値変更ハンドル
    const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, id?: string) => {
        const targetId = id == null ? event.target.id : id;
        setInputValues({ ...inputValues, [targetId]: event.target.value });
    }

    // 日付入直値変更ハンドル
    const handleDateChange = (date: Date | null, id: string) => {
        setInputValues({ ...inputValues, [id]: date });
    }

    // URLパラメータ
    const { id } = useParams<{id: string}>();
    console.log('id: ', id);

    const loading = useContext(loadingContext);

    useEffect(() => {

        // 画面権限取得
        getPermissionAuthority().then(r => {
            setPermissionAuthority(r);
            if (!canReference(r, pathname, id)) {
                setPermissionAuthorityMesssage("参照権限がありません。");
            }
        });

        // リストリソース取得
        loading.setIsLoading(true);
        getResources().then(() => {
            if (id !== undefined) {
                loading.setIsLoading(true);
                getProjects().then(() => loading.setIsLoading(false));
            }
            console.log('inputValues', inputValues);    
            loading.setIsLoading(false);
        });

        // 初回のみ実行するため、第2引数の指定は無し。
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // プロジェクト取得(id指定)
    const getProjects = async () => {
        await Auth.currentSession()
            .then(r => {
                return r.getIdToken().getJwtToken();
            })
            .then(async (jwtToken) => {
                const apiInit = {
                    headers: {
                        Authorization: jwtToken
                    }
                };

                // APIコール
                await API.get(Api.apiName, Api.apis.projects.path + "?project_code=" + id, apiInit)
                    .then(r => {
                        console.log(r);
                        if (r.projects[0].project_code !== undefined) {
                            setForm(r.projects[0]);
                        }
                    })
                    .catch(e => {
                        console.log("API.get error:", e);
                        alert(e);
                    });
            })
            .catch(e => {
                console.log("Auth.currentSession error", e);
                // {code: "NotAuthorizedException", name: "NotAuthorizedException", message: "Refresh Token has expired"}が返った場合は再認証。
                if (e.code === "NotAuthorizedException") {
                    alert("タイムアウトにより認証情報が無効となりました。トップページに遷移します。")
                    // 再認証
                    amplifyAuth();
                } else {
                    alert(e.message !== undefined ? e.message : e);
                }
            });
    };

    // 画面項目値設定
    const setForm = (apiResponse: TProject) => {
        console.log('apiResponse', apiResponse);
        setInputValues(apiResponse);
    };

    // 重複チェック
    useEffect(() => {
        if (!id) {
            if (inputValues.project_code.length === 5) {
                loading.setIsLoading(true);
                // 重複プロジェクト取得
                getDuplicateProjects().then(() => loading.setIsLoading(false));
            } else {
                setIsDuplicate(false);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputValues.project_code]);

    // 重複プロジェクト取得(プロジェクトコード)
    const getDuplicateProjects = async () => {
        await Auth.currentSession()
            .then(r => {
                return r.getIdToken().getJwtToken();
            })
            .then(async (jwtToken) => {
                const apiInit = {
                    headers: {
                        Authorization: jwtToken
                    }
                };

                // APIコール
                await API.get(Api.apiName, Api.apis.projects.path
                    + "?project_code=" + inputValues.project_code, apiInit)
                    .then(r => {
                        console.log(r);
                        if (r.projects.length > 0) {
                            setIsDuplicate(true);
                            alert("登録済みのプロジェクトです。");
                        } else{
                            setIsDuplicate(false);
                        }
                    })
                    .catch(e => {
                        console.log("API.get error:", e);
                        alert(e);
                    });
            })
            .catch(e => {
                console.log("Auth.currentSession error", e);
                // {code: "NotAuthorizedException", name: "NotAuthorizedException", message: "Refresh Token has expired"}が返った場合は再認証。
                if (e.code === "NotAuthorizedException") {
                    alert("タイムアウトにより認証情報が無効となりました。トップページに遷移します。")
                    // 再認証
                    amplifyAuth();
                } else {
                    alert(e.message !== undefined ? e.message : e);
                }
            });
    };

    // 登録処理
    const saveData = () => {
        if (id) {
            // 変更
            loading.setIsLoading(true);
            putProjects().then(() => {
                loading.setIsLoading(false);

                // 画面遷移
                history.push("/project",  location.state);
            });

        } else {
            // 追加
            loading.setIsLoading(true);
            postProjects().then(() => {
                loading.setIsLoading(false);

                // 画面遷移
                history.push("/project",  location.state);
            });
        }
    };

    // プロジェクト登録(追加)
    const postProjects = async () => {
        await Auth.currentSession()
            .then(r => {
                return r.getIdToken().getJwtToken();
            })
            .then(async (jwtToken) => {
                const apiInit = {
                    headers: {
                        Authorization: jwtToken
                    },
                    body: {
                        projects: [
                            {
                                project_code: inputValues.project_code,                 // プロジェクトコード
                                project_name: inputValues.project_name,                 // プロジェクト名
                                start_date: inputValues.start_date ? format(new Date(inputValues.start_date), "yyyy-MM-dd") : null,      // 開始年月日
                                end_date: inputValues.end_date ? format(new Date(inputValues.end_date), "yyyy-MM-dd") : null,            // 終了年月日
                                project_field_code: inputValues.project_field_code,     // プロジェクト分野コード
                                business_type_code: inputValues.business_type_code,     // 業種コード
                                project_content: inputValues.project_content,           // 内容
                                project_scale: parseFloat(inputValues.project_scale.toString()),        // 規模
                                contract_type_code: inputValues.contract_type_code      // 契約形態コード
                            }
                        ]
                    }
                };

                // APIコール
                await API.post(Api.apiName, Api.apis.projects.path, apiInit)
                    .then(r => {
                        console.log(r);
                    })
                    .catch(e => {
                        console.log("API.post error:", e);
                        alert(e);
                    });
            })
            .catch(e => {
                console.log("Auth.currentSession error", e);
                // {code: "NotAuthorizedException", name: "NotAuthorizedException", message: "Refresh Token has expired"}が返った場合は再認証。
                if (e.code === "NotAuthorizedException") {
                    alert("タイムアウトにより認証情報が無効となりました。トップページに遷移します。")
                    // 再認証
                    amplifyAuth();
                } else {
                    alert(e.message !== undefined ? e.message : e);
                }
            });
    };

    // プロジェクト登録(変更)
    const putProjects = async () => {
        await Auth.currentSession()
            .then(r => {
                return r.getIdToken().getJwtToken();
            })
            .then(async (jwtToken) => {
                const apiInit = {
                    headers: {
                        Authorization: jwtToken
                    },
                    body: {
                        project_name: inputValues.project_name,                 // プロジェクト名
                        start_date: inputValues.start_date ? format(new Date(inputValues.start_date), "yyyy-MM-dd") : null,      // 開始年月日
                        end_date: inputValues.end_date ? format(new Date(inputValues.end_date), "yyyy-MM-dd") : null,            // 終了年月日
                        project_field_code: inputValues.project_field_code,     // プロジェクト分野コード
                        business_type_code: inputValues.business_type_code,     // 業種コード
                        project_content: inputValues.project_content,           // 内容
                        project_scale: parseFloat(inputValues.project_scale.toString()),        // 規模
                        contract_type_code: inputValues.contract_type_code      // 契約形態コード
                    }
                };

                // APIコール
                await API.put(Api.apiName, Api.apis.projects.path + "/" + id, apiInit)
                    .then(r => {
                        console.log(r);
                    })
                    .catch(e => {
                        console.log("API.put error:", e);
                        alert(e);
                    });
            })
            .catch(e => {
                console.log("Auth.currentSession error", e);
                // {code: "NotAuthorizedException", name: "NotAuthorizedException", message: "Refresh Token has expired"}が返った場合は再認証。
                if (e.code === "NotAuthorizedException") {
                    alert("タイムアウトにより認証情報が無効となりました。トップページに遷移します。")
                    // 再認証
                    amplifyAuth();
                } else {
                    alert(e.message !== undefined ? e.message : e);
                }
            });
    };

    // 削除処理
    const deleteData = () => {
        loading.setIsLoading(true);
        deleteProjects().then(() => {
            loading.setIsLoading(false);

            // 画面遷移
            history.push("/project",  location.state);
        });

    };

    // プロジェクト削除
    const deleteProjects = async () => {
        await Auth.currentSession()
            .then(r => {
                return r.getIdToken().getJwtToken();
            })
            .then(async (jwtToken) => {
                const apiInit = {
                    headers: {
                        Authorization: jwtToken
                    }
                };

                // APIコール
                await API.del(Api.apiName, Api.apis.projects.path + "/" + id, apiInit)
                    .then(r => {
                        console.log(r);
                    })
                    .catch(e => {
                        console.log("API.del error:", e);
                        alert(e);
                    });
            })
            .catch(e => {
                console.log("Auth.currentSession error", e);
                // {code: "NotAuthorizedException", name: "NotAuthorizedException", message: "Refresh Token has expired"}が返った場合は再認証。
                if (e.code === "NotAuthorizedException") {
                    alert("タイムアウトにより認証情報が無効となりました。トップページに遷移します。")
                    // 再認証
                    amplifyAuth();
                } else {
                    alert(e.message !== undefined ? e.message : e);
                }
            });
    };

    return (
        <>
            {canReference(permissionAuthority, pathname, id) ? (
                // 画面権限 参照可の場合
                <div className={classes.root}>
                    <Grid container alignItems="center">
                        <Grid item>
                            <IconButton component={Link} to={{ pathname: "/project", state: location.state }} size="small"><ArrowBack /></IconButton>
                        </Grid>
                        <Grid item>
                            <Typography component="h2">プロジェクトマスタ 詳細</Typography>
                        </Grid>
                        <Grid item xs />
                    </Grid>
                    <div>
                        <Grid container spacing={2}>
                            <Grid container item spacing={1} className={classes.gridRow} >
                                <Grid item className={classes.gridItem}>
                                    {id === undefined ? (
                                        // プロジェクトコードがない場合(変更可)
                                        <TextField id="project_code" label="プロジェクトコード" variant="outlined" size="small" autoFocus
                                            value={inputValues.project_code}
                                            required
                                            error={errorState.project_code || isDuplicate}
                                            onChange={(event) => {
                                                handleInputChange(event);
                                                // 入力チェック
                                                if (event.target.value.length > 0) {
                                                    setErrorState({ ...errorState, [event.target.id]: false});
                                                } else {
                                                    setErrorState({ ...errorState, [event.target.id]: true});
                                                }
                                            }}
                                            // inputProps={{maxLength: 5}}
                                        />
                                    ) : (
                                        // プロジェクトコードがある場合(変更不可)
                                        <TextField id="project_code" label="プロジェクトコード" variant="outlined" size="small"
                                            value={inputValues.project_code}
                                            disabled
                                        />
                                    )}
                                </Grid>
                                <Grid item className={classes.gridItem}>
                                    <TextField id="project_name" label="プロジェクト名" variant="outlined" size="small" className={classes.doubleWidth}
                                        autoFocus={id !== undefined}
                                        value={inputValues.project_name}
                                        required
                                        error={errorState.project_name}
                                        onChange={(event) => {
                                            handleInputChange(event);
                                            // 入力チェック
                                            if (event.target.value.length > 0) {
                                                setErrorState({ ...errorState, [event.target.id]: false});
                                            } else {
                                                setErrorState({ ...errorState, [event.target.id]: true});
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid item className={classes.gridItem}>
                                    <KeyboardDatePicker id="start_date" label="開始日" autoOk disableToolbar
                                        value={inputValues.start_date}
                                        inputVariant="outlined" variant="inline" size="small" format="yyyy/MM/dd" className={classes.select}
                                        required
                                        error={errorState.start_date}
                                        onChange={(date) => {
                                            handleDateChange(date, "start_date");
                                            // 入力チェック
                                            if (date === null || isNaN(date.getTime())) {
                                                setErrorState({ ...errorState, start_date: true});
                                            } else {
                                                setErrorState({ ...errorState, start_date: false});
                                            }
                                            // 開始日終了日相関チェック
                                            if (date !== null && inputValues.end_date !== null) {
                                                if (date.getTime() > new Date(inputValues.end_date).getTime()) {
                                                    setErrorState({ ...errorState, start_date: true, end_date: true});
                                                } else {
                                                    setErrorState({ ...errorState, start_date: false, end_date: false});
                                                }
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid item className={classes.gridItem}>
                                    <KeyboardDatePicker id="end_date" label="終了日" autoOk disableToolbar
                                        value={inputValues.end_date}
                                        inputVariant="outlined" variant="inline" size="small" format="yyyy/MM/dd" className={classes.select}
                                        required
                                        error={errorState.end_date}
                                        onChange={(date) => {
                                            handleDateChange(date, "end_date");
                                            // 入力チェック
                                            if (date === null || isNaN(date.getTime())) {
                                                setErrorState({ ...errorState, end_date: true});
                                            } else {
                                                setErrorState({ ...errorState, end_date: false});
                                            }
                                            // 開始日終了日相関チェック
                                            if (date !== null && inputValues.start_date !== null) {
                                                if (new Date(inputValues.start_date).getTime() > date.getTime()) {
                                                    setErrorState({ ...errorState, start_date: true, end_date: true});
                                                } else {
                                                    setErrorState({ ...errorState, start_date: false, end_date: false});
                                                }
                                            }
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item spacing={1} className={classes.gridRow}>
                                <Grid item className={classes.gridItem}>
                                    <TextField id="project_field_code" label="プロジェクト分野" variant="outlined" size="small" select className={classes.selectDoubleWidth} 
                                        value={inputValues.project_field_code}
                                        required
                                        error={errorState.project_field_code}
                                        onChange={(event) => {
                                            handleInputChange(event, "project_field_code");
                                            // 入力チェック
                                            if (event.target.value === '') {
                                                setErrorState({ ...errorState, project_field_code: true});
                                            } else {
                                                setErrorState({ ...errorState, project_field_code: false});
                                            }
                                        }}
                                    >
                                        <MenuItem value="">None</MenuItem>
                                        {projectFields.map((e) =>
                                            <MenuItem key={e.project_field_code} value={e.project_field_code}>{e.project_field_name}</MenuItem>
                                        )}
                                    </TextField>
                                </Grid>
                                <Grid item className={classes.gridItem}>
                                    <TextField id="business_type_code" label="業種" variant="outlined" size="small" select className={classes.selectDoubleWidth} 
                                        value={inputValues.business_type_code}
                                        required
                                        error={errorState.business_type_code}
                                        onChange={(event) => {
                                            handleInputChange(event, "business_type_code");
                                            // 入力チェック
                                            if (event.target.value === '') {
                                                setErrorState({ ...errorState, business_type_code: true});
                                            } else {
                                                setErrorState({ ...errorState, business_type_code: false});
                                            }
                                        }}
                                    >
                                        <MenuItem value="">None</MenuItem>
                                        {businessTypes.map((e) =>
                                            <MenuItem key={e.business_type_code} value={e.business_type_code}>{e.business_type_name}</MenuItem>
                                        )}
                                    </TextField>
                                </Grid>
                            </Grid>
                            <Grid container item spacing={1} className={classes.gridRow}>
                                <Grid item className={classes.gridItem}>
                                    <TextField id="project_content" label="内容" variant="outlined" size="small" className={classes.QuadrupleWidth}
                                        value={inputValues.project_content}
                                        required
                                        error={errorState.project_content}
                                        onChange={(event) => {
                                            handleInputChange(event);
                                            // 入力チェック
                                            if (event.target.value.length > 0) {
                                                setErrorState({ ...errorState, [event.target.id]: false});
                                            } else {
                                                setErrorState({ ...errorState, [event.target.id]: true});
                                            }
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item spacing={1} className={classes.gridRow}>
                                <Grid item className={classes.gridItem}>
                                    <TextField id="project_scale" label="規模(人月)" variant="outlined" size="small"
                                        value={inputValues.project_scale}
                                        required
                                        error={errorState.project_scale}
                                        onChange={(event) => {
                                            handleInputChange(event);
                                            // 入力チェック
                                            if (parseInt(event.target.value) < 0        // 規模(人月)マイナス
                                                || event.target.value === ''            // 規模(人月)空
                                                || isNaN(Number(event.target.value))    // 規模(人月)数値以外
                                            ) {
                                                setErrorState({ ...errorState, project_scale: true});
                                            } else {
                                                setErrorState({ ...errorState, project_scale: false});
                                            }
                                        }}
                                        inputProps={{ style: {textAlign: 'right'} }}
                                    />
                                </Grid>
                                <Grid item className={classes.gridItem}>
                                    <TextField id="contract_type_code" label="契約形態" variant="outlined" size="small" select className={classes.select} 
                                        value={inputValues.contract_type_code}
                                        required
                                        error={errorState.contract_type_code}
                                        onChange={(event) => {
                                            handleInputChange(event, "contract_type_code");
                                            // 入力チェック
                                            if (event.target.value === '') {
                                                setErrorState({ ...errorState, contract_type_code: true});
                                            } else {
                                                setErrorState({ ...errorState, contract_type_code: false});
                                            }
                                        }}
                                    >
                                        <MenuItem value="">None</MenuItem>
                                        {contractTypes.map((e) =>
                                            <MenuItem key={e.contract_type_code} value={e.contract_type_code}>{e.contract_type_name}</MenuItem>
                                        )}
                                    </TextField>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Typography component="h2">最終更新</Typography>
                            </Grid>
                            <Grid container item spacing={1} className={classes.gridRow}>
                                <Grid item className={classes.gridItem}>
                                    <TextField id="updated_at" label="更新日時" variant="outlined" size="small" 
                                        value={inputValues.updated_at ? format(Date.parse(inputValues.updated_at), "Y/M/d H:m:s") : ""}
                                        disabled
                                    />
                                </Grid>
                                <Grid item className={classes.gridItem}>
                                    <TextField id="updated_user_name" label="更新者" variant="outlined" size="small"
                                        value={inputValues.updated_user_name}
                                        disabled
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item spacing={1} className={classes.gridRow}>
                                {((canPost(permissionAuthority, Api.apis.projects.path) && !id) || (canPut(permissionAuthority, Api.apis.projects.path) && id)) && (
                                    // 画面権限 追加可または更新可の場合
                                    <Grid item className={classes.gridItem}>
                                        <Button variant="contained" color="primary" startIcon={<Save />}
                                            onClick={() => saveData()}
                                            // 非活性条件
                                            disabled={
                                                inputValues.project_code.length === 0       // プロジェクトコード未入力
                                                || inputValues.project_name === ''          // プロジェクト名未入力
                                                || inputValues.start_date === null          // 開始日未入力
                                                || (inputValues.start_date && isNaN(new Date(inputValues.start_date).getTime()))    // 開始日不正
                                                || inputValues.end_date === null            // 終了日未入力
                                                || (inputValues.end_date && isNaN(new Date(inputValues.end_date).getTime()))        // 終了日不正
                                                || new Date(inputValues.start_date).getTime() > new Date(inputValues.end_date).getTime()        // 開始日終了日逆転
                                                || inputValues.project_field_code === ''    // プロジェクト分野未選択
                                                || inputValues.business_type_code === ''    // 業種未選択
                                                || inputValues.project_content === ''       // 内容未入力
                                                || inputValues.project_scale < 0            // 規模(人月)マイナス
                                                || inputValues.project_scale.toString() === ''      // 規模(人月)空
                                                || isNaN(inputValues.project_scale)         // 規模(人月)数値以外
                                                || inputValues.contract_type_code === ''    // 契約形態未選択
                                                || isDuplicate                              // 重複
                                            }
                                        >登録</Button>
                                    </Grid>
                                )}
                                <Grid item xs />
                                {canDelete(permissionAuthority, Api.apis.projects.path) && id && (
                                    // 画面権限 削除可の場合
                                    <Grid item className={classes.gridItem}>
                                        <Button variant="contained" color="secondary" startIcon={<Delete />}
                                            onClick={() => deleteData()}
                                            // 非活性条件
                                            disabled={
                                                id === undefined        // プロジェクトコード未設定
                                            }
                                        >削除</Button>
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                    </div>
                </div>
            ) : (
                // 画面権限 参照不可の場合
                <div className={classes.root}>
                    {/* <Typography>参照権限がありません。</Typography> */}
                    <Typography>{permissionAuthorityMessage}</Typography>
                </div>
            )}
                
        </>
    );
}

// default export
export default DetailProject;
