import { ResponseResolver, MockedRequest, restContext } from 'msw';

const mockMenus: ResponseResolver<MockedRequest, typeof restContext> = async (req, res, ctx) => {
    console.log(req);

    const menus = {
        "count": 14,
        "paths": [
            { "path": "/employee" },
            { "path": "/smilev/import" },
            { "path": "/employeesanalysis" },
            { "path": "/possessionqualification" },
            { "path": "/possessionskill" },
            { "path": "/skillqualificationanalysis" },
            { "path": "/project" },
            { "path": "/obpm/import" },
            { "path": "/gradeanalysis" },
            { "path": "/workhistory" },
            { "path": "/workhistorysheet" },
            { "path": "/mikiwame/import" },
            { "path": "/evaluation/import" },
            { "path": "/evaluationanalysis" },
        ]
    };

    // 仮想処理時間
    await new Promise(s => setTimeout(s, 2000));

    return res(ctx.json(menus));
};
  
export default mockMenus;
