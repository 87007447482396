import { useContext, useEffect, useState } from 'react';
import { API, Auth } from 'aws-amplify';
import { Link, useHistory } from 'react-router-dom';
import { Button, Grid, IconButton, MenuItem, TextField, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { DataGrid, RowsProp, CellParams } from '@material-ui/data-grid';
import { Search, ArrowForward } from '@material-ui/icons';

import { amplifyAuth, loadingContext } from '../App';
import Api from '../api.json';
import { TEmployees } from '../ApiInterface/employee';
import ResourcesEmployee from '../ApiInterface/ResoucesEmployeesStub.json';
import { defaultPermissionAuthority, TPermissionAuthority } from '../ApiInterface/PermissionAuthority';
import { getPermissionAuthority, canReference } from '../Authority/PermissionAuthority';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            '& > *': {
                margin: theme.spacing(1),
            },
        },
        gridItem: {
            marginBottom: theme.spacing(1),
        },
        select: {
            minWidth: 210,   // TextField.width:210
            width: 210
        },
        dataGrid: {
            marginTop: theme.spacing(2),
            height: 56 + 52 * 10 + 56,     // 632 = header:56 + row:52 * 10rows + footer:56?
            width: '100%'
        }
    }),
);

// 検索条件interface
export interface ISearchConditions {
    employee_id: string;                    // 社員コード
    employee_name: string;                  // 氏名
    gender_code: string;                    // 性別コード
    entry_year: string;                     // 入社年
    organization_code: string;              // 組織コード
    official_position_code: string;         // 職位コード
    recruitment_category_code: string;      // 採用区分コード
    enrollment_category_code: string;       // 在籍区分コード
}

// default function
export default function SearchEmployee() {
    const classes = useStyles();

    // 検索条件hooks
    const [searchConditions, setSearchConditions] = useState<ISearchConditions>({
        employee_id: "",                    // 社員コード
        employee_name: "",                  // 氏名
        gender_code: "",                    // 性別コード
        entry_year: "",                     // 入社年
        organization_code: "",              // 組織コード
        official_position_code: "",         // 職位コード
        recruitment_category_code: "",      // 採用区分コード
        enrollment_category_code: "",       // 在籍区分コード
    });

    const history = useHistory<ISearchConditions>();

    // pathname
    const pathname = window.location.pathname;

    // location.stateから検索条件を取得し検索
    useEffect(() => {
        if (history?.location.state !== undefined) {
            console.log(history.location.state);
            setSearchConditions(history.location.state);
            search(history.location.state);
        }

        // 初回のみ実行するため、第2引数の指定は無し。
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    // API Response 組織リスト Type定義
    type TOrganizations = {
        organization_code: string;      // 組織コード
        organization_name: string;      // 組織名
    };
    // 組織リストState
    const [organizations, setOrganizations] = useState<TOrganizations[]>([]);

    // API Response 職位リスト Type定義
    type TOfficialPositions = {
        official_position_code: string;     // 職位コード
        official_position_name: string;     // 職位名
    };
    // 職位リストState
    const [officialPositions, setOfficialPositions] = useState<TOfficialPositions[]>([]);

    // API Response 採用区分リスト Type定義
    type TRecruitmentCategories = {
        recruitment_category_code: string;      // 採用区分コード
        recruitment_category_name: string;      // 採用区分名
    };
    // 採用区分リストState
    const [recruitmentCategories, setRecruitmentCategories] = useState<TRecruitmentCategories[]>([]);

    // 画面権限 State
    const [permissionAuthority, setPermissionAuthority] = useState<TPermissionAuthority>(defaultPermissionAuthority);
    const [permissionAuthorityMessage, setPermissionAuthorityMesssage] = useState('Loading...');
    
    useEffect(() => {
        // リストリソース取得
        getResources();

        // 画面権限取得
        loading.setIsLoading(true);
        getPermissionAuthority().then(r => {
            setPermissionAuthority(r);
            if (!canReference(r, pathname)) {
                setPermissionAuthorityMesssage("参照権限がありません。");
            }
        }).finally(() => {
            loading.setIsLoading(false);
        });

        // 初回のみ実行するため、第2引数の指定は無し。
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // リストリソース取得非同期関数
    const getResources = async () => {
        await Auth.currentSession()
            .then(r => {
                return r.getIdToken().getJwtToken();
            })
            .then(async (jwtToken) => {
                const apiInit = {
                    headers: {
                        Authorization: jwtToken
                    }
                };

                // APIコール 組織リスト取得
                API.get(Api.apiName, Api.apis.employeeOrganizations.path, apiInit)
                    .then(r => {
                        console.log("API Response", r);
                        // 組織リストを取得しStateに設定
                        setOrganizations(r.organizations);
                    })
                    .catch(e => {
                        console.log("API.get error:", e);
                        alert(e);
                    });

                // APIコール 職位リスト取得
                API.get(Api.apiName, Api.apis.employeeOfficialPositions.path, apiInit)
                    .then(r => {
                        console.log("API Response", r);
                        // 職位リストを取得しStateに設定
                        setOfficialPositions(r.official_positions);
                    })
                    .catch(e => {
                        console.log("API.get error:", e);
                        alert(e);
                    });

                // APIコール 採用区分リスト取得
                API.get(Api.apiName, Api.apis.employeeRecruitmentCategories.path, apiInit)
                    .then(r => {
                        console.log("API Response", r);
                        // 採用区分リストを取得しStateに設定
                        setRecruitmentCategories(r.recruitment_categories);
                    })
                    .catch(e => {
                        console.log("API.get error:", e);
                        alert(e);
                    });
            })
            .catch(e => {
                console.log("Auth.currentSession error", e);
                // {code: "NotAuthorizedException", name: "NotAuthorizedException", message: "Refresh Token has expired"}が返った場合は再認証。
                if (e.code === "NotAuthorizedException") {
                    alert("タイムアウトにより認証情報が無効となりました。トップページに遷移します。")
                    // 再認証
                    amplifyAuth();
                }
            });
    };


    // 検索条件入力変更ハンドル
    const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, id?: string) => {
        const targetId = id == null ? event.target.id : id;
        setSearchConditions({ ...searchConditions, [targetId]: event.target.value });
    }

    const [rows, setRows] = useState<RowsProp>([]);

    const loading = useContext(loadingContext);

    // 検索処理定義
    const search = (searchConditions: ISearchConditions) => {
        // console.log("search() searchConditions", searchConditions);
        loading.setIsLoading(true);
        setRows([]);
        getEmployees(searchConditions).then(() => loading.setIsLoading(false));
    };

    // 人事基本情報取得
    const getEmployees = async (searchConditions: ISearchConditions) => {
        await Auth.currentSession()
            .then(r => {
                return r.getIdToken().getJwtToken();
            })
            .then(async (jwtToken) => {
                const apiInit = {
                    headers: {
                        Authorization: jwtToken
                    }
                };

                // 検索条件指定
                let params = {};
                // 社員コード
                if (searchConditions.employee_id.length !== 0) { params = { ...params, employee_id: searchConditions.employee_id }; }
                // 氏名
                if (searchConditions.employee_name.length !== 0) { params = { ...params, employee_name: searchConditions.employee_name }; }
                // 性別コード
                if (searchConditions.gender_code.length !== 0) { params = { ...params, gender_code: searchConditions.gender_code }; }
                // 入社年
                if (searchConditions.entry_year.length !== 0) { params = { ...params, entry_year: searchConditions.entry_year }; }
                // 組織コード
                if (searchConditions.organization_code.length !== 0) { params = { ...params, organization_code: searchConditions.organization_code }; }
                // 職位コード
                if (searchConditions.official_position_code.length !== 0) { params = { ...params, official_position_code: searchConditions.official_position_code }; }
                // 採用区分コード
                if (searchConditions.recruitment_category_code.length !== 0) { params = { ...params, recruitment_category_code: searchConditions.recruitment_category_code }; }
                // 在籍区分コード
                if (searchConditions.enrollment_category_code.length !== 0) { params = { ...params, enrollment_category_code: searchConditions.enrollment_category_code }; }

                const getParams: string = Object.entries(params).length > 0 ? "?" + Object.entries(params).map((e) => `${e[0]}=${e[1]}`).join("&") : "";
                console.log(getParams);

                // APIコール
                await API.get(Api.apiName, Api.apis.employees.path + getParams, apiInit)
                    .then(r => {
                        console.log(r);
                        if (r !== null) {
                            setRows(getRowsProp(r));
                        }
                    })
                    .catch(e => {
                        console.log("API.get error:", e);
                        alert(e);
                    });
            })
            .catch(e => {
                console.log("Auth.currentSession error", e);
                // alert(e.message !== undefined ? e.message : e);
                // {code: "NotAuthorizedException", name: "NotAuthorizedException", message: "Refresh Token has expired"}が返った場合は再認証。
                if (e.code === "NotAuthorizedException") {
                    alert("タイムアウトにより認証情報が無効となりました。トップページに遷移します。")
                    // 再認証
                    amplifyAuth();
                }
            });
    };

    // DataGrid行組立と取得
    const getRowsProp = (apiResponse: TEmployees): RowsProp => {
        console.log(apiResponse);
        const rowsResult: RowsProp = apiResponse.employees.map(r => {
            return {
                id: r.employee_id,                                          // ID:従業員ID
                employee_id: r.employee_id,                                 // 社員コード:従業員ID
                employee_name: r.employee_name,                             // 氏名:従業員名
                gender_name: r.profile.gender_name,                         // 性別:性別名称
                entry_date: r.entry_date,                                   // 入社年月日
                organization_name: r.organization_name,                     // 組織:組織名称
                official_position_name: r.official_position_name,           // 職位:職位名称
                recruitment_category_name: r.recruitment_category_name,     // 採用区分:採用区分名称
                enrollment_category_name: r.enrollment_category_name,       // 在籍区分:在籍区分名称
            };
        });

        return rowsResult;
    };

    return (
        <>
            {canReference(permissionAuthority, pathname) ? (
                // 画面権限 参照可の場合
                <div className={classes.root}>
                    <Grid container alignItems="center">
                        <Grid item>
                            <Typography component="h2">人事基本情報 検索</Typography>
                        </Grid>
                        <Grid item xs />
                    </Grid>
                    <div>
                        <Grid container spacing={2}>
                            <Grid container item spacing={1}>
                                <Grid item className={classes.gridItem}>
                                    <TextField id="employee_id" label="社員コード" variant="outlined" size="small" autoFocus value={searchConditions.employee_id} onChange={(event) => handleInputChange(event)} />
                                </Grid>
                                <Grid item className={classes.gridItem}>
                                    <TextField id="employee_name" label="氏名" variant="outlined" size="small" value={searchConditions.employee_name} onChange={(event) => handleInputChange(event)} />
                                </Grid>
                                <Grid item className={classes.gridItem}>
                                    <TextField id="gender_code" select label="性別" variant="outlined" size="small" className={classes.select} value={searchConditions.gender_code} onChange={(event) => handleInputChange(event, "gender_code")}>
                                        <MenuItem value="">None</MenuItem>
                                        {ResourcesEmployee.genders.map((genders) =>
                                            <MenuItem key={genders.gender_code} value={genders.gender_code}>{genders.gender_name}</MenuItem>
                                        )}
                                    </TextField>
                                </Grid>
                                <Grid item className={classes.gridItem}>
                                    <TextField id="entry_year" label="入社年" variant="outlined" size="small" value={searchConditions.entry_year} onChange={(event) => handleInputChange(event)} />
                                </Grid>
                                <Grid item className={classes.gridItem}>
                                    <TextField id="organization_code" label="組織" variant="outlined" size="small" select className={classes.select} value={searchConditions.organization_code} onChange={(event) => handleInputChange(event, "organization_code")}>
                                        <MenuItem value="">None</MenuItem>
                                        {organizations.map((e) =>
                                            <MenuItem key={e.organization_code} value={e.organization_code}>{e.organization_name}</MenuItem>
                                        )}
                                    </TextField>
                                </Grid>
                                <Grid item className={classes.gridItem}>
                                    <TextField id="official_position_code" label="職位" variant="outlined" size="small" select className={classes.select} value={searchConditions.official_position_code} onChange={(event) => handleInputChange(event, "official_position_code")}>
                                        <MenuItem value="">None</MenuItem>
                                        {officialPositions.map((e) =>
                                            <MenuItem key={e.official_position_code} value={e.official_position_code}>{e.official_position_name}</MenuItem>
                                        )}
                                    </TextField>
                                </Grid>
                                <Grid item className={classes.gridItem}>
                                    <TextField id="recruitment_category_code" label="採用区分" variant="outlined" size="small" select className={classes.select} value={searchConditions.recruitment_category_code} onChange={(event) => handleInputChange(event, "recruitment_category_code")}>
                                        <MenuItem value="">None</MenuItem>
                                        {recruitmentCategories.map((e) =>
                                            <MenuItem key={e.recruitment_category_code} value={e.recruitment_category_code}>{e.recruitment_category_name}</MenuItem>
                                        )}
                                    </TextField>
                                </Grid>
                                <Grid item className={classes.gridItem}>
                                    <TextField id="enrollment_category_code" label="在籍区分" variant="outlined" size="small" select className={classes.select} value={searchConditions.enrollment_category_code} onChange={(event) => handleInputChange(event, "enrollment_category_code")}>
                                        <MenuItem value="">None</MenuItem>
                                        {ResourcesEmployee.enrollment_categories.map((e) =>
                                            <MenuItem key={e.enrollment_category_code} value={e.enrollment_category_code}>{e.enrollment_category_name}</MenuItem>
                                        )}
                                    </TextField>
                                </Grid>
                                <Grid item className={classes.gridItem}>
                                    <Button variant="contained" color="primary" startIcon={<Search />} onClick={() => search(searchConditions)}>検索</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                    <div>
                        <div className={classes.dataGrid}>
                            {/* rowsが更新されるとflex指定が無効化されるbugがあるっぽい https://www.gitmemory.com/issue/mui-org/material-ui-x/716/743188442 */}
                            <DataGrid autoPageSize pagination hideFooterSelectedRowCount rows={rows} columnBuffer={9} columns={[
                                { field: 'employee_id', headerName: '社員コード', width: 120 },
                                { field: 'employee_name', headerName: '氏名', width: 160 },
                                { field: 'gender_name', headerName: '性別', width: 80 },
                                { field: 'entry_date', headerName: '入社年月日', width: 120 },
                                { field: 'organization_name', headerName: '組織', width: 160},
                                { field: 'official_position_name', headerName: '職位', width: 160},
                                { field: 'recruitment_category_name', headerName: '採用区分', width: 160 },
                                { field: 'enrollment_category_name', headerName: '在籍区分', width: 120 },
                                {
                                    field: 'id', headerName: '詳細', width: 80,
                                    renderCell: (params: CellParams) => (
                                        <IconButton color="primary" component={Link} to={{ pathname: `/employee/detail/${params.value}`, state: searchConditions }} size="small"><ArrowForward /></IconButton>
                                    )
                                }
                            ]} />
                        </div>
                    </div>
                </div>
            ) : (
                // 画面権限 参照不可の場合
                <div className={classes.root}>
                    <Typography>{permissionAuthorityMessage}</Typography>
                </div>
            )}
        </>
    );
}
