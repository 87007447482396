import { ResponseResolver, MockedRequest, restContext } from 'msw';

const mockProjectFields: ResponseResolver<MockedRequest, typeof restContext> = async (req, res, ctx) => {
    console.log(req);

    const projectFields = {
        "count": 10,
        "project_fields": [
            { "project_field_code": "pf01", "project_field_name": "システム新規開発" },
            { "project_field_code": "pf02", "project_field_name": "システム保守開発" },
            { "project_field_code": "pf03", "project_field_name": "パッケージ適用・導入" },
            { "project_field_code": "pf04", "project_field_name": "セールス/カスタマーサクセス" },
            { "project_field_code": "pf05", "project_field_name": "ヘルプデスク/サポートデスク" },
            { "project_field_code": "pf06", "project_field_name": "顧客業務支援" },
            { "project_field_code": "pf07", "project_field_name": "プロジェクト管理・管理支援" },
            { "project_field_code": "pf08", "project_field_name": "システム企画/要求分析" },
            { "project_field_code": "pf99", "project_field_name": "その他" },

            { "project_field_code": "pf999", "project_field_name": "mock" }
        ]
    };

    // 仮想処理時間
    await new Promise(s => setTimeout(s, 2500));

    return res(ctx.json(projectFields));
};
  
export default mockProjectFields;
