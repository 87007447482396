import { useContext, useEffect, useState } from 'react';
import { API, Auth } from 'aws-amplify';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import { Button, Grid, IconButton, MenuItem, TextField, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { ArrowBack, Delete, Save } from '@material-ui/icons';
import { format } from 'date-fns';

import { amplifyAuth, loadingContext } from '../App';
import { ISearchConditions } from './SearchPossessionSkill';
import Api from '../api.json';
import { defaultPossessionSkill, TPossessionSkill } from '../ApiInterface/PossessionSkill';
import { TSkillField } from '../ApiInterface/SkillField';
import { TSkill } from '../ApiInterface/Skill';
import { TSkillLevel } from '../ApiInterface/SkillLevel';
import { TPossessionQualification } from '../ApiInterface/PossessionQualification';
import { defaultPermissionAuthority, TPermissionAuthority } from '../ApiInterface/PermissionAuthority';
import { getPermissionAuthority, canReference, canPost, canPut, canDelete } from '../Authority/PermissionAuthority';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            '& > *': {
                margin: theme.spacing(1),
            },
        },
        gridRow: {
            // marginBottom: theme.spacing(1),
        },
        gridItem: {
            marginBottom: theme.spacing(1),
        },
        halfWidth: {
            width: 210 / 2 - 8
        },
        doubleWidth: {
            width: 210 * 2 + 8
        },
        select: {
            minWidth: 210,  // TextField.width:210
            width: 210
        },
        selectHalfWidth: {
            minWidth: 210 / 2 -4, 
            width: 210 / 2 - 4
        },
        selectDoubleWidth: {
            minWidth: 210 * 2 + 8, 
            width: 210 * 2 + 8
        },
        selectQuadrupleWidth: {
            minWidth: 210 * 4 + 8*3, 
            width: 210 * 4 + 8*3
        },
        paper: {
            marginBottom: theme.spacing(1),
            padding: theme.spacing(1),
            paddingTop: theme.spacing(2),
            width: "100%"
        }
    }),
);

// default function
const DetailPossessionSkill = () => {
    const classes = useStyles();

    const history = useHistory();
    const location = useLocation<ISearchConditions>();
    console.log(location);

    // pathname
    const pathname = window.location.pathname;

    const [inputValues, setInputValues] = useState<TPossessionSkill>(defaultPossessionSkill);

    // Error State Type定義
    type TErrorState = {
        employee_id: boolean;           // 社員コード
        skill_field_code: boolean;      // 技能分野
        skill_code: boolean;            // 技能
        skill_level: boolean;           // 技能レベル
        experience_months: boolean;     // 経験月数
    };
    // Error State
    const [errorState, setErrorState] = useState<TErrorState>({
        employee_id: false,
        skill_field_code:false,
        skill_code:false,
        skill_level:false,
        experience_months:false
    });

    // 画面権限 State
    const [permissionAuthority, setPermissionAuthority] = useState<TPermissionAuthority>(defaultPermissionAuthority);
    const [permissionAuthorityMessage, setPermissionAuthorityMesssage] = useState('Loading...');
    
    // 技能分野リストState
    const [skillFields, setSkillFields] = useState<TSkillField[]>([]);

    // 技能リストState
    const [skills, setSkills] = useState<TSkill[]>([]);
    const [filteredSkills, setFilteredSkills] = useState<TSkill[]>([]);

    // 技能レベルState
    const [skillLevels, setSkillLevels] = useState<TSkillLevel[]>([]);
    const [filteredSkillLevels, setFilteredSkillLevels] = useState<TSkillLevel[]>([]);

    // 保有資格State
    const [possessionQualifications, setPossessionQualifications] = useState<TPossessionQualification[]>([]);
    const [filteredPossessionQualifications, setFilteredPossessionQualifications] = useState<TPossessionQualification[]>([]);

    // 重複State
    const [isDuplicate, setIsDuplicate] = useState<boolean>(false);

    // useEffect(() => {
        // // リストリソース取得
        // getResources();

        // // 画面権限取得
        // getPermissionAuthority().then(r => {
        //     setPermissionAuthority(r);
        //     if (!canReference(r, pathname, id)) {
        //         setPermissionAuthorityMesssage("参照権限がありません。");
        //     }
        // });

        // 初回のみ実行するため、第2引数の指定は無し。
        // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [])

    // リストリソース取得非同期関数
    const getResources = async () => {
        await Auth.currentSession()
            .then(r => {
                return r.getIdToken().getJwtToken();
            })
            .then(async (jwtToken) => {
                const apiInit = {
                    headers: {
                        Authorization: jwtToken
                    }
                };

                // APIコール 技能分野リスト取得
                await API.get(Api.apiName, Api.apis.skillFields.path, apiInit)
                    .then(r => {
                        console.log("API Response", r);
                        // 技能分野リストを取得しStateに設定
                        setSkillFields(r.skill_fields);
                    })
                    .catch(e => {
                        console.log("API.get error:", e);
                        alert(e);
                    });

                // APIコール 技能リスト取得
                await API.get(Api.apiName, Api.apis.skills.path, apiInit)
                    .then(r => {
                        console.log("API Response", r);
                        // 技能リストを取得しStateに設定
                        setSkills(r.skills);
                    })
                    .catch(e => {
                        console.log("API.get error:", e);
                        alert(e);
                    });

                // APIコール 技能レベルリスト取得
                await API.get(Api.apiName, Api.apis.skillLevels.path, apiInit)
                    .then(r => {
                        console.log("API Response", r);
                        // 技能レベルリストを取得しStateに設定
                        setSkillLevels(r.skill_levels);
                    })
                    .catch(e => {
                        console.log("API.get error:", e);
                        alert(e);
                    });

                // APIコール 保有資格リスト取得
                await API.get(Api.apiName, Api.apis.possessionQualifications.path, apiInit)
                    .then(r => {
                        console.log("API Response", r);
                        // 保有資格リストを取得しStateに設定
                        setPossessionQualifications(r.possession_qualifications);
                    })
                    .catch(e => {
                        console.log("API.get error:", e);
                        alert(e);
                    });

            })
            .catch(e => {
                console.log("Auth.currentSession error", e);
                // {code: "NotAuthorizedException", name: "NotAuthorizedException", message: "Refresh Token has expired"}が返った場合は再認証。
                if (e.code === "NotAuthorizedException") {
                    alert("タイムアウトにより認証情報が無効となりました。トップページに遷移します。")
                    // 再認証
                    amplifyAuth();
                } else {
                    alert(e.message !== undefined ? e.message : e);
                }
            });
    };

    // 入力値変更ハンドル
    const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, id?: string) => {
        const targetId = id == null ? event.target.id : id;
        setInputValues({ ...inputValues, [targetId]: event.target.value });
    }

    // 技能分野変更ハンドル
    const handleChangeSkillField = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, id: string) => {
        // 技能分野変更、技能クリア
        setInputValues({ ...inputValues, [id]: event.target.value, skill_code: "", skill_level: "" });
    };

    // 技能変更ハンドル
    const handleChangeSkill = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, id: string) => {
        // 技能変更、技能レベルクリア
        setInputValues({ ...inputValues, [id]: event.target.value, skill_level: "" });
    };

    // 技能分野変更時Effect
    useEffect(() => {
        // 技能リストフィルタ
        setFilteredSkills(skills.filter(skill => { return skill.skill_field_code === inputValues.skill_field_code }));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputValues.skill_field_code]);

    // 技能変更時Effect
    useEffect(() => {
        // 現在の技能を取得(技能コードが一致するもの)
        const currentSkills = skills.filter(v => { return v.skill_code === inputValues.skill_code });
        // 技能レベルフィルタ
        setFilteredSkillLevels(skillLevels.filter(v => { return v.skill_level_definition_code === currentSkills[0]?.skill_level_definition_code }));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputValues.skill_code]);

    // URLパラメータ
    const { id } = useParams<{id: string}>();
    console.log('id: ', id);

    const loading = useContext(loadingContext);

    useEffect(() => {

        // 画面権限取得
        getPermissionAuthority().then(r => {
            setPermissionAuthority(r);
            if (!canReference(r, pathname, id)) {
                setPermissionAuthorityMesssage("参照権限がありません。");
            }
        });

        // リストリソース取得
        loading.setIsLoading(true);
        getResources().then(r => {
            if (id !== undefined) {
                loading.setIsLoading(true);
                getPossessionSkills().then(() => loading.setIsLoading(false));
            }
            console.log('inputValues', inputValues);    
            loading.setIsLoading(false);
        });

        // 初回のみ実行するため、第2引数の指定は無し。
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // 保有技能取得(id指定)
    const getPossessionSkills = async () => {
        await Auth.currentSession()
            .then(r => {
                return r.getIdToken().getJwtToken();
            })
            .then(async (jwtToken) => {
                const apiInit = {
                    headers: {
                        Authorization: jwtToken
                    }
                };

                // APIコール
                await API.get(Api.apiName, Api.apis.possessionSkills.path + "?possession_skill_id=" + id, apiInit)
                    .then(r => {
                        console.log(r);
                        if (r.possession_skills[0].possession_skill_id !== undefined) {
                            setForm(r.possession_skills[0]);
                        }
                    })
                    .catch(e => {
                        console.log("API.get error:", e);
                        alert(e);
                    });
            })
            .catch(e => {
                console.log("Auth.currentSession error", e);
                // {code: "NotAuthorizedException", name: "NotAuthorizedException", message: "Refresh Token has expired"}が返った場合は再認証。
                if (e.code === "NotAuthorizedException") {
                    alert("タイムアウトにより認証情報が無効となりました。トップページに遷移します。")
                    // 再認証
                    amplifyAuth();
                } else {
                    alert(e.message !== undefined ? e.message : e);
                }
            });
    };

    // 画面項目値設定
    const setForm = (apiResponse: TPossessionSkill) => {
        console.log('apiResponse', apiResponse);
        setInputValues(apiResponse);
    };

    // 重複チェック
    useEffect(() => {
        if (!id) {
            if (inputValues.employee_id.length === 6 && inputValues.skill_field_code !== '' && inputValues.skill_code !== '') {
                loading.setIsLoading(true);
                // 重複保有技能取得
                getDuplicatePossessionSkills().then(() => loading.setIsLoading(false));
            } else {
                setIsDuplicate(false);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputValues.employee_id, inputValues.skill_field_code, inputValues.skill_code]);

    // 重複保有技能取得(社員コード, 技能分野, 技能指定)
    const getDuplicatePossessionSkills = async () => {
        await Auth.currentSession()
            .then(r => {
                return r.getIdToken().getJwtToken();
            })
            .then(async (jwtToken) => {
                const apiInit = {
                    headers: {
                        Authorization: jwtToken
                    }
                };

                // APIコール
                await API.get(Api.apiName, Api.apis.possessionSkills.path
                    + "?employee_id=" + inputValues.employee_id
                    + "&skill_field_code=" + inputValues.skill_field_code
                    + "&skill_code=" + inputValues.skill_code, apiInit)
                    .then(r => {
                        console.log(r);
                        if (r.possession_skills.length > 0) {
                            setIsDuplicate(true);
                            alert("登録済みの保有技能です。");
                        } else{
                            setIsDuplicate(false);
                        }
                    })
                    .catch(e => {
                        console.log("API.get error:", e);
                        alert(e);
                    });
            })
            .catch(e => {
                console.log("Auth.currentSession error", e);
                // {code: "NotAuthorizedException", name: "NotAuthorizedException", message: "Refresh Token has expired"}が返った場合は再認証。
                if (e.code === "NotAuthorizedException") {
                    alert("タイムアウトにより認証情報が無効となりました。トップページに遷移します。")
                    // 再認証
                    amplifyAuth();
                } else {
                    alert(e.message !== undefined ? e.message : e);
                }
            });
    };

    // 登録処理
    const saveData = () => {
        if (id) {
            // 変更
            loading.setIsLoading(true);
            putPossessionSkills().then(() => {
                loading.setIsLoading(false);

                // 画面遷移
                history.push("/possessionskill",  location.state);
            });

        } else {
            // 追加
            loading.setIsLoading(true);
            postPossessionSkills().then(() => {
                loading.setIsLoading(false);

                // 画面遷移
                history.push("/possessionskill",  location.state);
            });
        }
    };

    // 保有技能登録(追加)
    const postPossessionSkills = async () => {
        await Auth.currentSession()
            .then(r => {
                return r.getIdToken().getJwtToken();
            })
            .then(async (jwtToken) => {
                const apiInit = {
                    headers: {
                        Authorization: jwtToken
                    },
                    body: {
                        possession_skills: [
                            {
                                employee_id: inputValues.employee_id,                                       // 社員コード
                                skill_code: inputValues.skill_code,                                         // 技能コード
                                skill_level: inputValues.skill_level,                                       // 技能レベル
                                // experience_months: inputValues.experience_months,                           // 経験月数
                                experience_months: parseInt(inputValues.experience_months.toString()),      // 経験月数
                                possession_qualification_id: inputValues.possession_qualification_id === "" ? null : inputValues.possession_qualification_id,       // 保有資格ID
                            }
                        ]
                    }
                };

                // APIコール
                await API.post(Api.apiName, Api.apis.possessionSkills.path, apiInit)
                    .then(r => {
                        console.log(r);
                    })
                    .catch(e => {
                        console.log("API.post error:", e);
                        alert(e);
                    });
            })
            .catch(e => {
                console.log("Auth.currentSession error", e);
                // {code: "NotAuthorizedException", name: "NotAuthorizedException", message: "Refresh Token has expired"}が返った場合は再認証。
                if (e.code === "NotAuthorizedException") {
                    alert("タイムアウトにより認証情報が無効となりました。トップページに遷移します。")
                    // 再認証
                    amplifyAuth();
                } else {
                    alert(e.message !== undefined ? e.message : e);
                }
            });
    };

    // 保有技能登録(変更)
    const putPossessionSkills = async () => {
        await Auth.currentSession()
            .then(r => {
                return r.getIdToken().getJwtToken();
            })
            .then(async (jwtToken) => {
                const apiInit = {
                    headers: {
                        Authorization: jwtToken
                    },
                    body: {
                        skill_level: inputValues.skill_level,                                       // 技能レベル
                        experience_months: inputValues.experience_months,                           // 経験月数
                        possession_qualification_id: inputValues.possession_qualification_id === "" ? null : inputValues.possession_qualification_id,       // 保有資格ID
                    }
                };

                // APIコール
                await API.put(Api.apiName, Api.apis.possessionSkills.path + "/" + id, apiInit)
                    .then(r => {
                        console.log(r);
                    })
                    .catch(e => {
                        console.log("API.put error:", e);
                        alert(e);
                    });
            })
            .catch(e => {
                console.log("Auth.currentSession error", e);
                // {code: "NotAuthorizedException", name: "NotAuthorizedException", message: "Refresh Token has expired"}が返った場合は再認証。
                if (e.code === "NotAuthorizedException") {
                    alert("タイムアウトにより認証情報が無効となりました。トップページに遷移します。")
                    // 再認証
                    amplifyAuth();
                } else {
                    alert(e.message !== undefined ? e.message : e);
                }
            });
    };

    // 削除処理
    const deleteData = () => {
        loading.setIsLoading(true);
        deletePossessionSkills().then(() => {
            loading.setIsLoading(false);

            // 画面遷移
            history.push("/possessionskill",  location.state);
        });

    };

    // 保有技能削除
    const deletePossessionSkills = async () => {
        await Auth.currentSession()
            .then(r => {
                return r.getIdToken().getJwtToken();
            })
            .then(async (jwtToken) => {
                const apiInit = {
                    headers: {
                        Authorization: jwtToken
                    }
                };

                // APIコール
                await API.del(Api.apiName, Api.apis.possessionSkills.path + "/" + id, apiInit)
                    .then(r => {
                        console.log(r);
                    })
                    .catch(e => {
                        console.log("API.del error:", e);
                        alert(e);
                    });
            })
            .catch(e => {
                console.log("Auth.currentSession error", e);
                // {code: "NotAuthorizedException", name: "NotAuthorizedException", message: "Refresh Token has expired"}が返った場合は再認証。
                if (e.code === "NotAuthorizedException") {
                    alert("タイムアウトにより認証情報が無効となりました。トップページに遷移します。")
                    // 再認証
                    amplifyAuth();
                } else {
                    alert(e.message !== undefined ? e.message : e);
                }
            });
    };

    // 社員コード変更時
    useEffect(() => {
        if (!id) {
            if (inputValues.employee_id.length === 6) {
                loading.setIsLoading(true);
                getEmployees(inputValues.employee_id).then(() => loading.setIsLoading(false));
            } else {
                setInputValues({...inputValues, employee_name: '', possession_qualification_id: ''});
            }
        }

        // 保有資格リストフィルタ
        setFilteredPossessionQualifications(possessionQualifications.filter(v => { return v.employee_id === inputValues.employee_id }));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputValues.employee_id])

    // 人事基本情報取得(氏名)
    const getEmployees = async (employee_id: string) => {
        await Auth.currentSession()
            .then(r => {
                return r.getIdToken().getJwtToken();
            })
            .then(async (jwtToken) => {
                const apiInit = {
                    headers: {
                        Authorization: jwtToken
                    }
                };

                // APIコール
                await API.get(Api.apiName, Api.apis.employees.path + "?employee_id=" + employee_id, apiInit)
                    .then(r => {
                        console.log(r);
                        if (r.employees[0]?.employee_name !== undefined) {
                            // setInputValues({...inputValues, employee_name: r.employees[0].employee_name})
                            setInputValues(prevValues => ({...prevValues, employee_name: r.employees[0].employee_name}))
                        }
                    })
                    .catch(e => {
                        console.log("API.get error:", e);
                        alert(e);
                    });
            })
            .catch(e => {
                console.log("Auth.currentSession error", e);
                // {code: "NotAuthorizedException", name: "NotAuthorizedException", message: "Refresh Token has expired"}が返った場合は再認証。
                if (e.code === "NotAuthorizedException") {
                    alert("タイムアウトにより認証情報が無効となりました。トップページに遷移します。")
                    // 再認証
                    amplifyAuth();
                } else {
                    alert(e.message !== undefined ? e.message : e);
                }
            });
    };

    return (
        <>
            {canReference(permissionAuthority, pathname, id) ? (
                // 画面権限 参照可の場合
                <div className={classes.root}>
                    <Grid container alignItems="center">
                        <Grid item>
                            <IconButton component={Link} to={{ pathname: "/possessionskill", state: location.state }} size="small"><ArrowBack /></IconButton>
                        </Grid>
                        <Grid item>
                            <Typography component="h2">保有技能 詳細</Typography>
                        </Grid>
                        <Grid item xs />
                    </Grid>
                    <div>
                        <Grid container spacing={2}>
                            <Grid container item spacing={1} className={classes.gridRow} >
                                <Grid item className={classes.gridItem}>
                                    {id === undefined ? (
                                        // 保有技能IDがない場合(変更可)
                                        <TextField id="employee_id" label="社員コード" variant="outlined" size="small" autoFocus
                                            value={inputValues.employee_id}
                                            required
                                            error={errorState.employee_id || isDuplicate}
                                            onChange={(event) => {
                                                handleInputChange(event);
                                                // 入力チェック
                                                if (event.target.value.length === 6) {
                                                    setErrorState({ ...errorState, [event.target.id]: false});
                                                } else {
                                                    setErrorState({ ...errorState, [event.target.id]: true});
                                                }
                                            }}
                                            inputProps={{maxLength: 6}}
                                        />
                                    ) : (
                                        // 保有技能IDがある場合(変更不可)
                                        <TextField id="employee_id" label="社員コード" variant="outlined" size="small" autoFocus
                                            value={inputValues.employee_id}
                                            disabled
                                        />
                                    )}
                                </Grid>
                                <Grid item className={classes.gridItem}>
                                    <TextField id="employee_name" label="氏名" variant="outlined" size="small"
                                        value={inputValues.employee_name}
                                        disabled
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item spacing={1} className={classes.gridRow}>
                                <Grid item className={classes.gridItem}>
                                    {id === undefined ? (
                                        // 保有技能IDがない場合(変更可)
                                        <TextField id="skill_field_code" label="技能分野" variant="outlined" size="small" select className={classes.selectDoubleWidth} 
                                            value={inputValues.skill_field_code}
                                            required
                                            error={errorState.skill_field_code || isDuplicate}
                                            onChange={(event) => {
                                                handleChangeSkillField(event, "skill_field_code");
                                                // 入力チェック
                                                if (event.target.value === '') {
                                                    setErrorState({ ...errorState, skill_field_code: true});
                                                } else {
                                                    setErrorState({ ...errorState, skill_field_code: false});
                                                }
                                            }}
                                        >
                                            <MenuItem value="">None</MenuItem>
                                            {skillFields.map((e) =>
                                                <MenuItem key={e.skill_field_code} value={e.skill_field_code}>{e.skill_field_name}</MenuItem>
                                            )}
                                        </TextField>
                                    ) : (
                                        // 保有技能IDがある場合(変更不可)
                                        <TextField id="skill_field_code" label="技能分野" variant="outlined" size="small" select className={classes.selectDoubleWidth} 
                                            value={inputValues.skill_field_code}
                                            disabled
                                        >
                                            <MenuItem value="">None</MenuItem>
                                            {skillFields.map((e) =>
                                                <MenuItem key={e.skill_field_code} value={e.skill_field_code}>{e.skill_field_name}</MenuItem>
                                            )}
                                        </TextField>
                                    )}
                                </Grid>
                                <Grid item className={classes.gridItem}>
                                    {id === undefined ? (
                                        // 保有技能IDがない場合(変更可)
                                        <TextField id="skill_code" label="技能" variant="outlined" size="small" select className={classes.selectQuadrupleWidth} 
                                            value={inputValues.skill_code}
                                            required
                                            error={errorState.skill_code || isDuplicate}
                                            onChange={(event) => {
                                                handleChangeSkill(event, "skill_code");
                                                // 入力チェック
                                                if (event.target.value === '') {
                                                    setErrorState({ ...errorState, skill_code: true});
                                                } else {
                                                    setErrorState({ ...errorState, skill_code: false});
                                                }
                                            }}
                                        >
                                            <MenuItem value="">None</MenuItem>
                                            {filteredSkills
                                                // 名称でソート
                                                .sort((a, b) => {
                                                    if(a.skill_name > b.skill_name) {
                                                        return 1;
                                                    } else {
                                                        return -1;
                                                    }
                                                })
                                                .map((e) =>
                                                    <MenuItem key={e.skill_code} value={e.skill_code}>{e.skill_name}</MenuItem>
                                            )}
                                        </TextField>
                                    ) : (
                                        // 保有技能IDがある場合(変更不可)
                                        <TextField id="skill_code" label="技能" variant="outlined" size="small" select className={classes.selectQuadrupleWidth} 
                                            value={inputValues.skill_code}
                                            disabled
                                        >
                                            <MenuItem value="">None</MenuItem>
                                            {filteredSkills.map((e) =>
                                                <MenuItem key={e.skill_code} value={e.skill_code}>{e.skill_name}</MenuItem>
                                            )}
                                        </TextField>
                                    )}
                                </Grid>
                            </Grid>
                            <Grid container item spacing={1} className={classes.gridRow}>
                                <Grid item className={classes.gridItem}>
                                    <TextField id="skill_level" label="技能レベル" variant="outlined" size="small" select className={classes.select}
                                        value={inputValues.skill_level}
                                        required
                                        error={errorState.skill_level}
                                        autoFocus={id !== undefined}
                                        onChange={(event) => {
                                            handleInputChange(event, "skill_level");
                                            // 入力チェック
                                            if (event.target.value === '') {
                                                setErrorState({ ...errorState, skill_level: true});
                                            } else {
                                                setErrorState({ ...errorState, skill_level: false});
                                            }
                                        }}
                                    >
                                        <MenuItem value="">None</MenuItem>
                                        {filteredSkillLevels
                                            .map((e) =>
                                                <MenuItem key={e.skill_level} value={e.skill_level}>{e.skill_level + ':' + e.description}</MenuItem>
                                        )}
                                    </TextField>
                                </Grid>
                                <Grid item className={classes.gridItem}>
                                    <TextField id="experience_months" label="経験月数" variant="outlined" size="small"
                                        value={inputValues.experience_months}
                                        required
                                        error={errorState.experience_months}
                                        onChange={(event) => {
                                            handleInputChange(event);
                                            // 入力チェック
                                            if (parseInt(event.target.value) < 0        // 経験月数マイナス
                                                || event.target.value === ''            // 経験月数空
                                                || isNaN(Number(event.target.value))    // 経験月数数値以外
                                                || Number(event.target.value) % 1 > 0   // 経験月数小数
                                            ) {
                                                setErrorState({ ...errorState, experience_months: true});
                                            } else {
                                                setErrorState({ ...errorState, experience_months: false});
                                            }
                                        }}
                                        inputProps={{ style: {textAlign: 'right'} }}
                                    />
                                </Grid>
                                <Grid item className={classes.gridItem}>
                                    <TextField id="possession_qualification_id" label="保有資格" variant="outlined" size="small" select className={classes.selectQuadrupleWidth} 
                                        value={inputValues.possession_qualification_id}
                                        onChange={(event) => handleInputChange(event, "possession_qualification_id")}
                                    >
                                        <MenuItem value="">None</MenuItem>
                                        {filteredPossessionQualifications
                                            // 名称でソート
                                            .sort((a, b) => {
                                                if(a.qualification_name > b.qualification_name) {
                                                    return 1;
                                                } else {
                                                    return -1;
                                                }
                                            })
                                            .map((e) =>
                                                <MenuItem key={e.possession_qualification_id} value={e.possession_qualification_id}>{e.qualification_name}</MenuItem>
                                        )}
                                    </TextField>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Typography component="h2">最終更新</Typography>
                            </Grid>
                            <Grid container item spacing={1} className={classes.gridRow}>
                                <Grid item className={classes.gridItem}>
                                    <TextField id="updated_at" label="更新日時" variant="outlined" size="small" 
                                        value={inputValues.updated_at ? format(Date.parse(inputValues.updated_at), "Y/M/d H:m:s") : ""}
                                        disabled
                                    />
                                </Grid>
                                <Grid item className={classes.gridItem}>
                                    <TextField id="updated_user_name" label="更新者" variant="outlined" size="small"
                                        value={inputValues.updated_user_name}
                                        disabled
                                    />
                                </Grid>
                            </Grid>
                                <Grid container item spacing={1} className={classes.gridRow}>
                                    {((canPost(permissionAuthority, Api.apis.possessionSkills.path) && !id) || (canPut(permissionAuthority, Api.apis.possessionSkills.path) && id)) && (
                                        // 画面権限 追加可または更新可の場合
                                        <Grid item className={classes.gridItem}>
                                            <Button variant="contained" color="primary" startIcon={<Save />}
                                                onClick={() => saveData()}
                                                // 非活性条件
                                                disabled={
                                                    inputValues.employee_id.length !== 6        // 社員コード6桁以外
                                                    || inputValues.employee_name === ''         // 氏名未取得
                                                    || inputValues.skill_field_code === ''      // 技能分野未選択
                                                    || inputValues.skill_code === ''            // 技能未選択
                                                    || inputValues.skill_level === ''           // 技能レベル未選択
                                                    || inputValues.experience_months < 0        // 経験月数マイナス
                                                    || inputValues.experience_months.toLocaleString() === ''    // 経験月数空
                                                    || isNaN(inputValues.experience_months)     // 経験月数数値以外
                                                    || inputValues.experience_months % 1 > 0    // 経験月数小数
                                                    || isDuplicate                              // 重複
                                                }
                                            >登録</Button>
                                        </Grid>
                                    )}
                                    <Grid item xs />
                                    {canDelete(permissionAuthority, Api.apis.possessionSkills.path) && id && (
                                        // 画面権限 削除可の場合
                                        <Grid item className={classes.gridItem}>
                                            <Button variant="contained" color="secondary" startIcon={<Delete />}
                                                onClick={() => deleteData()}
                                                // 非活性条件
                                                disabled={
                                                    id === undefined        // 保有技能ID未設定
                                                }
                                            >削除</Button>
                                        </Grid>
                                    )}
                                </Grid>
                        </Grid>
                    </div>
                </div>
            ) : (
                // 画面権限 参照不可の場合
                <div className={classes.root}>
                    {/* <Typography>参照権限がありません。</Typography> */}
                    <Typography>{permissionAuthorityMessage}</Typography>
                </div>
            )}
                
        </>
    );
}

// default export
export default DetailPossessionSkill;
