export type TPermissionAuthority = {
    apis: [
        {
            path: string;       // 認可リソースパス
            method: string;     // 認可メソッド
        }
    ];
    menus: [
        {
            path: string;       // 認可リソースパス
        }
    ];
    forms: [
        {
            path: string;       // 認可リソースパス
        }
    ];
};

export const defaultPermissionAuthority: TPermissionAuthority = {
    apis: [
        {
            path: "",       // 認可リソースパス
            method: ""      // 認可メソッド
        }
    ],
    menus: [
        {
            path: ""        // 認可リソースパス
        }
    ],
    forms: [
        {
            path: ""        // 認可リソースパス
        }
    ]
};

