// 工程
export type TProjectProcess = {
    project_process_code: string;       // 工程コード
    project_process_name: string;       // 工程名
};

export const defaultProjectProcess: TProjectProcess = {
    project_process_code: "",           // 工程コード
    project_process_name: "",           // 工程名
};

// 使用技能
export type TUseSkill = {
    skill_code: string;     // 技能コード
    skill_name: string;     // 技能名
};

export const defaultUseSkill: TUseSkill = {
    skill_code: "",         // 技能コード
    skill_name: "",         // 技能名
};

// 職務経歴
export type TWorkHistory = {
    work_history_id: string;                    // 職務経歴ID
    employee_id: string;                        // 従業員コード
    employee_name: string;                      // 従業員名
    project_code: string;                       // プロジェクトコード
    project_name: string;                       // プロジェクト名
    start_date: Date | null;                    // 開始年月日
    end_date: Date | null;                      // 終了年月日
    work_content: string;                       // 職務内容
    project_position_code: string;              // 役割コード
    project_position_name: string;              // 役割名
    project_processes: TProjectProcess[];       // 工程リスト
    use_skills: TUseSkill[];                    // 使用技能リスト
    created_at: string;                         // 作成日時
    created_user_id: string;                    // 作成ユーザーID
    created_user_name: string;                  // 作成ユーザー名
    updated_at: string;                         // 更新日時
    updated_user_id: string;                    // 更新ユーザーID
    updated_user_name: string;                  // 更新ユーザー名    
};

export type TWorkHistories = {
    work_histories: TWorkHistory[];
    count: number;
};

export const defaultWorkHistory: TWorkHistory = {
    work_history_id: "",                            // 職務経歴ID
    employee_id: "",                                // 従業員コード
    employee_name: "",                              // 従業員名
    project_code: "",                               // プロジェクトコード
    project_name: "",                               // プロジェクト名
    start_date: null,                               // 開始年月日
    end_date: null,                                 // 終了年月日
    work_content: "",                               // 職務内容
    project_position_code: "",                      // 役割コード
    project_position_name: "",                      // 役割名
    project_processes: [],                          // 工程リスト
    use_skills: [],                                 // 使用技能リスト
    created_at: "",                                 // 作成日時
    created_user_id: "",                            // 作成ユーザーID
    created_user_name: "",                          // 作成ユーザー名
    updated_at: "",                                 // 更新日時
    updated_user_id: "",                            // 更新ユーザーID
    updated_user_name: "",                          // 更新ユーザー名
};
