import { rest } from 'msw';
import mockMenus from './resolvers/mockMenus';                // メニュー
import mockPermissions from './resolvers/mockPermissions';    // 認可リソース

// import mockQualificationFields from './resolvers/mockQualificationFields';              // 資格分野
// import mockQualifications from './resolvers/mockQualifications';                        // 資格
// import mockPossessionQualifications from './resolvers/mockPossessionQualifications';    // 保有資格

// import mockSkillFields from './resolvers/mockSkillFields';              // 技能分野
// import mockSkills from './resolvers/mockSkills';                        // 技能
// import mockSkillLevels from './resolvers/mockSkillLevels';              // 技能レベル
// import mockPossessionSkills from './resolvers/mockPossessionSkills';    // 保有技能

// import mockSkillQualificationAnalysis from './resolvers/mockSkillQualificationAnalysis'   // 技能・資格保有者分析

import mockProjectFields from './resolvers/mockProjectFields';    // プロジェクト分野
import mockBusinessTypes from './resolvers/mockBusinessTypes';    // 業種
import mockContractTypes from './resolvers/mockContractTypes';    // 契約形態
import mockProjects from './resolvers/mockProjects';              // プロジェクト

import mockGradeAnalysis from './resolvers/mockGradeAnalysis';    // グレード分析

import mockProjectPositions from './resolvers/mockProjectPositions';    // 役割
import mockProjectProcesses from './resolvers/mockProjectProcesses';    // 工程
import mockWorkHistories from './resolvers/mockWorkHistories';          // 職務経歴

import mockWorkHistorySheets from './resolvers/mockWorkHistorySheets';    // 職務経歴書出力

import mockEvaluationAnalysis from './resolvers/mockEvaluationAnalysis';  // 評価分析

// APIエンドポイント
const apiEndpoint = 'https://rj10qkhj6f.execute-api.ap-northeast-1.amazonaws.com/dev';

const handlers = [
  rest.get(apiEndpoint + "/menus", mockMenus),                // メニュー
  rest.get(apiEndpoint + '/permissions', mockPermissions),    // 認可リソース

  // rest.get(apiEndpoint + '/qualification_fields', mockQualificationFields),             // 資格分野
  // rest.get(apiEndpoint + '/qualifications', mockQualifications),                        // 資格

  // rest.get(apiEndpoint + '/possession_qualifications', mockPossessionQualifications),           // 保有資格(GET)
  // rest.post(apiEndpoint + '/possession_qualifications', mockPossessionQualifications),          // 保有資格(POST)
  // rest.put(apiEndpoint + '/possession_qualifications/:id', mockPossessionQualifications),       // 保有資格(PUT)
  // rest.delete(apiEndpoint + '/possession_qualifications/:id', mockPossessionQualifications),    // 保有資格(DELETE)

  // rest.get(apiEndpoint + '/skill_fields', mockSkillFields),   // 技能分野
  // rest.get(apiEndpoint + '/skills', mockSkills),              // 技能
  // rest.get(apiEndpoint + '/skill_levels', mockSkillLevels),   // 技能レベル

  // rest.get(apiEndpoint + '/possession_skills', mockPossessionSkills),           // 保有技能(GET)
  // rest.post(apiEndpoint + '/possession_skills', mockPossessionSkills),          // 保有技能(POST)
  // rest.put(apiEndpoint + '/possession_skills/:id', mockPossessionSkills),       // 保有技能(PUT)
  // rest.delete(apiEndpoint + '/possession_skills/:id', mockPossessionSkills),    // 保有技能(DELETE)

  // rest.get(apiEndpoint + '/quicksight/employees_skill_and_qualification_analysis_dashboard_embed_url', mockSkillQualificationAnalysis),   // 技能・資格保有者分析

  rest.get(apiEndpoint + '/project_fields', mockProjectFields),    // プロジェクト分野
  rest.get(apiEndpoint + '/business_types', mockBusinessTypes),    // 業種
  rest.get(apiEndpoint + '/contract_types', mockContractTypes),    // 契約形態

  rest.get(apiEndpoint + '/projects', mockProjects),           // プロジェクト(GET)
  rest.post(apiEndpoint + '/projects', mockProjects),          // プロジェクト(POST)
  rest.put(apiEndpoint + '/projects/:id', mockProjects),       // プロジェクト(PUT)
  rest.delete(apiEndpoint + '/projects/:id', mockProjects),    // プロジェクト(DELETE)

  rest.get(apiEndpoint + '/quicksight/employees_grade_analysis_dashboard_embed_url', mockGradeAnalysis),    // グレード分析

  rest.get(apiEndpoint + '/project_positions', mockProjectPositions),   // 役割(GET)
  rest.get(apiEndpoint + '/project_processes', mockProjectProcesses),   // 工程(GET)

  rest.get(apiEndpoint + '/work_histories', mockWorkHistories),           // 職務経歴(GET)
  rest.post(apiEndpoint + '/work_histories', mockWorkHistories),          // 職務経歴(POST)
  rest.put(apiEndpoint + '/work_histories/:id', mockWorkHistories),       // 職務経歴(PUT)
  rest.delete(apiEndpoint + '/work_histories/:id', mockWorkHistories),    // 職務経歴(DELETE)

  rest.get(apiEndpoint + '/work_history_sheets', mockWorkHistorySheets),    // 職務経歴書出力(GET)

  rest.get(apiEndpoint + '/quicksight/employees_evaluation_analysis_dashboard_embed_url', mockEvaluationAnalysis),    // 評価分析

];

export default handlers;