import { useContext, useEffect, useState } from 'react';
import { API, Auth } from 'aws-amplify';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import { Button, Grid, IconButton, MenuItem, TextField, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { ArrowBack, Delete, Save } from '@material-ui/icons';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { format } from 'date-fns';

import { amplifyAuth, loadingContext } from '../App';
import { ISearchConditions } from './SearchPossessionQualification';
import Api from '../api.json';
import { defaultPossessionQualification, TPossessionQualification } from '../ApiInterface/PossessionQualification';
import { TQualificationField } from '../ApiInterface/QualificationField';
import { TQualification } from '../ApiInterface/Qualification';
import { defaultPermissionAuthority, TPermissionAuthority } from '../ApiInterface/PermissionAuthority';
import { getPermissionAuthority, canReference, canPost, canPut, canDelete } from '../Authority/PermissionAuthority';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            '& > *': {
                margin: theme.spacing(1),
            },
        },
        gridRow: {
            // marginBottom: theme.spacing(1),
        },
        gridItem: {
            marginBottom: theme.spacing(1),
        },
        halfWidth: {
            width: 210 / 2 - 8
        },
        doubleWidth: {
            width: 210 * 2 + 8
        },
        select: {
            minWidth: 210,  // TextField.width:210
            width: 210
        },
        selectHalfWidth: {
            minWidth: 210 / 2 -4, 
            width: 210 / 2 - 4
        },
        selectDoubleWidth: {
            minWidth: 210 * 2 + 8, 
            width: 210 * 2 + 8
        },
        selectQuadrupleWidth: {
            minWidth: 210 * 4 + 8*3, 
            width: 210 * 4 + 8*3
        },
        paper: {
            marginBottom: theme.spacing(1),
            padding: theme.spacing(1),
            paddingTop: theme.spacing(2),
            width: "100%"
        }
    }),
);

// default function
const DetailPossessionQualification = () => {
    const classes = useStyles();

    const history = useHistory();
    const location = useLocation<ISearchConditions>();
    console.log(location);

    // pathname
    const pathname = window.location.pathname;

    const [inputValues, setInputValues] = useState<TPossessionQualification>(defaultPossessionQualification);

    // Error State Type定義
    type TErrorState = {
        employee_id: boolean;                   // 社員コード
        qualification_field_code: boolean;      // 資格分野
        qualification_code: boolean;            // 資格
        acquisition_date: boolean;              // 取得日
    };
    // Error State
    const [errorState, setErrorState] = useState<TErrorState>({
        employee_id: false,
        qualification_field_code:false,
        qualification_code:false,
        acquisition_date: false
    });

    // 画面権限 State
    const [permissionAuthority, setPermissionAuthority] = useState<TPermissionAuthority>(defaultPermissionAuthority);
    const [permissionAuthorityMessage, setPermissionAuthorityMesssage] = useState('Loading...');
    
    // 資格分野リストState
    const [qualificationFields, setQualificationFields] = useState<TQualificationField[]>([]);

    // 資格リストState
    const [qualifications, setQualifications] = useState<TQualification[]>([]);
    const [filteredQualifications, setFilteredQualifications] = useState<TQualification[]>([]);

    // 重複State
    const [isDuplicate, setIsDuplicate] = useState<boolean>(false);

    // useEffect(() => {
        // // リストリソース取得
        // getResources();

        // // 画面権限取得
        // getPermissionAuthority().then(r => {
        //     setPermissionAuthority(r);
        //     if (!canReference(r, pathname, id)) {
        //         setPermissionAuthorityMesssage("参照権限がありません。");
        //     }
        // });

        // 初回のみ実行するため、第2引数の指定は無し。
        // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [])

    // リストリソース取得非同期関数
    const getResources = async () => {
        await Auth.currentSession()
            .then(r => {
                return r.getIdToken().getJwtToken();
            })
            .then(async (jwtToken) => {
                const apiInit = {
                    headers: {
                        Authorization: jwtToken
                    }
                };

                // APIコール 資格分野リスト取得
                await API.get(Api.apiName, Api.apis.qualificationFields.path, apiInit)
                    .then(r => {
                        console.log("API Response", r);
                        // 資格分野リストを取得しStateに設定
                        setQualificationFields(r.qualification_fields);
                    })
                    .catch(e => {
                        console.log("API.get error:", e);
                        alert(e);
                    });

                // APIコール 資格リスト取得
                await API.get(Api.apiName, Api.apis.qualifications.path, apiInit)
                    .then(r => {
                        console.log("API Response", r);
                        // 資格リストを取得しStateに設定
                        setQualifications(r.qualifications);
                    })
                    .catch(e => {
                        console.log("API.get error:", e);
                        alert(e);
                    });

            })
            .catch(e => {
                console.log("Auth.currentSession error", e);
                // {code: "NotAuthorizedException", name: "NotAuthorizedException", message: "Refresh Token has expired"}が返った場合は再認証。
                if (e.code === "NotAuthorizedException") {
                    alert("タイムアウトにより認証情報が無効となりました。トップページに遷移します。")
                    // 再認証
                    amplifyAuth();
                } else {
                    alert(e.message !== undefined ? e.message : e);
                }
            });
    };

    // 入力値変更ハンドル
    const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, id?: string) => {
        const targetId = id == null ? event.target.id : id;
        setInputValues({ ...inputValues, [targetId]: event.target.value });
    }

    // 日付入直値変更ハンドル
    const handleDateChange = (date: Date | null, id: string) => {
        setInputValues({ ...inputValues, [id]: date });
    }

    // 資格分野変更ハンドル
    const handleChangeQualificationField = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, id: string) => {
        // 資格分野変更、資格クリア
        setInputValues({ ...inputValues, [id]: event.target.value, qualification_code: "" });
    };

    // 資格分野変更時Effect
    useEffect(() => {
        // 資格リストフィルタ
        setFilteredQualifications(qualifications.filter(qualification => { return qualification.qualification_field_code === inputValues.qualification_field_code }));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputValues.qualification_field_code]);


    // URLパラメータ
    const { id } = useParams<{id: string}>();
    console.log('id: ', id);

    const loading = useContext(loadingContext);

    useEffect(() => {

        // 画面権限取得
        getPermissionAuthority().then(r => {
            setPermissionAuthority(r);
            if (!canReference(r, pathname, id)) {
                setPermissionAuthorityMesssage("参照権限がありません。");
            }
        });

        // リストリソース取得
        loading.setIsLoading(true);
        getResources().then(r => {
            if (id !== undefined) {
                loading.setIsLoading(true);
                // 保有資格取得
                getPossessionQualifications().then(() => loading.setIsLoading(false));
            }
            console.log('inputValues', inputValues);    
            loading.setIsLoading(false);
        });

        // 初回のみ実行するため、第2引数の指定は無し。
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // 保有資格取得(id指定)
    const getPossessionQualifications = async () => {
        await Auth.currentSession()
            .then(r => {
                return r.getIdToken().getJwtToken();
            })
            .then(async (jwtToken) => {
                const apiInit = {
                    headers: {
                        Authorization: jwtToken
                    }
                };

                // APIコール
                await API.get(Api.apiName, Api.apis.possessionQualifications.path + "?possession_qualification_id=" + id, apiInit)
                    .then(r => {
                        console.log(r);
                        if (r.possession_qualifications[0].possession_qualification_id !== undefined) {
                            setForm(r.possession_qualifications[0]);
                        }
                    })
                    .catch(e => {
                        console.log("API.get error:", e);
                        alert(e);
                    });
            })
            .catch(e => {
                console.log("Auth.currentSession error", e);
                // {code: "NotAuthorizedException", name: "NotAuthorizedException", message: "Refresh Token has expired"}が返った場合は再認証。
                if (e.code === "NotAuthorizedException") {
                    alert("タイムアウトにより認証情報が無効となりました。トップページに遷移します。")
                    // 再認証
                    amplifyAuth();
                } else {
                    alert(e.message !== undefined ? e.message : e);
                }
            });
    };

    // 画面項目値設定
    const setForm = (apiResponse: TPossessionQualification) => {
        console.log('apiResponse', apiResponse);
        setInputValues(apiResponse);
    };

    // 重複チェック
    useEffect(() => {
        if (!id) {
            if (inputValues.employee_id.length === 6 && inputValues.qualification_field_code !== '' && inputValues.qualification_code !== '' && inputValues.acquisition_date !== null) {
                loading.setIsLoading(true);
                // 重複保有資格取得
                getDuplicatePossessionQualifications().then(() => loading.setIsLoading(false));
            } else {
                setIsDuplicate(false);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputValues.employee_id, inputValues.qualification_field_code, inputValues.qualification_code, inputValues.acquisition_date]);

    // 重複保有資格取得(社員コード, 資格分野, 資格指定)
    const getDuplicatePossessionQualifications = async () => {
        await Auth.currentSession()
            .then(r => {
                return r.getIdToken().getJwtToken();
            })
            .then(async (jwtToken) => {
                const apiInit = {
                    headers: {
                        Authorization: jwtToken
                    }
                };

                // APIコール
                await API.get(Api.apiName, Api.apis.possessionQualifications.path
                    + "?employee_id=" + inputValues.employee_id
                    + "&qualification_field_code=" + inputValues.qualification_field_code
                    + "&qualification_code=" + inputValues.qualification_code, apiInit)
                    .then(r => {
                        console.log(r);
                        if (r.possession_qualifications.length > 0) {
                            if (inputValues.acquisition_date && !isNaN(inputValues.acquisition_date.getTime())) {
                                if (r.possession_qualifications[0].acquisition_date === (inputValues.acquisition_date ? format(new Date(inputValues.acquisition_date), "yyyy-MM-dd") : null)) {
                                    setIsDuplicate(true);
                                    alert("登録済みの保有資格です。");
                                } else {
                                    setIsDuplicate(false);
                                }
                            } else {
                                setIsDuplicate(false);
                            }
                        } else{
                            setIsDuplicate(false);
                        }
                    })
                    .catch(e => {
                        console.log("API.get error:", e);
                        alert(e);
                    });
            })
            .catch(e => {
                console.log("Auth.currentSession error", e);
                // {code: "NotAuthorizedException", name: "NotAuthorizedException", message: "Refresh Token has expired"}が返った場合は再認証。
                if (e.code === "NotAuthorizedException") {
                    alert("タイムアウトにより認証情報が無効となりました。トップページに遷移します。")
                    // 再認証
                    amplifyAuth();
                } else {
                    alert(e.message !== undefined ? e.message : e);
                }
            });
    };
    
    // 登録処理
    const saveData = () => {
        if (id) {
            // 変更
            loading.setIsLoading(true);
            putPossessionQualifications().then(() => {
                loading.setIsLoading(false);

                // 画面遷移
                history.push("/possessionqualification",  location.state);
            });

        } else {
            // 追加
            loading.setIsLoading(true);
            postPossessionQualifications().then(() => {
                loading.setIsLoading(false);

                // 画面遷移
                history.push("/possessionqualification",  location.state);
            });
        }
    };

    // 保有資格登録(追加)
    const postPossessionQualifications = async () => {
        await Auth.currentSession()
            .then(r => {
                return r.getIdToken().getJwtToken();
            })
            .then(async (jwtToken) => {
                const apiInit = {
                    headers: {
                        Authorization: jwtToken
                    },
                    body: {
                        possession_qualifications: [
                            {
                                employee_id: inputValues.employee_id,                                   // 社員コード
                                qualification_code: inputValues.qualification_code,                     // 資格コード
                                acquisition_date: inputValues.acquisition_date,                         // 取得日
                                expiry_date: inputValues.expiry_date,                                   // 有効期限
                                qualification_number: inputValues.qualification_number,                 // 資格番号
                                certification_document_url: inputValues.certification_document_url,     // 証明文書管理URL
                            }
                        ]
                    }
                };

                // APIコール
                await API.post(Api.apiName, Api.apis.possessionQualifications.path, apiInit)
                    .then(r => {
                        console.log(r);
                    })
                    .catch(e => {
                        console.log("API.post error:", e);
                        alert(e);
                    });
            })
            .catch(e => {
                console.log("Auth.currentSession error", e);
                // {code: "NotAuthorizedException", name: "NotAuthorizedException", message: "Refresh Token has expired"}が返った場合は再認証。
                if (e.code === "NotAuthorizedException") {
                    alert("タイムアウトにより認証情報が無効となりました。トップページに遷移します。")
                    // 再認証
                    amplifyAuth();
                } else {
                    alert(e.message !== undefined ? e.message : e);
                }
            });
    };

    // 保有資格登録(変更)
    const putPossessionQualifications = async () => {
        await Auth.currentSession()
            .then(r => {
                return r.getIdToken().getJwtToken();
            })
            .then(async (jwtToken) => {
                const apiInit = {
                    headers: {
                        Authorization: jwtToken
                    },
                    body: {
                        expiry_date: inputValues.expiry_date ? format(new Date(inputValues.expiry_date), "yyyy-MM-dd") : null,   // 有効期限
                        qualification_number: inputValues.qualification_number,                 // 資格番号
                        certification_document_url: inputValues.certification_document_url,     // 証明文書管理URL
                    }
                };

                // APIコール
                await API.put(Api.apiName, Api.apis.possessionQualifications.path + "/" + id, apiInit)
                    .then(r => {
                        console.log(r);
                    })
                    .catch(e => {
                        console.log("API.put error:", e);
                        alert(e);
                    });
            })
            .catch(e => {
                console.log("Auth.currentSession error", e);
                // {code: "NotAuthorizedException", name: "NotAuthorizedException", message: "Refresh Token has expired"}が返った場合は再認証。
                if (e.code === "NotAuthorizedException") {
                    alert("タイムアウトにより認証情報が無効となりました。トップページに遷移します。")
                    // 再認証
                    amplifyAuth();
                } else {
                    alert(e.message !== undefined ? e.message : e);
                }
            });
    };

    // 削除処理
    const deleteData = () => {
        loading.setIsLoading(true);
        deletePossessionQualifications().then(() => {
            loading.setIsLoading(false);

            // 画面遷移
            history.push("/possessionqualification",  location.state);
        });

    };

    // 保有資格削除
    const deletePossessionQualifications = async () => {
        await Auth.currentSession()
            .then(r => {
                return r.getIdToken().getJwtToken();
            })
            .then(async (jwtToken) => {
                const apiInit = {
                    headers: {
                        Authorization: jwtToken
                    }
                };

                // APIコール
                await API.del(Api.apiName, Api.apis.possessionQualifications.path + "/" + id, apiInit)
                    .then(r => {
                        console.log(r);
                    })
                    .catch(e => {
                        console.log("API.del error:", e);
                        alert(e);
                    });
            })
            .catch(e => {
                console.log("Auth.currentSession error", e);
                // {code: "NotAuthorizedException", name: "NotAuthorizedException", message: "Refresh Token has expired"}が返った場合は再認証。
                if (e.code === "NotAuthorizedException") {
                    alert("タイムアウトにより認証情報が無効となりました。トップページに遷移します。")
                    // 再認証
                    amplifyAuth();
                } else {
                    alert(e.message !== undefined ? e.message : e);
                }
            });
    };

    // 社員コード変更時
    useEffect(() => {
        if (!id) {
            if (inputValues.employee_id.length === 6) {
                loading.setIsLoading(true);
                getEmployees(inputValues.employee_id).then(() => loading.setIsLoading(false));
            } else {
                setInputValues({...inputValues, employee_name: ''});
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputValues.employee_id])

    // 人事基本情報取得(氏名)
    const getEmployees = async (employee_id: string) => {
        await Auth.currentSession()
            .then(r => {
                return r.getIdToken().getJwtToken();
            })
            .then(async (jwtToken) => {
                const apiInit = {
                    headers: {
                        Authorization: jwtToken
                    }
                };

                // APIコール
                await API.get(Api.apiName, Api.apis.employees.path + "?employee_id=" + employee_id, apiInit)
                    .then(r => {
                        console.log(r);
                        if (r.employees[0]?.employee_name !== undefined) {
                            // setInputValues({...inputValues, employee_name: r.employees[0].employee_name})
                            setInputValues(prevValues => ({...prevValues, employee_name: r.employees[0].employee_name}));
                        }
                    })
                    .catch(e => {
                        console.log("API.get error:", e);
                        alert(e);
                    });
            })
            .catch(e => {
                console.log("Auth.currentSession error", e);
                // {code: "NotAuthorizedException", name: "NotAuthorizedException", message: "Refresh Token has expired"}が返った場合は再認証。
                if (e.code === "NotAuthorizedException") {
                    alert("タイムアウトにより認証情報が無効となりました。トップページに遷移します。")
                    // 再認証
                    amplifyAuth();
                } else {
                    alert(e.message !== undefined ? e.message : e);
                }
            });
    };

    return (
        <>
            {canReference(permissionAuthority, pathname, id) ? (
                // 画面権限 参照可の場合
                <div className={classes.root}>
                    <Grid container alignItems="center">
                        <Grid item>
                            <IconButton component={Link} to={{ pathname: "/possessionqualification", state: location.state }} size="small"><ArrowBack /></IconButton>
                        </Grid>
                        <Grid item>
                            <Typography component="h2">保有資格 詳細</Typography>
                        </Grid>
                        <Grid item xs />
                    </Grid>
                    <div>
                        <Grid container spacing={2}>
                            <Grid container item spacing={1} className={classes.gridRow} >
                                <Grid item className={classes.gridItem}>
                                    {id === undefined ? (
                                        // 保有資格IDがない場合(変更可)
                                        <TextField id="employee_id" label="社員コード" variant="outlined" size="small" autoFocus
                                            value={inputValues.employee_id}
                                            required
                                            error={errorState.employee_id || isDuplicate}
                                            onChange={(event) => {
                                                handleInputChange(event);
                                                // 入力チェック
                                                if (event.target.value.length === 6) {
                                                    setErrorState({ ...errorState, [event.target.id]: false});
                                                } else {
                                                    setErrorState({ ...errorState, [event.target.id]: true});
                                                }
                                            }}
                                            inputProps={{maxLength: 6}}
                                        />
                                    ) : (
                                        // 保有資格IDがある場合(変更不可)
                                        <TextField id="employee_id" label="社員コード" variant="outlined" size="small" autoFocus
                                            value={inputValues.employee_id}
                                            disabled
                                        />
                                    )}
                                </Grid>
                                <Grid item className={classes.gridItem}>
                                    <TextField id="employee_name" label="氏名" variant="outlined" size="small"
                                        value={inputValues.employee_name}
                                        disabled
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item spacing={1} className={classes.gridRow}>
                                <Grid item className={classes.gridItem}>
                                    {id === undefined ? (
                                        // 保有資格IDがない場合(変更可)
                                        <TextField id="qualification_field_code" label="資格分野" variant="outlined" size="small" select className={classes.selectDoubleWidth} 
                                            value={inputValues.qualification_field_code}
                                            required
                                            error={errorState.qualification_field_code || isDuplicate}
                                            onChange={(event) => {
                                                handleChangeQualificationField(event, "qualification_field_code");
                                                // 入力チェック
                                                if (event.target.value === '') {
                                                    setErrorState({ ...errorState, qualification_field_code: true});
                                                } else {
                                                    setErrorState({ ...errorState, qualification_field_code: false});
                                                }
                                            }}
                                        >
                                            <MenuItem value="">None</MenuItem>
                                            {qualificationFields.map((e) =>
                                                <MenuItem key={e.qualification_field_code} value={e.qualification_field_code}>{e.qualification_field_name}</MenuItem>
                                            )}
                                        </TextField>
                                    ) : (
                                        // 保有資格IDがある場合(変更不可)
                                        <TextField id="qualification_field_code" label="資格分野" variant="outlined" size="small" select className={classes.selectDoubleWidth} 
                                            value={inputValues.qualification_field_code}
                                            disabled
                                        >
                                            <MenuItem value="">None</MenuItem>
                                            {qualificationFields.map((e) =>
                                                <MenuItem key={e.qualification_field_code} value={e.qualification_field_code}>{e.qualification_field_name}</MenuItem>
                                            )}
                                        </TextField>
                                    )}
                                </Grid>
                                <Grid item className={classes.gridItem}>
                                    {id === undefined ? (
                                        // 保有資格IDがない場合(変更可)
                                        <TextField id="qualification_code" label="資格" variant="outlined" size="small" select className={classes.selectQuadrupleWidth} 
                                            value={inputValues.qualification_code}
                                            required
                                            error={errorState.qualification_code || isDuplicate}
                                            onChange={(event) => {
                                                handleInputChange(event, "qualification_code");
                                                // 入力チェック
                                                if (event.target.value === '') {
                                                    setErrorState({ ...errorState, qualification_code: true});
                                                } else {
                                                    setErrorState({ ...errorState, qualification_code: false});
                                                }
                                            }}
                                        >
                                            <MenuItem value="">None</MenuItem>
                                            {filteredQualifications
                                                // 名称でソート
                                                .sort((a, b) => {
                                                    if(a.qualification_name > b.qualification_name) {
                                                        return 1;
                                                    } else {
                                                        return -1;
                                                    }
                                                })
                                                .map((e) =>
                                                    <MenuItem key={e.qualification_code} value={e.qualification_code}>{e.qualification_name}</MenuItem>
                                            )}
                                        </TextField>
                                    ) : (
                                        // 保有資格IDがある場合(変更不可)
                                        <TextField id="qualification_code" label="資格" variant="outlined" size="small" select className={classes.selectQuadrupleWidth} 
                                            value={inputValues.qualification_code}
                                            disabled
                                        >
                                            <MenuItem value="">None</MenuItem>
                                            {filteredQualifications.map((e) =>
                                                <MenuItem key={e.qualification_code} value={e.qualification_code}>{e.qualification_name}</MenuItem>
                                            )}
                                        </TextField>
                                    )}
                                </Grid>
                                <Grid item className={classes.gridItem}>
                                    {id === undefined ? (
                                        // 保有資格IDがない場合(変更可)
                                        <KeyboardDatePicker id="acquisition_date" label="取得日" autoOk disableToolbar
                                            value={inputValues.acquisition_date}
                                            inputVariant="outlined" variant="inline" size="small" format="yyyy/MM/dd" className={classes.select}
                                            required
                                            error={errorState.acquisition_date || isDuplicate}
                                            onChange={(date) => {
                                                handleDateChange(date, "acquisition_date");
                                                // 入力チェック
                                                if (date === null || isNaN(date.getTime())) {
                                                    setErrorState({ ...errorState, acquisition_date: true});
                                                } else {
                                                    setErrorState({ ...errorState, acquisition_date: false});
                                                }
                                            }}
                                        />
                                    ) : (
                                        // 保有資格IDがある場合(変更不可)
                                        <KeyboardDatePicker id="acquisition_date" label="取得日" autoOk disableToolbar
                                            value={inputValues.acquisition_date}
                                            inputVariant="outlined" variant="inline" size="small" format="yyyy/MM/dd" className={classes.select}
                                            onChange={()=>{}}
                                            disabled
                                        />
                                    )}

                                </Grid>
                            </Grid>
                            <Grid container item spacing={1} className={classes.gridRow}>
                                <Grid item className={classes.gridItem}>
                                    <TextField id="qualification_number" label="資格番号" variant="outlined" size="small"
                                        value={inputValues.qualification_number}
                                        autoFocus={id !== undefined}
                                        onChange={(event) => handleInputChange(event)}
                                    />
                                </Grid>
                                <Grid item className={classes.gridItem}>
                                    <KeyboardDatePicker id="expiry_date" label="有効期限" autoOk disableToolbar
                                        value={inputValues.expiry_date}
                                        inputVariant="outlined" variant="inline" size="small" format="yyyy/MM/dd" className={classes.select}
                                        onChange={(date) => handleDateChange(date, "expiry_date")}
                                    />
                                </Grid>
                                <Grid item className={classes.gridItem}>
                                    <TextField id="certification_document_url" label="証明文書管理URL" variant="outlined" size="small" className={classes.doubleWidth} 
                                        value={inputValues.certification_document_url}
                                        onChange={(event) => handleInputChange(event)}
                                    />
                                </Grid>

                            </Grid>
                            <Grid item>
                                <Typography component="h2">最終更新</Typography>
                            </Grid>
                            <Grid container item spacing={1} className={classes.gridRow}>
                                <Grid item className={classes.gridItem}>
                                    <TextField id="updated_at" label="更新日時" variant="outlined" size="small" 
                                        value={inputValues.updated_at ? format(Date.parse(inputValues.updated_at), "Y/M/d H:m:s") : ""}
                                        disabled
                                    />
                                </Grid>
                                <Grid item className={classes.gridItem}>
                                    <TextField id="updated_user_name" label="更新者" variant="outlined" size="small"
                                        value={inputValues.updated_user_name}
                                        disabled
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item spacing={1} className={classes.gridRow}>
                                {((canPost(permissionAuthority, Api.apis.possessionQualifications.path) && !id) || (canPut(permissionAuthority, Api.apis.possessionQualifications.path) && id)) && (
                                    // 画面権限 追加可または更新可の場合
                                    <Grid item className={classes.gridItem}>
                                        <Button variant="contained" color="primary" startIcon={<Save />}
                                            onClick={() => saveData()}
                                            // 非活性条件
                                            disabled={
                                                inputValues.employee_id.length !== 6                // 社員コード6桁以外
                                                || inputValues.employee_name === ''                 // 氏名未取得
                                                || inputValues.qualification_field_code === ''      // 資格分野未選択
                                                || inputValues.qualification_code === ''            // 資格未選択
                                                || inputValues.acquisition_date === null            // 取得日未入力
                                                || (inputValues.acquisition_date && isNaN(new Date(inputValues.acquisition_date).getTime()))    // 取得日不正
                                                || (inputValues.expiry_date && isNaN(new Date(inputValues.expiry_date).getTime()))    // 有効期限不正
                                                || isDuplicate                                      // 重複
                                            }
                                        >登録</Button>
                                    </Grid>
                                )}
                                <Grid item xs />
                                {canDelete(permissionAuthority, Api.apis.possessionQualifications.path) && id && (
                                    // 画面権限 削除可の場合
                                    <Grid item className={classes.gridItem}>
                                        <Button variant="contained" color="secondary" startIcon={<Delete />}
                                            onClick={() => deleteData()}
                                            // 非活性条件
                                            disabled={
                                                id === undefined        // 保有資格ID未設定
                                            }
                                        >削除</Button>
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                    </div>
                </div>
            ) : (
                // 画面権限 参照不可の場合
                <div className={classes.root}>
                    {/* <Typography>参照権限がありません。</Typography> */}
                    <Typography>{permissionAuthorityMessage}</Typography>
                </div>
            )}
                
        </>
    );
}

// default export
export default DetailPossessionQualification;
