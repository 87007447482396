import { ResponseResolver, MockedRequest, restContext } from 'msw';

const mockContractTypes: ResponseResolver<MockedRequest, typeof restContext> = async (req, res, ctx) => {
    console.log(req);

    const contractTypes = {
        "count": 5,
        "contract_types": [
            { "contract_type_code": "ct01", "contract_type_name": "一括契約" },
            { "contract_type_code": "ct02", "contract_type_name": "準委任契約" },
            { "contract_type_code": "ct03", "contract_type_name": "派遣契約" },
            { "contract_type_code": "ct04", "contract_type_name": "契約なし" },

            { "contract_type_code": "ct99", "contract_type_name": "mock" }
        ]
    };

    // 仮想処理時間
    await new Promise(s => setTimeout(s, 2000));

    return res(ctx.json(contractTypes));
};
  
export default mockContractTypes;
