import { ResponseResolver, MockedRequest, restContext } from 'msw';

const mockProjects: ResponseResolver<MockedRequest, typeof restContext> = async (req, res, ctx) => {
    console.log(req);

    // GET
    if (req.method === 'GET') {
        // クエリパラメータ
        const project_code = req.url.searchParams.get('project_code');                  // プロジェクトコード
        const project_name = req.url.searchParams.get('project_name');                  // プロジェクト名
        const project_field_code = req.url.searchParams.get('project_field_code');      // プロジェクト分野コード
        const business_type_code = req.url.searchParams.get('business_type_code');      // 業種コード
        const contract_type_code = req.url.searchParams.get('contract_type_code');      // 契約形態コード
        
        const projects = {
            "count": 7,
            "projects": [
                {
                    "project_code": "AAA01",
                    "project_name": "プロジェクトAAA01",
                    "start_date": "2022/3/1",
                    "end_date": "2023/2/28",
                    "project_field_code": "pf01",
                    "project_field_name": "システム新規開発",
                    "business_type_code": "5200",
                    "business_type_name": "倉庫・運輸関連業",
                    "project_content": "プロジェクト内容",
                    "project_scale": "11.1",
                    "contract_type_code": "ct02",
                    "contract_type_name": "準委任契約",
                    "created_at": "2023/3/8 17:28",
                    "created_user_id": "210001",
                    "created_user_name": "人事　太郎",
                    "updated_at": "2023/3/8 17:28",
                    "updated_user_id": "210001",
                    "updated_user_name": "人事　太郎",
                },

                {
                    "project_code": "AAA02",
                    "project_name": "プロジェクトAAA02",
                    "start_date": "2022/3/1",
                    "end_date": "2023/2/28",
                    "project_field_code": "pf02",
                    "project_field_name": "システム保守開発",
                    "business_type_code": "5200",
                    "business_type_name": "倉庫・運輸関連業",
                    "project_content": "プロジェクト内容",
                    "project_scale": "11.2",
                    "contract_type_code": "ct02",
                    "contract_type_name": "準委任契約",
                    "created_at": "2023/3/8 17:28",
                    "created_user_id": "210001",
                    "created_user_name": "人事　太郎",
                    "updated_at": "2023/3/8 17:28",
                    "updated_user_id": "210001",
                    "updated_user_name": "人事　太郎",
                },

                {
                    "project_code": "AAA03",
                    "project_name": "プロジェクトAAA03",
                    "start_date": "2022/3/1",
                    "end_date": "2023/2/28",
                    "project_field_code": "pf03",
                    "project_field_name": "パッケージ適用・導入",
                    "business_type_code": "5200",
                    "business_type_name": "倉庫・運輸関連業",
                    "project_content": "プロジェクト内容",
                    "project_scale": "11.3",
                    "contract_type_code": "ct02",
                    "contract_type_name": "準委任契約",
                    "created_at": "2023/3/8 17:28",
                    "created_user_id": "210001",
                    "created_user_name": "人事　太郎",
                    "updated_at": "2023/3/8 17:28",
                    "updated_user_id": "210001",
                    "updated_user_name": "人事　太郎",
                },

                {
                    "project_code": "BBB02",
                    "project_name": "プロジェクトBBB02",
                    "start_date": "2022/1/1",
                    "end_date": "2022/12/31",
                    "project_field_code": "pf01",
                    "project_field_name": "システム新規開発",
                    "business_type_code": "2050",
                    "business_type_name": "建設業",
                    "project_content": "プロジェクト内容",
                    "project_scale": "12",
                    "contract_type_code": "ct01",
                    "contract_type_name": "一括契約",
                    "created_at": "2023/3/8 17:28",
                    "created_user_id": "210001",
                    "created_user_name": "人事　太郎",
                    "updated_at": "2023/3/8 17:28",
                    "updated_user_id": "210001",
                    "updated_user_name": "人事　太郎",
                },

                {
                    "project_code": "CCC03",
                    "project_name": "プロジェクトCCC03",
                    "start_date": "2022/10/10",
                    "end_date": "2022/11/11",
                    "project_field_code": "pf07",
                    "project_field_name": "プロジェクト管理・管理支援",
                    "business_type_code": "7150",
                    "business_type_name": "保険業",
                    "project_content": "プロジェクト内容",
                    "project_scale": "13",
                    "contract_type_code": "ct03",
                    "contract_type_name": "派遣契約",
                    "created_at": "2023/3/8 17:28",
                    "created_user_id": "210001",
                    "created_user_name": "人事　太郎",
                    "updated_at": "2023/3/8 17:28",
                    "updated_user_id": "210001",
                    "updated_user_name": "人事　太郎",
                },

                {
                    "project_code": "EEE05",
                    "project_name": "プロジェクト７８９０１２３４５６７８９０１",
                    "start_date": "2022/3/1",
                    "end_date": "2023/2/28",
                    "project_field_code": "pf05",
                    "project_field_name": "ヘルプデスク/サポートデスク",
                    "business_type_code": "3400",
                    "business_type_name": "製造業(ガラス・土石製品)",
                    "project_content": "プロジェクト内容",
                    "project_scale": "15",
                    "contract_type_code": "ct02",
                    "contract_type_name": "準委任契約",
                    "created_at": "2023/3/8 17:28",
                    "created_user_id": "210001",
                    "created_user_name": "人事　太郎",
                    "updated_at": "2023/3/8 17:28",
                    "updated_user_id": "210001",
                    "updated_user_name": "人事　太郎",
                },

                {
                    "project_code": "JIA01",
                    "project_name": "プロジェクトJIA01",
                    "start_date": "2022/4/1",
                    "end_date": "2022/6/30",
                    "project_field_code": "pf99",
                    "project_field_name": "その他",
                    "business_type_code": "z050",
                    "business_type_name": "研修",
                    "project_content": "プロジェクト内容",
                    "project_scale": "0",
                    "contract_type_code": "ct04",
                    "contract_type_name": "契約なし",
                    "created_at": "2023/3/8 17:28",
                    "created_user_id": "210001",
                    "created_user_name": "人事　太郎",
                    "updated_at": "2023/3/8 17:28",
                    "updated_user_id": "210001",
                    "updated_user_name": "人事　太郎",
                },

            ]
        };
        
        let filtered = projects;

        // プロジェクトコードでフィルタ(前方一致)
        if (project_code) {
            filtered.projects = filtered.projects.filter(v => v.project_code.indexOf(project_code) === 0);
        }
        // プロジェクト名でフィルタ(部分一致)
        if (project_name) {
            filtered.projects = filtered.projects.filter(v => v.project_name.indexOf(project_name) !== -1);
        }
        // プロジェクト分野コードでフィルタ(完全一致)
        if (project_field_code) {
            filtered.projects = filtered.projects.filter(v => v.project_field_code === project_field_code);
        }
        // 業種コードでフィルタ(完全一致)
        if (business_type_code) {
            filtered.projects = filtered.projects.filter(v => v.business_type_code === business_type_code);
        }
        // 契約形態コードでフィルタ(完全一致)
        if (contract_type_code) {
            filtered.projects = filtered.projects.filter(v => v.contract_type_code === contract_type_code);
        }
        
        // count
        filtered.count = filtered.projects.length;

        // 仮想処理時間
        await new Promise(s => setTimeout(s, 2000));

        return res(ctx.json(filtered));
    }

    // POST
    if (req.method === 'POST') {
        const projects = {
            "count": 1,
            "projects": [
                {
                    "project_code": "AAA01",
                    "project_name": "プロジェクトAAA01",
                    "start_date": "2022/3/1",
                    "end_date": "2023/2/28",
                    "project_field_code": "pf01",
                    "project_field_name": "プロジェクト分野０１",
                    "business_type_code": "bt01",
                    "business_type_name": "業種０１",
                    "project_content": "プロジェクト内容",
                    "project_scale": "11.1",
                    "contract_type_code": "ct01",
                    "contract_type_name": "契約形態０１",
                    "created_at": "2023/3/8 17:28",
                    "created_user_id": "210001",
                    "created_user_name": "人事　太郎",
                    "updated_at": "2023/3/8 17:28",
                    "updated_user_id": "210001",
                    "updated_user_name": "人事　太郎",
                },
            ]
        };

        // 仮想処理時間
        await new Promise(s => setTimeout(s, 2000));

        return res(ctx.status(201), ctx.json(projects));
    }

    // PUT
    if (req.method === 'PUT') {
        const project = {
            "project_code": "AAA01",
            "project_name": "プロジェクトAAA01",
            "start_date": "2022/3/1",
            "end_date": "2023/2/28",
            "project_field_code": "pf01",
            "project_field_name": "プロジェクト分野０１",
            "business_type_code": "bt01",
            "business_type_name": "業種０１",
            "project_content": "プロジェクト内容",
            "project_scale": "11.1",
            "contract_type_code": "ct01",
            "contract_type_name": "契約形態０１",
            "created_at": "2023/3/8 17:28",
            "created_user_id": "210001",
            "created_user_name": "人事　太郎",
            "updated_at": "2023/3/8 17:28",
            "updated_user_id": "210001",
            "updated_user_name": "人事　太郎",
        };

        // 仮想処理時間
        await new Promise(s => setTimeout(s, 2000));

        return res(ctx.status(200), ctx.json(project));
    }

    // DELETE
    if (req.method === 'DELETE') {
        // 仮想処理時間
        await new Promise(s => setTimeout(s, 2000));        

        return res(ctx.status(200));
    }
};
  
export default mockProjects;
