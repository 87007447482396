import React, { useContext, useEffect, useState } from 'react';
import { API, Auth } from 'aws-amplify';
import { Button, Grid, TextField, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { CloudDownload } from '@material-ui/icons';
import { Buffer } from 'buffer';

import { amplifyAuth, loadingContext } from '../App';
import Api from '../api.json';
import { defaultPermissionAuthority, TPermissionAuthority } from '../ApiInterface/PermissionAuthority';
import { getPermissionAuthority, canReference } from '../Authority/PermissionAuthority';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            '& > *': {
                margin: theme.spacing(1),
            },
        },
        gridRow: {
            // marginBottom: theme.spacing(1),
        },
        gridItem: {
            marginBottom: theme.spacing(1),
        },
        halfWidth: {
            width: 210 / 2 - 8
        },
        doubleWidth: {
            width: 210 * 2 + 8
        },
        QuadrupleWidth: {
            width: 210 * 4 + 8*3
        },
        select: {
            minWidth: 210,  // TextField.width:210
            width: 210
        },
        selectHalfWidth: {
            minWidth: 210 / 2 -4, 
            width: 210 / 2 - 4
        },
        selectDoubleWidth: {
            minWidth: 210 * 2 + 8, 
            width: 210 * 2 + 8
        },
        selectQuadrupleWidth: {
            minWidth: 210 * 4 + 8*3, 
            width: 210 * 4 + 8*3
        },
        paper: {
            marginBottom: theme.spacing(1),
            padding: theme.spacing(1),
            paddingTop: theme.spacing(2),
            width: "100%"
        }
    }),
);

// default function
const ExportlWorkHistorySheet = () => {
    const classes = useStyles();

    // pathname
    const pathname = window.location.pathname;

    type TExportWorkHistorySheet = {
        employee_id: string;        // 社員コード
        employee_name: string;      // 社員名
    };
    
    const defaultExportWorkHistorySheet: TExportWorkHistorySheet = {
        employee_id: "",        // 社員コード
        employee_name: ""       // 社員名
    };

    const [inputValues, setInputValues] = useState<TExportWorkHistorySheet>(defaultExportWorkHistorySheet);

    // Error State Type定義
    type TErrorState = {
        employee_id: boolean;       // 社員コード
    };
    // Error State
    const [errorState, setErrorState] = useState<TErrorState>({
        employee_id: false,
    });

    // 画面権限 State
    const [permissionAuthority, setPermissionAuthority] = useState<TPermissionAuthority>(defaultPermissionAuthority);
    const [permissionAuthorityMessage, setPermissionAuthorityMesssage] = useState('Loading...');

    // 入力値変更ハンドル
    const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, id?: string) => {
        const targetId = id == null ? event.target.id : id;
        setInputValues({ ...inputValues, [targetId]: event.target.value });
    }

    const loading = useContext(loadingContext);

    useEffect(() => {

        // 画面権限取得
        getPermissionAuthority().then(r => {
            setPermissionAuthority(r);
            if (!canReference(r, pathname)) {
                setPermissionAuthorityMesssage("参照権限がありません。");
            }
        });

        // 初回のみ実行するため、第2引数の指定は無し。
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // ダウンロード処理
    const downloadData = () => {
        loading.setIsLoading(true);
        getWorkHistorySheet().then(() => {
            loading.setIsLoading(false);
        });
    };

    // 職務経歴書取得
    const getWorkHistorySheet = async () => {
        await Auth.currentSession()
            .then(r => {
                return r.getIdToken().getJwtToken();
            })
            .then(async (jwtToken) => {
                const apiInit = {
                    headers: {
                        Authorization: jwtToken
                    }
                };

                // 検索条件指定
                let params = {};
                // 社員コード
                if (inputValues.employee_id.length !== 0) { params = { ...params, employee_id: inputValues.employee_id }; }

                const getParams: string = Object.entries(params).length > 0 ? "?" + Object.entries(params).map((e) => `${e[0]}=${e[1]}`).join("&") : "";
                console.log(getParams);

                // APIコール
                await API.get(Api.apiName, Api.apis.workHistorySheets.path + getParams, apiInit)
                    .then(r => {
                        console.log('response:', r);

                        // レスポンスからファイル情報取得
                        const fileName = r.file_name;
                        const fileBuffer = Buffer.from(r.file_data, 'base64');

                        // ダウンロード処理
                        const url = window.URL.createObjectURL(new Blob([fileBuffer]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', fileName);
                        document.body.appendChild(link);
                        link.click();
                        link.remove();
                    })
                    .catch(e => {
                        console.log("API.get error:", e);
                        alert(e);
                    });
            })
            .catch(e => {
                console.log("Auth.currentSession error", e);
                // {code: "NotAuthorizedException", name: "NotAuthorizedException", message: "Refresh Token has expired"}が返った場合は再認証。
                if (e.code === "NotAuthorizedException") {
                    alert("タイムアウトにより認証情報が無効となりました。トップページに遷移します。")
                    // 再認証
                    amplifyAuth();
                } else {
                    alert(e.message !== undefined ? e.message : e);
                }
            });
    };

    // 社員コード変更時
    useEffect(() => {
        if (inputValues.employee_id.length === 6) {
            loading.setIsLoading(true);
            getEmployees(inputValues.employee_id).then(() => loading.setIsLoading(false));
        } else {
            setInputValues({...inputValues, employee_name: ''});
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputValues.employee_id])

    // 人事基本情報取得(氏名)
    const getEmployees = async (employee_id: string) => {
        await Auth.currentSession()
            .then(r => {
                return r.getIdToken().getJwtToken();
            })
            .then(async (jwtToken) => {
                const apiInit = {
                    headers: {
                        Authorization: jwtToken
                    }
                };

                // APIコール
                await API.get(Api.apiName, Api.apis.employees.path + "?employee_id=" + employee_id, apiInit)
                    .then(r => {
                        console.log(r);
                        if (r.employees[0]?.employee_name !== undefined) {
                            setInputValues(prevValues => ({...prevValues, employee_name: r.employees[0].employee_name}))
                        }
                    })
                    .catch(e => {
                        console.log("API.get error:", e);
                        alert(e);
                    });
            })
            .catch(e => {
                console.log("Auth.currentSession error", e);
                // {code: "NotAuthorizedException", name: "NotAuthorizedException", message: "Refresh Token has expired"}が返った場合は再認証。
                if (e.code === "NotAuthorizedException") {
                    alert("タイムアウトにより認証情報が無効となりました。トップページに遷移します。")
                    // 再認証
                    amplifyAuth();
                } else {
                    alert(e.message !== undefined ? e.message : e);
                }
            });
    };

    return (
        <>
            {canReference(permissionAuthority, pathname) ? (
                // 画面権限 参照可の場合
                <div className={classes.root}>
                    <Grid container alignItems="center">
                        <Grid item>
                            <Typography component="h2">職務経歴書出力</Typography>
                        </Grid>
                        <Grid item xs />
                    </Grid>
                    <div>
                        <Grid container spacing={2}>
                            <Grid container item spacing={1} className={classes.gridRow} >
                                <Grid item className={classes.gridItem}>
                                    <TextField id="employee_id" label="社員コード" variant="outlined" size="small" autoFocus
                                        value={inputValues.employee_id}
                                        required
                                        error={errorState.employee_id}
                                        onChange={(event) => {
                                            handleInputChange(event);
                                            // 入力チェック
                                            if (event.target.value.length === 6) {
                                                setErrorState({ ...errorState, [event.target.id]: false});
                                            } else {
                                                setErrorState({ ...errorState, [event.target.id]: true});
                                            }
                                        }}
                                        inputProps={{maxLength: 6}}
                                    />
                                </Grid>
                                <Grid item className={classes.gridItem}>
                                    <TextField id="employee_name" label="氏名" variant="outlined" size="small"
                                        value={inputValues.employee_name}
                                        disabled
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item spacing={1} className={classes.gridRow}>
                                <Grid item className={classes.gridItem}>
                                    <Button variant="contained" color="primary" startIcon={<CloudDownload />}
                                        onClick={() => downloadData()}
                                        // 非活性条件
                                        disabled={
                                            inputValues.employee_id.length !== 6            // 社員コード6桁以外
                                            || inputValues.employee_name === ''             // 氏名未取得
                                        }
                                    >ダウンロード</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            ) : (
                // 画面権限 参照不可の場合
                <div className={classes.root}>
                    {/* <Typography>参照権限がありません。</Typography> */}
                    <Typography>{permissionAuthorityMessage}</Typography>
                </div>
            )}
                
        </>
    );
}

// default export
export default ExportlWorkHistorySheet;
