import { useContext, useEffect, useState } from 'react';
import { API, Auth } from 'aws-amplify';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import { amplifyAuth, loadingContext } from '../App';
import Api from '../api.json';
import { defaultPermissionAuthority, TPermissionAuthority } from '../ApiInterface/PermissionAuthority';
import { getPermissionAuthority, canReference } from '../Authority/PermissionAuthority';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            '& > *': {
                margin: theme.spacing(1),
            },
        },
        iframe: {
            width: "100%",
            height: 800,
            borderWidth: 1,
        },
    }),
);

// default関数
const GradeAnalysis = () => {
    const classes = useStyles();
    const loading = useContext(loadingContext);

    // pathname
    const pathname = window.location.pathname;

    // 画面権限 State
    const [permissionAuthority, setPermissionAuthority] = useState<TPermissionAuthority>(defaultPermissionAuthority);
    const [permissionAuthorityMessage, setPermissionAuthorityMesssage] = useState('Loading...');
    
    // 埋込URL
    const [embedUrl, setEmbedUrl] = useState("");

    // 初回ロード時
    useEffect(() => {
        // 画面権限取得
        getPermissionAuthority().then(r => {
            setPermissionAuthority(r);
            if (!canReference(r, pathname)) {
                setPermissionAuthorityMesssage("参照権限がありません。");
            }
        });

        loading.setIsLoading(true);
        // 埋込URL取得
        getEmbedUrl().then(() => loading.setIsLoading(false));

        // 初回のみ実行するため、第2引数は空配列。
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // API Response Type定義
    type TEmbedUrl = {
        embedUrl: string;   // 埋込URL
    };

    // 埋込URL取得 非同期関数
    const getEmbedUrl = async () => {
        await Auth.currentSession()
            .then(r => {
                return r.getIdToken().getJwtToken();
            })
            .then(async (jwtToken) => {
                const apiInit = {
                    headers: {
                        Authorization: jwtToken
                    }
                };

                // APIコール
                await API.get(Api.apiName, Api.apis.gradeAnalysis.path, apiInit)
                    .then(r => {
                        console.log("API Response", r);
                        // 埋込URLを取得しStateに設定
                        const ResponseObject:TEmbedUrl = r;
                        setEmbedUrl(ResponseObject.embedUrl);
                    })
                    .catch(e => {
                        console.log("API.get error:", e);
                        alert(e);
                    });
            })
            .catch(e => {
                console.log("Auth.currentSession error", e);
                // {code: "NotAuthorizedException", name: "NotAuthorizedException", message: "Refresh Token has expired"}が返った場合は再認証。
                if (e.code === "NotAuthorizedException") {
                    alert("タイムアウトにより認証情報が無効となりました。トップページに遷移します。")
                    // 再認証
                    amplifyAuth();
                } else {
                    alert(e.message !== undefined ? e.message : e);
                }
            });
    };

    return(
        <>
            {canReference(permissionAuthority, pathname) ? (
                // 画面権限 参照可の場合
                <div className={classes.root}>
                    <Typography component="h2">グレード分析</Typography>
                    {/* ToDo: amazon-quicksight-embedding-sdkの使用 */}
                    <iframe title="QuickSight" src={embedUrl} className={classes.iframe}></iframe>
                </div>                
            ) : (
                // 画面権限 参照不可の場合
                <div className={classes.root}>
                    <Typography>{permissionAuthorityMessage}</Typography>
                </div>
            )}
        </>
    );
}

// defalut export
export default GradeAnalysis;
