export type TPossessionSkill = {
    possession_skill_id: string;                // 保有技能ID
    employee_id: string;                        // 従業員ID
    employee_name: string;                      // 従業員氏名
    skill_field_code: string;                   // 技能分野コード
    skill_field_name: string;                   // 技能分野名
    skill_code: string;                         // 技能コード
    skill_name: string;                         // 技能名
    skill_level: string;                        // 技能レベル
    experience_months: number;                  // 経験月数
    possession_qualification_id: string;        // 保有資格ID
    created_at: string;                         // 作成日時
    created_user_id: string;                    // 作成ユーザーID
    created_user_name: string;                  // 作成ユーザー名
    updated_at: string;                         // 更新日時
    updated_user_id: string;                    // 更新ユーザーID
    updated_user_name: string;                  // 更新ユーザー名
};

export type TPossessionSkills = {
    possession_skills: TPossessionSkill[];
    Count: number;
};

export const defaultPossessionSkill: TPossessionSkill = {
    possession_skill_id: "",                // 保有技能ID
    employee_id: "",                        // 従業員ID
    employee_name: "",                      // 従業員氏名
    skill_field_code: "",                   // 技能分野コード
    skill_field_name: "",                   // 技能分野名
    skill_code: "",                         // 技能コード
    skill_name: "",                         // 技能名
    skill_level: "",                        // 技能レベル
    experience_months: 0,                   // 経験月数
    possession_qualification_id: "",        // 保有資格ID
    created_at: "",                         // 作成日時
    created_user_id: "",                    // 作成ユーザーID
    created_user_name: "",                  // 作成ユーザー名
    updated_at: "",                         // 更新日時
    updated_user_id: "",                    // 更新ユーザーID
    updated_user_name: "",                  // 更新ユーザー名
};