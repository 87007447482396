import { ResponseResolver, MockedRequest, restContext } from 'msw';

const mockBusinessTypes: ResponseResolver<MockedRequest, typeof restContext> = async (req, res, ctx) => {
    console.log(req);

    const businessTypes = {
        "count": 36,
        "business_types": [
            { "business_type_code": "0050", "business_type_name": "水産・農林業" },
            { "business_type_code": "1050", "business_type_name": "鉱業" },
            { "business_type_code": "2050", "business_type_name": "建設業" },
            { "business_type_code": "3050", "business_type_name": "製造業(食料品)" },
            { "business_type_code": "3100", "business_type_name": "製造業(繊維製品)" },
            { "business_type_code": "3150", "business_type_name": "製造業(パルプ・紙)" },
            { "business_type_code": "3200", "business_type_name": "製造業(化学)" },
            { "business_type_code": "3250", "business_type_name": "製造業(医薬品)" },
            { "business_type_code": "3300", "business_type_name": "製造業(石油・石炭製品)" },
            { "business_type_code": "3350", "business_type_name": "製造業(ゴム製品)" },
            { "business_type_code": "3400", "business_type_name": "製造業(ガラス・土石製品)" },
            { "business_type_code": "3450", "business_type_name": "製造業(鉄鋼)" },
            { "business_type_code": "3500", "business_type_name": "製造業(非鉄金属)" },
            { "business_type_code": "3550", "business_type_name": "製造業(金属製品)" },
            { "business_type_code": "3600", "business_type_name": "製造業(機械)" },
            { "business_type_code": "3650", "business_type_name": "製造業(電気機器)" },
            { "business_type_code": "3700", "business_type_name": "製造業(輸送用機器)" },
            { "business_type_code": "3750", "business_type_name": "製造業(精密機器)" },
            { "business_type_code": "3800", "business_type_name": "製造業(その他製品)" },
            { "business_type_code": "4050", "business_type_name": "電気・ガス業" },
            { "business_type_code": "5050", "business_type_name": "陸運業" },
            { "business_type_code": "5100", "business_type_name": "海運業" },
            { "business_type_code": "5150", "business_type_name": "空運業" },
            { "business_type_code": "5200", "business_type_name": "倉庫・運輸関連業" },
            { "business_type_code": "5250", "business_type_name": "情報・通信業" },
            { "business_type_code": "6050", "business_type_name": "卸売業" },
            { "business_type_code": "6100", "business_type_name": "小売業" },
            { "business_type_code": "7050", "business_type_name": "銀行業" },
            { "business_type_code": "7100", "business_type_name": "証券、商品先物取引業" },
            { "business_type_code": "7150", "business_type_name": "保険業" },
            { "business_type_code": "7200", "business_type_name": "その他金融業" },
            { "business_type_code": "8050", "business_type_name": "不動産業" },
            { "business_type_code": "9050", "business_type_name": "サービス業" },
            { "business_type_code": "z050", "business_type_name": "研修" },
            { "business_type_code": "z900", "business_type_name": "その他" },

            { "business_type_code": "bt99", "business_type_name": "mock" }
        ]
    };

    // 仮想処理時間
    await new Promise(s => setTimeout(s, 3000));

    return res(ctx.json(businessTypes));
};
  
export default mockBusinessTypes;
