import Typography from '@material-ui/core/Typography';

export default function DemoHome() {
    return (
        <>
            <Typography variant="h2" gutterBottom>
                Welcome to Human Resource Management System
            </Typography>
        </>
    );
}