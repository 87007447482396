import { useContext, useState } from 'react';
import { API, Auth } from 'aws-amplify';
import { Button, Grid, Paper, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { CloudUpload } from '@material-ui/icons';

import { amplifyAuth, loadingContext } from '../App';

const useStyles = makeStyles((theme: Theme) =>
createStyles({
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    gridItem: {
        marginBottom: theme.spacing(1),
    },
    paper: {
        marginBottom: theme.spacing(1),
        padding: theme.spacing(1),
        paddingTop: theme.spacing(2),
    }
}),
);

// Props定義
type Props = {
    title: string;
    apiName: string;
    apiPath: string;
    permissionAuthorityPost: boolean;
};

// default関数
const FileUpload = (props: Props) => {
    const classes = useStyles();
    const loading = useContext(loadingContext);

    const [file, setFile] = useState<File>();
    // ファイル選択ハンドラ
    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if(!e.target.files) return;
        const selectFile: File = e.target.files[0];
        setFile(selectFile);
        encodeBase64(selectFile);
    };

    // アップロードハンドラ
    const handleUpload = () => {
        loading.setIsLoading(true);
        upload().then(() => loading.setIsLoading(false));        
    };

    const [fileBase64, setFileBase64] = useState<string | ArrayBuffer | null | undefined>();
    // Base64変換
    const encodeBase64 = (file: File) => {
        if(!file) return;
        const fr = new FileReader();
        fr.onload = (e) => {
            setFileBase64(e.target?.result);
        };
        fr.readAsDataURL(file);
    };

    // アップロード
    const upload = async () => {
        if (!file) return;
        await Auth.currentSession()
            .then(r => {
                return r.getIdToken().getJwtToken();
            })
            .then(async (jwtToken) => {
                const apiInit = {
                    headers: {
                        Authorization: jwtToken
                    },
                    body: {
                        file: fileBase64?.toString().replace(/^data:.+;base64,/, "")        // 先頭の非Base64文字列を除去
                    }
                };
                console.log("apiInit:", apiInit);

                // APIコール
                await API.post(props.apiName, props.apiPath, apiInit)
                    .then(r => {
                        console.log(r);
                        alert(r.message);
                    })
                    .catch(e => {
                        console.log("API error:", e);
                        alert(e);
                    });
            })
            .catch(e => {
                console.log("Auth.currentSession error", e);
                // {code: "NotAuthorizedException", name: "NotAuthorizedException", message: "Refresh Token has expired"}が返った場合は再認証。
                if (e.code === "NotAuthorizedException") {
                    alert("タイムアウトにより認証情報が無効となりました。トップページに遷移します。")
                    // 再認証
                    amplifyAuth();
                } else {
                    alert(e.message !== undefined ? e.message : e);
                }
            });
    };

    return (
        <Grid item>
            <Paper variant="outlined" className={classes.paper}>
                <Grid container item spacing={1}>
                    <Grid item className={classes.gridItem}>
                        <Typography component="h2">{props.title}</Typography>
                    </Grid>
                </Grid>
                <Grid container item spacing={1}>
                    <Grid item className={classes.gridItem}>
                        <input type="file" id="uploadFile" onChange={(e) => handleFileChange(e)} />
                    </Grid>
                </Grid>
                {props.permissionAuthorityPost && (
                    // 画面権限 更新可の場合
                    <Grid container item spacing={1}>
                        <Grid item className={classes.gridItem}>
                            <Button variant="contained" color="primary" startIcon={<CloudUpload />} onClick={() => handleUpload()} disabled={!file || loading.loading}>アップロード</Button>
                        </Grid>
                    </Grid>
                )}
            </Paper>
        </Grid>
    );
};

// default export
export default FileUpload;
