export type TEmployee = {
    employee_id: string;                        // 従業員ID
    employee_name: string;                      // 従業員氏名
    abbreviated_employee_name: string;          // 従業員名略称
    employee_name_kana: string;                 // 従業員名フリガナ
    employee_name_romaji: string;               // 従業員名ローマ字
    employee_name_category_code: string;        // 従業員名区分コード
    employee_name_category_name: string;        // 従業員名区分名称
    enrollment_category_code: string;           // 在籍区分コード
    enrollment_category_name: string;           // 在籍区分名称
    entry_date: string;                         // 入社日
    employment_company_code: string;            // 雇用会社コード
    employment_company_name: string;            // 雇用会社名称
    recruitment_category_code: string;          // 採用区分コード
    recruitment_category_name: string;          // 採用区分名称
    retire_date: string;                        // 退職日
    retire_reason_code: string;                 // 退職理由コード
    retire_reason: string;                      // 退職理由
    organization_code: string;                  // 組織コード
    organization_name: string;                  // 組織名
    official_position_code: string;             // 職位コード
    official_position_name: string;             // 職位名
    profile: {                                  // プロフィール
        name: string;                           // 氏名
        abbreviated_name: string;               // 氏名略称
        name_kana: string;                      // 氏名フリガナ
        name_romaji: string;                    // 氏名ローマ字
        is_use_previous_name: boolean;          // 旧姓使用有無
        gender_code: string;                    // 性別コード
        gender_name: string;                    // 性別名称
        blood_type_code: string;                // 血液型コード
        blood_type_name: string;                // 血液型名称
        registered_domicile_code: string;       // 本籍都道府県コード
        registered_domicile_name: string;       // 本籍都道府県名称
        birthplace_code: string;                // 出身地都道府県コード
        birthplace_name: string;                // 出身地都道府県名称
        birth_date: string;                     // 生年月日
        is_married: boolean;                    // 婚姻区分
        marriage_date: string;                  // 婚姻年月日
        mobile_phone_number: string;            // 携帯電話番号
        email_address: string;                  // eメールアドレス
    },
    previous_profile: {                         // 旧姓プロフィール
        previous_name: string;                  // 旧姓氏名
        abbreviated_previous_name: string;      // 旧姓氏名略称
        previous_name_kana: string;             // 旧姓氏名フリガナ
        previous_name_romaji: string;           // 旧姓氏名ローマ字
    },
    operation_information: {                    // 操作情報
        operation_date: string;                 // 操作日付
        login_id: string;                       // ログインID
        login_name: string;                     // ログイン名
    }
};

export type TEmployees = {
    employees: TEmployee[];
    Count: number;
};

export const defaultEmployee: TEmployee = {
    employee_id: "",                        // 従業員ID
    employee_name: "",                      // 従業員氏名
    abbreviated_employee_name: "",          // 従業員名略称
    employee_name_kana: "",                 // 従業員名フリガナ
    employee_name_romaji: "",               // 従業員名ローマ字
    employee_name_category_code: "",        // 従業員名区分コード
    employee_name_category_name: "",        // 従業員名区分名称
    enrollment_category_code: "",           // 在籍区分コード
    enrollment_category_name: "",           // 在籍区分名称
    entry_date: "",                         // 入社日
    employment_company_code: "",            // 雇用会社コード
    employment_company_name: "",            // 雇用会社名称
    recruitment_category_code: "",          // 採用区分コード
    recruitment_category_name: "",          // 採用区分名称
    retire_date: "",                        // 退職日
    retire_reason_code: "",                 // 退職理由コード
    retire_reason: "",                      // 退職理由
    organization_code: "",                  // 組織コード
    organization_name: "",                  // 組織名
    official_position_code: "",             // 職位コード
    official_position_name: "",             // 職位名
    profile: {                              // プロフィール
        name: "",                           // 氏名
        abbreviated_name: "",               // 氏名略称
        name_kana: "",                      // 氏名フリガナ
        name_romaji: "",                    // 氏名ローマ字
        is_use_previous_name: false,        // 旧姓使用有無
        gender_code: "",                    // 性別コード
        gender_name: "",                    // 性別名称
        blood_type_code: "",                // 血液型コード
        blood_type_name: "",                // 血液型名称
        registered_domicile_code: "",       // 本籍都道府県コード
        registered_domicile_name: "",       // 本籍都道府県名称
        birthplace_code: "",                // 出身地都道府県コード
        birthplace_name: "",                // 出身地都道府県名称
        birth_date: "",                     // 生年月日
        is_married: false,                  // 婚姻区分
        marriage_date: "",                  // 婚姻年月日
        mobile_phone_number: "",            // 携帯電話番号
        email_address: "",                  // eメールアドレス
    },
    previous_profile: {                     // 旧姓プロフィール
        previous_name: "",                  // 旧姓氏名
        abbreviated_previous_name: "",      // 旧姓氏名略称
        previous_name_kana: "",             // 旧姓氏名フリガナ
        previous_name_romaji: "",           // 旧姓氏名ローマ字
    },
    operation_information: {                // 操作情報
        operation_date: "",                 // 操作日付
        login_id: "",                       // ログインID
        login_name: "",                     // ログイン名
    }
};