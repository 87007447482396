import { ResponseResolver, MockedRequest, restContext } from 'msw';

const mockGradeAnalysis: ResponseResolver<MockedRequest, typeof restContext> = async (req, res, ctx) => {
    console.log(req);

    const response = {
        "embedUrl": "https://ap-northeast-1.quicksight.aws.amazon.com/embed/477f29e7e9b84e67ae48c18f1e050905/dashboards/61e089d6-f506-411f-80f0-eee667821acd?isauthcode=true&identityprovider=quicksight&code=AYABeDmZibTuiD7mroOjwjEuqisAAAABAAdhd3Mta21zAFBhcm46YXdzOmttczphcC1ub3J0aGVhc3QtMTozNjcwOTQ1NjE4OTQ6a2V5LzkyZDU3MjEzLTc0MjItNGNhOC1iYWZiLTg2MDFjNGZkODgyNwC4AQIBAHh19lpIdHJLvWmpW7433A8711o_o_2vfnBuxyXbJJdfkwEbrlV2Yii-v9zcazWGFJPZAAAAfjB8BgkqhkiG9w0BBwagbzBtAgEAMGgGCSqGSIb3DQEHATAeBglghkgBZQMEAS4wEQQMhjMAzhFiFo8LZPgvAgEQgDuXZQb4IER_nhRdiG8Sm2XYwDDjml16LiI0BwY_30_eN7h0Itnvmf58r2jdPBjOqs4AcQZLlGAXadWfjAIAAAAADAAAEAAAAAAAAAAAAAAAAABCiTeCOhysSfVc1cONoQuv_____wAAAAEAAAAAAAAAAAAAAAEAAACeYVklEFpF8ixqPR20vWn_t8akNAWUtgIqtYCNwsTKuRIL0IpRjdQkfsxA3tdNvXgNwwGtIWIIuCtMMtcuii0TvIFYqT2KbmAdwe_7V5FRB0dJQLSNQZnvJtynXC535ROcpmdg5IIg9kNcKzoI8jMgDYchW3cJVRAJwtvzz2_eMZGa7eWD9cbkED3Min-8Pwj6jQCUrvZlo0PePs9Qxmtwqd0wMlmf5NqiEcnupQkF&undoRedoDisabled=true&resetDisabled=true"
    };

    // 仮想処理時間
    await new Promise(s => setTimeout(s, 2000));

    return res(ctx.json(response));
};
  
export default mockGradeAnalysis;
