export type TPossessionQualification = {
    possession_qualification_id: string;        // 保有資格ID
    employee_id: string;                        // 従業員ID
    employee_name: string;                      // 従業員氏名
    qualification_field_code: string;           // 資格分野コード
    qualification_field_name: string;           // 資格分野名
    qualification_code: string;                 // 資格コード
    qualification_name: string;                 // 資格名
    acquisition_date: Date | null;              // 取得日
    expiry_date: Date | null;                   // 有効期限
    qualification_number: string;               // 資格番号
    certification_document_url: string;         // 証明文書管理URL
    created_at: string;                         // 作成日時
    created_user_id: string;                    // 作成ユーザーID
    created_user_name: string;                  // 作成ユーザー名
    updated_at: string;                         // 更新日時
    updated_user_id: string;                    // 更新ユーザーID
    updated_user_name: string;                  // 更新ユーザー名
};

export type TPossessionQualifications = {
    possession_qualifications: TPossessionQualification[];
    Count: number;
};

export const defaultPossessionQualification: TPossessionQualification = {
    possession_qualification_id: "",        // 保有資格ID
    employee_id: "",                        // 従業員ID
    employee_name: "",                      // 従業員氏名
    qualification_field_code: "",           // 資格分野コード
    qualification_field_name: "",           // 資格分野名
    qualification_code: "",                 // 資格コード
    qualification_name: "",                 // 資格名
    acquisition_date: null,                 // 取得日
    expiry_date: null,                      // 有効期限
    qualification_number: "",               // 資格番号
    certification_document_url: "",         // 証明文書管理URL
    created_at: "",                         // 作成日時
    created_user_id: "",                    // 作成ユーザーID
    created_user_name: "",                  // 作成ユーザー名
    updated_at: "",                         // 更新日時
    updated_user_id: "",                    // 更新ユーザーID
    updated_user_name: "",                  // 更新ユーザー名
};