import { useContext, useEffect, useState } from 'react';
import { API, Auth } from 'aws-amplify';
import { Link, useLocation, useParams } from 'react-router-dom';
import { Grid, IconButton, TextField, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { ArrowBack } from '@material-ui/icons';
// import { KeyboardDatePicker } from '@material-ui/pickers';

import { amplifyAuth, loadingContext } from '../App';
import { ISearchConditions } from './SearchEmployee';
import Api from '../api.json';
import { defaultEmployee, TEmployee } from '../ApiInterface/employee';
// import ResourcesEmployee from '../ApiInterface/ResoucesEmployeesStub.json';
import { defaultPermissionAuthority, TPermissionAuthority } from '../ApiInterface/PermissionAuthority';
import { getPermissionAuthority, canReference } from '../Authority/PermissionAuthority';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            '& > *': {
                margin: theme.spacing(1),
            },
        },
        gridRow: {
            // marginBottom: theme.spacing(1),
        },
        gridItem: {
            marginBottom: theme.spacing(1),
        },
        halfWidth: {
            width: 210 / 2 - 8
        },
        doubleWidth: {
            width: 210 * 2 + 8
        },
        select: {
            minWidth: 210,  // TextField.width:210
            width: 210
        },
        selectHalfWidth: {
            minWidth: 210 / 2 -4, 
            width: 210 / 2 - 4
        },
        selectDoubleWidth: {
            minWidth: 210 * 2 + 8, 
            width: 210 * 2 + 8
        },
        paper: {
            marginBottom: theme.spacing(1),
            padding: theme.spacing(1),
            paddingTop: theme.spacing(2),
            width: "100%"
        }
    }),
);

// default function
export default function DetailEmployee() {
    const classes = useStyles();

    // const history = useHistory();
    const location = useLocation<ISearchConditions>();
    console.log(location);

    // pathname
    const pathname = window.location.pathname;

    const [inputValues, setInputValues] = useState<TEmployee>(defaultEmployee);

    // 画面権限 State
    const [permissionAuthority, setPermissionAuthority] = useState<TPermissionAuthority>(defaultPermissionAuthority);
    const [permissionAuthorityMessage, setPermissionAuthorityMesssage] = useState('Loading...');
    
    // // 入力値変更ハンドル
    // const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, id?: string) => {
    //     const targetId = id == null ? event.target.id : id;
    //     // ToDo: ネストオブジェクトへの対応
    //     setInputValues({ ...inputValues, [targetId]: event.target.value });
    // }

    // // 日付入直値変更ハンドル
    // const handleDateChange = (date: Date | null, id: string) => {
    //     // ToDo: ネストオブジェクトへの対応
    //     setInputValues({ ...inputValues, [id]: date });
    // }

    // URLパラメータ
    const { id } = useParams<{id: string}>();

    const loading = useContext(loadingContext);

    useEffect(() => {
        // 画面権限取得
        getPermissionAuthority().then(r => {
            setPermissionAuthority(r);
            if (!canReference(r, pathname, id)) {
                setPermissionAuthorityMesssage("参照権限がありません。");
            }
        });

        loading.setIsLoading(true);
        getEmployee().then(() => loading.setIsLoading(false));

        // 初回のみ実行するため、第2引数の指定は無し。
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // 人事基本情報取得(id指定)
    const getEmployee = async () => {
        await Auth.currentSession()
            .then(r => {
                return r.getIdToken().getJwtToken();
            })
            .then(async (jwtToken) => {
                const apiInit = {
                    headers: {
                        Authorization: jwtToken
                    }
                };

                // APIコール
                await API.get(Api.apiName, Api.apis.employees.path + "/" + id, apiInit)
                    .then(r => {
                        console.log(r);
                        // setForm(r);
                        if (r.employee_id !== undefined){
                            setForm(r);
                        }
                    })
                    .catch(e => {
                        console.log("API.get error:", e);
                        alert(e);
                    });
            })
            .catch(e => {
                console.log("Auth.currentSession error", e);
                // alert(e.message !== undefined ? e.message : e);
                // {code: "NotAuthorizedException", name: "NotAuthorizedException", message: "Refresh Token has expired"}が返った場合は再認証。
                if (e.code === "NotAuthorizedException") {
                    alert("タイムアウトにより認証情報が無効となりました。トップページに遷移します。")
                    // 再認証
                    amplifyAuth();
                }
            });
    };

    // 画面項目値設定
    const setForm = (apiResponse: TEmployee) => {
        setInputValues(apiResponse);
    };

    // 全項目非活性
    const isDisabled = false;

    return (
        <>
            {canReference(permissionAuthority, pathname, id) ? (
                // 画面権限 参照可の場合
                <div className={classes.root}>
                    <Grid container alignItems="center">
                        <Grid item>
                            <IconButton component={Link} to={{ pathname: "/employee", state: location.state }} size="small"><ArrowBack /></IconButton>
                        </Grid>
                        <Grid item>
                            <Typography component="h2">人事基本情報 詳細</Typography>
                        </Grid>
                        <Grid item xs />
                    </Grid>
                    <div>
                        <Grid container spacing={2}>
                            <Grid container item spacing={1} className={classes.gridRow} >
                                <Grid item className={classes.gridItem}>
                                    <TextField id="employee_id" label="社員コード" variant="outlined" size="small" autoFocus value={inputValues.employee_id} disabled={isDisabled} />
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Typography component="h2">基本情報</Typography>
                            </Grid>
                            <Grid container item spacing={1} className={classes.gridRow}>
                                <Grid item className={classes.gridItem}>
                                    <TextField id="name" label="氏名" variant="outlined" size="small" value={inputValues.profile.name} disabled={isDisabled} />
                                </Grid>
                                <Grid item className={classes.gridItem}>
                                    <TextField id="abbreviated_name" label="略称" variant="outlined" size="small" value={inputValues.profile.abbreviated_name} disabled={isDisabled} />
                                </Grid>
                                <Grid item className={classes.gridItem}>
                                    <TextField id="name_kana" label="フリガナ" variant="outlined" size="small" value={inputValues.profile.name_kana} disabled={isDisabled} />
                                </Grid>
                                <Grid item className={classes.gridItem}>
                                    <TextField id="name_romaji" label="ローマ字氏名" variant="outlined" size="small" className={classes.doubleWidth} value={inputValues.profile.name_romaji} disabled={isDisabled} />
                                </Grid>
                            </Grid>
                            <Grid container item spacing={1} className={classes.gridRow}>
                                {/* <Paper variant="outlined" className={classes.paper}>
                                    <Grid container item spacing={1}> */}
                                        {/* <Grid item className={classes.gridItem}>
                                            <FormControlLabel id="is_use_previous_name" control={<Checkbox color="primary" checked={inputValues.profile.is_use_previous_name} disabled={isDisabled} />} label="旧姓を使用する" />
                                        </Grid> */}
                                        <Grid item className={classes.gridItem}>
                                            <TextField id="previous_name" label="旧姓" variant="outlined" size="small" value={inputValues.previous_profile.previous_name} disabled={isDisabled} />
                                        </Grid>
                                        <Grid item className={classes.gridItem}>
                                            <TextField id="abbreviated_previous_name" label="旧姓略称" variant="outlined" size="small" value={inputValues.previous_profile.abbreviated_previous_name} disabled={isDisabled} />
                                        </Grid>
                                        <Grid item className={classes.gridItem}>
                                            <TextField id="previous_name_kana" label="旧姓フリガナ" variant="outlined" size="small" value={inputValues.previous_profile.previous_name_kana} disabled={isDisabled} />
                                        </Grid>
                                        <Grid item className={classes.gridItem}>
                                            <TextField id="previous_name_romaji" label="ローマ字旧姓" variant="outlined" size="small" className={classes.doubleWidth} value={inputValues.previous_profile.previous_name_romaji} disabled={isDisabled} />
                                        </Grid>
                                    {/* </Grid>
                                </Paper> */}
                            </Grid>
                            <Grid container item spacing={1} className={classes.gridRow}>
                                <Grid item className={classes.gridItem}>
                                    <TextField id="employee_name_category_code" label="従業員名区分" variant="outlined" size="small" value={inputValues.employee_name_category_name} disabled={isDisabled} />
                                    {/* <TextField id="employee_name_category_code" label="従業員名区分" variant="outlined" size="small" select className={classes.select} value={inputValues.employee_name_category_code} disabled={isDisabled}>
                                        <MenuItem value="">None</MenuItem>
                                        {ResourcesEmployee.employee_name_categories.map((e) =>
                                            <MenuItem key={e.employee_name_category_code} value={e.employee_name_category_code}>{e.employee_name_category_name}</MenuItem>
                                        )}
                                    </TextField> */}
                                </Grid>
                                <Grid item className={classes.gridItem}>
                                    <TextField id="gender_code" label="性別" variant="outlined" size="small" value={inputValues.profile.gender_name} disabled={isDisabled} />
                                    {/* <TextField id="gender_code" label="性別" variant="outlined" size="small" select className={classes.select} value={inputValues.profile.gender_code} disabled={isDisabled}>
                                        <MenuItem value="">None</MenuItem>
                                        {ResourcesEmployee.genders.map((e) =>
                                            <MenuItem key={e.gender_code} value={e.gender_code}>{e.gender_name}</MenuItem>
                                        )}
                                    </TextField> */}
                                </Grid>
                                {/* <Grid item className={classes.gridItem}>
                                    <TextField id="birth_date" label="生年月日" variant="outlined" size="small" value={inputValues.profile.birth_date} disabled={isDisabled} />
                                </Grid> */}
                                <Grid item className={classes.gridItem}>
                                    <TextField id="blood_type_code" label="血液型" variant="outlined" size="small" value={inputValues.profile.blood_type_name} disabled={isDisabled} />
                                    {/* <TextField id="blood_type_code" label="血液型" variant="outlined" size="small" select className={classes.select} value={inputValues.profile.blood_type_code} disabled={isDisabled}>
                                        <MenuItem value="">None</MenuItem>
                                        {ResourcesEmployee.blood_types.map((e) =>
                                            <MenuItem key={e.blood_type_code} value={e.blood_type_code}>{e.blood_type_name}</MenuItem>
                                        )}
                                    </TextField> */}
                                </Grid>
                                {/* <Grid item className={classes.gridItem}>
                                    <TextField id="registered_domicile_code" label="本籍" variant="outlined" size="small" value={inputValues.profile.registered_domicile_name} disabled={isDisabled} /> */}
                                    {/* <TextField id="registered_domicile_code" label="本籍" variant="outlined" size="small" select className={classes.select} value={inputValues.profile.registered_domicile_code} disabled={isDisabled}>
                                        <MenuItem value="">None</MenuItem>
                                        {ResourcesEmployee.prefectures.map((e) =>
                                            <MenuItem key={e.prefectures_code} value={e.prefectures_code}>{e.prefectures_name}</MenuItem>
                                        )}
                                    </TextField> */}
                                {/* </Grid> */}
                                {/* <Grid item className={classes.gridItem}>
                                    <TextField id="birthplace_code" label="出身地" variant="outlined" size="small" value={inputValues.profile.birthplace_name} disabled={isDisabled} /> */}
                                    {/* <TextField id="birthplace_code" label="出身地" variant="outlined" size="small" select className={classes.select} value={inputValues.profile.birthplace_code} disabled={isDisabled}>
                                        <MenuItem value="">None</MenuItem>
                                        {ResourcesEmployee.prefectures.map((e) =>
                                            <MenuItem key={e.prefectures_code} value={e.prefectures_code}>{e.prefectures_name}</MenuItem>
                                        )}
                                    </TextField> */}
                                {/* </Grid> */}
                                <Grid item className={classes.gridItem}>
                                    <TextField id="entry_date" label="入社年月日" variant="outlined" size="small" value={inputValues.entry_date} disabled={isDisabled} />
                                </Grid>
                                <Grid item className={classes.gridItem}>
                                    <TextField id="recruitment_category_code" label="採用区分" variant="outlined" size="small" value={inputValues.recruitment_category_name} disabled={isDisabled} />
                                    {/* <TextField id="recruitment_category_code" label="採用区分" variant="outlined" size="small" select className={classes.select} value={inputValues.recruitment_category_code} disabled={isDisabled}>
                                        <MenuItem value="">None</MenuItem>
                                        {ResourcesEmployee.recruitment_categories.map((e) =>
                                            <MenuItem key={e.recruitment_category_code} value={e.recruitment_category_code}>{e.recruitment_category_name}</MenuItem>
                                        )}
                                    </TextField> */}
                                </Grid>
                                <Grid item className={classes.gridItem}>
                                    <TextField id="employment_company_code" label="雇用会社" variant="outlined" size="small" className={classes.doubleWidth} value={inputValues.employment_company_name} disabled={isDisabled} />
                                    {/* <TextField id="employment_company_code" label="雇用会社" variant="outlined" size="small" select className={classes.selectDoubleWidth} value={inputValues.employment_company_code} disabled={isDisabled}>
                                        <MenuItem value="">None</MenuItem>
                                        {ResourcesEmployee.employment_companies.map((e) =>
                                            <MenuItem key={e.employment_company_code} value={e.employment_company_code}>{e.employment_company_name}</MenuItem>
                                        )}
                                    </TextField> */}
                                </Grid>
                                <Grid item className={classes.gridItem}>
                                    <TextField id="enrollment_category_code" label="在籍区分" variant="outlined" size="small" value={inputValues.enrollment_category_name} disabled={isDisabled} />
                                    {/* <TextField id="enrollment_category_code" label="在籍区分" variant="outlined" size="small" select className={classes.select} value={inputValues.enrollment_category_code} disabled={isDisabled}>
                                        <MenuItem value="">None</MenuItem>
                                        {ResourcesEmployee.enrollment_categories.map((e) =>
                                            <MenuItem key={e.enrollment_category_code} value={e.enrollment_category_code}>{e.enrollment_category_name}</MenuItem>
                                        )}
                                    </TextField> */}
                                </Grid>
                                <Grid item className={classes.gridItem}>
                                    <TextField id="retire_date" label="退職年月日" variant="outlined" size="small" value={inputValues.retire_date} disabled={isDisabled} />
                                </Grid>
                                <Grid item className={classes.gridItem}>
                                    <TextField id="retire_reason" label="退職理由" variant="outlined" size="small" className={classes.doubleWidth} value={inputValues.retire_reason} disabled={isDisabled} />
                                </Grid>
                                {/* <Grid item className={classes.gridItem}>
                                    <TextField id="mobile_phone_number" label="携帯電話番号" variant="outlined" size="small" value={inputValues.profile.mobile_phone_number} disabled={isDisabled} />
                                </Grid> */}
                                {/* <Grid item className={classes.gridItem}>
                                    <TextField id="email_address" label="eメールアドレス" variant="outlined" size="small" className={classes.doubleWidth} value={inputValues.profile.email_address} disabled={isDisabled} />
                                </Grid> */}

                                {/* <Grid container item spacing={1} className={classes.gridRow}>
                                    <Paper variant="outlined" className={classes.paper}>
                                        <Grid container item spacing={1}>
                                            <Grid item className={classes.gridItem}>
                                                <FormControlLabel id="is_married" control={<Checkbox color="primary" checked={inputValues.profile.is_married} disabled={isDisabled} />} label="既婚" />
                                            </Grid>
                                            <Grid item className={classes.gridItem}>
                                                <TextField id="marriage_date" label="婚姻年月日" variant="outlined" size="small" value={inputValues.profile.marriage_date} disabled={isDisabled} />
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                </Grid> */}
                            </Grid>
                            <Grid item>
                                <Typography component="h2">職制情報</Typography>
                            </Grid>
                            <Grid container item spacing={1} className={classes.gridRow}>
                                <Grid item className={classes.gridItem}>
                                    <TextField id="organization_name" label="組織" variant="outlined" size="small" value={inputValues.organization_name} disabled={isDisabled} />
                                </Grid>
                                <Grid item className={classes.gridItem}>
                                    <TextField id="official_position_name" label="職位" variant="outlined" size="small" value={inputValues.official_position_name} disabled={isDisabled} />
                                </Grid>
                            </Grid>
                            {/* <Grid item>
                                <Typography component="h2">操作情報</Typography>
                            </Grid>
                            <Grid container item spacing={1} className={classes.gridRow}>
                                <Grid item className={classes.gridItem}>
                                    <TextField id="operation_date" label="操作日付" variant="outlined" size="small" value={inputValues.operation_information.operation_date} disabled={isDisabled} />
                                </Grid>
                                <Grid item className={classes.gridItem}>
                                    <TextField id="login_name" label="ログイン名" variant="outlined" size="small" value={inputValues.operation_information.login_name} disabled={isDisabled} />
                                </Grid>
                            </Grid> */}
                        </Grid>
                    </div>
                </div>
            ) : (
                // 画面権限 参照不可の場合
                <div className={classes.root}>
                    <Typography>{permissionAuthorityMessage}</Typography>
                </div>
            )}
        </>
    );
}