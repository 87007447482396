import { API, Auth } from 'aws-amplify';

import { amplifyAuth } from '../App';
import Api from '../api.json';
import { TPermissionAuthority, defaultPermissionAuthority } from '../ApiInterface/PermissionAuthority';

// 画面権限取得
export const getPermissionAuthority = async (): Promise<TPermissionAuthority> => {
    let result: TPermissionAuthority = defaultPermissionAuthority;

    await Auth.currentSession()
        .then(r => {
            return r.getIdToken().getJwtToken();
        })
        .then(async (jwtToken) => {
            const apiInit = {
                headers: {
                    Authorization: jwtToken
                }
            };

            // APIコール 画面権限取得
            await API.get(Api.apiName, Api.apis.permissions.path, apiInit)
                .then(r => {
                    console.log("API Response", r);
                    result = r;
                })
                .catch(e => {
                    console.log("API.get error:", e);
                    alert(e);
                });
        })
        .catch(e => {
            console.log("Auth.currentSession error", e);
            // {code: "NotAuthorizedException", name: "NotAuthorizedException", message: "Refresh Token has expired"}が返った場合は再認証。
            if (e.code === "NotAuthorizedException") {
                alert("タイムアウトにより認証情報が無効となりました。トップページに遷移します。")
                // 再認証
                amplifyAuth();
            } else {
                alert(e.message !== undefined ? e.message : e);
            }
        });

    return result;
};

// 参照可否
export const canReference = (permission: TPermissionAuthority, formPath: string, id?: string): boolean => {
    // let result = false;

    // // 対象パスが認可画面に含まれているか
    // if (permission.forms.findIndex(({path}) => path === formPath) !== -1) {
    //     result = true;
    // }

    // return result;
    let buildFormPath: string = formPath;
    // id指定がある場合
    if (id) {
        // パスからid部分を除去、置換
        buildFormPath = formPath.split("/").reverse().slice(1).reverse().join("/") + "/:id";
    }

    // 対象パスが認可画面に含まれているか
    return permission.forms.some(form => form.path === buildFormPath);
};

// 追加可否
export const canPost = (permission: TPermissionAuthority, apiPath: string): boolean => {
    // 対象パスが認可API(POST)に含まれているか
    return permission.apis.some(api => api.path === apiPath && api.method === "POST");
};

// 更新可否
export const canPut = (permission: TPermissionAuthority, apiPath: string): boolean => {
    // 対象パスが認可API(PUT)に含まれているか(パスパラメータ対応のためmatch)
    return permission.apis.some(api => api.path.match(apiPath) && api.method === "PUT");
};

// 削除可否
export const canDelete = (permission: TPermissionAuthority, apiPath: string): boolean => {
    // 対象パスが認可API(DELETE)に含まれているか(パスパラメータ対応のためmatch)
    return permission.apis.some(api => api.path.match(apiPath) && api.method === "DELETE");
};

