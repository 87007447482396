export type TMenuAuthority = {
    path: string;       // 認可画面メニューpath
};

export type TMenuAuthorities = {
    paths: TMenuAuthority[];
    count: number;
};

export const defaultMenuAuthority: TMenuAuthority = {
    path: ""        // 認可画面メニューpath
};

export const defaultMenuAuthorities: TMenuAuthorities = {
    paths: [],
    count: 0
};