export type TProject = {
    project_code: string;                       // プロジェクトコード
    project_name: string;                       // プロジェクト名
    start_date: Date | null;                    // 開始年月日
    end_date: Date | null;                      // 終了年月日
    project_field_code: string;                 // プロジェクト分野コード
    project_field_name: string;                 // プロジェクト分野名
    business_type_code: string;                 // 業種コード
    business_type_name: string;                 // 業種名
    project_content: string;                    // 内容
    project_scale: number;                      // 規模
    contract_type_code: string;                 // 契約形態コード
    contract_type_name: string;                 // 契約形態名
    created_at: string;                         // 作成日時
    created_user_id: string;                    // 作成ユーザーID
    created_user_name: string;                  // 作成ユーザー名
    updated_at: string;                         // 更新日時
    updated_user_id: string;                    // 更新ユーザーID
    updated_user_name: string;                  // 更新ユーザー名
};

export type TProjects = {
    projects: TProject[];
    count: number;
};

export const defaultProject: TProject = {
    project_code: "",                       // プロジェクトコード
    project_name: "",                       // プロジェクト名
    start_date: null,                       // 開始年月日
    end_date: null,                         // 終了年月日
    project_field_code: "",                 // プロジェクト分野コード
    project_field_name: "",                 // プロジェクト分野名
    business_type_code: "",                 // 業種コード
    business_type_name: "",                 // 業種名
    project_content: "",                    // 内容
    project_scale: 0,                       // 規模
    contract_type_code: "",                 // 契約形態コード
    contract_type_name: "",                 // 契約形態名
    created_at: "",                         // 作成日時
    created_user_id: "",                    // 作成ユーザーID
    created_user_name: "",                  // 作成ユーザー名
    updated_at: "",                         // 更新日時
    updated_user_id: "",                    // 更新ユーザーID
    updated_user_name: "",                  // 更新ユーザー名
};